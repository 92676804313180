import React from 'react'
import { Router } from './routes'
import ThemeProvider from './theme'
import RtlLayout from './components/RtlLayout'
import { ChartStyle } from './components/chart'
import ScrollToTop from './components/ScrollToTop'
import { ProgressBarStyle } from './components/ProgressBar'
import NotistackProvider from './components/NotistackProvider'
import ThemeColorPresets from './components/ThemeColorPresets'
import ThemeLocalization from './components/ThemeLocalization'
import MotionLazyContainer from './components/animate/MotionLazyContainer'
import LoadingMain from '@/components/LoadingMain'
import { EasyCrop } from '@/components/EasyCrop'

export default function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <ScrollToTop />
                <Router />
                <EasyCrop />
                <LoadingMain />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  )
}
