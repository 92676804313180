export const en = {
  '%s cards': `%s cards`,
  '%s created successfully!': `%s created successfully!`,
  '%s is required': `%s is required`,
  '%s list': `%s list`,
  '%s must be a number': `%s must be a number`,
  '%s successfully created': `%s successfully created`,
  '%s successfully deleted': `%s successfully deleted`,
  '%s successfully updated': `%s successfully updated`,
  '404 Page Not Found': `404 Page Not Found`,
  '500 Internal Server Error': `500 Internal Server Error`,
  'About': `About`,
  'About us': `About us`,
  'Account': `Account`,
  'Account Settings': `Account Settings`,
  'Active': `Active`,
  'Add': `Add`,
  'Add %s': `Add %s`,
  'Add Brand': `Add Brand`,
  'Add coupons': `Add coupons`,
  'Add coupons to product': `Add coupons to product`,
  'Add product': `Add product`,
  'Add products': `Add products`,
  'Add products to chests': `Add products to gift box`,
  'Add products to prizes': `Add products to prizes`,
  'Address': `Address`,
  'Adjust the image correctly': `Adjust the image correctly`,
  'Admin': `Admin`,
  'All day': `All day`,
  'Allowed': `Allowed`,
  'Already have an account?': `Already have an account?`,
  'Amount': `Amount`,
  'Analytics': `Analytics`,
  'and': `and`,
  'App': `App`,
  'Apply': `Apply`,
  'Approve': `Approve`,
  'Are you sure you want to delete %i %s?': `Are you sure you want to delete %i %s?`,
  'Are you sure you want to delete this %s?': `Are you sure you want to delete this %s?`,
  'Ask before share': `Ask before share`,
  'Available': `Available`,
  'Avatar is required': `Avatar is required`,
  'Awards for slot #77': `Awards for slot #77`,
  'Back': `Back`,
  'Balance': `Balance`,
  'Banking': `Banking`,
  'Banned': `Banned`,
  'Banner': `Banner`,
  'Billing': `Billing`,
  'Billing Address': `Billing Address`,
  'Blog': `Blog`,
  'Brand': `Brand`,
  'Brand Facebook ID': `Brand Facebook ID`,
  'Brand Facebook URL': `Brand Facebook URL`,
  'Brand ID Code': `Brand ID Code`,
  'Brand identity': `Brand identity`,
  'Brand Instagram name': `Brand Instagram name`,
  'Brand legal name': `Brand legal name`,
  'Brand name': `Brand name`,
  'Brands': `Brands`,
  'browse': `browse`,
  'Bug Reports': `Bug Reports`,
  'Buy': `Buy`,
  'By registering, I agree to click77': `By registering, I agree to click77`,
  'Calendar': `Calendar`,
  'Cancel': `Cancel`,
  'Card number': `Card number`,
  'Cards': `Cards`,
  'Cart is empty': `Cart is empty`,
  'Category': `Category`,
  'Change user balance for "%s"': `Change user balance for "%s"`,
  'Change user brands for "%s"': `Change user brands for "%s"`,
  'Change user permissions for "%s"': `Change user permissions for "%s"`,
  'Chat': `Chat`,
  'Check In': `Check In`,
  'Check Out': `Check Out`,
  'Checkout': `Checkout`,
  'chests should not be empty': `Chests should not be empty`,
  'City': `City`,
  'Clear All': `Clear All`,
  'Click77': `Click77`,
  'Click77 Box Code': `Click77 Box Code`,
  'Click77 Demo Product': `Click 77 Demo Product`,
  'Clicks': `Clicks`,
  'Close': `Close`,
  'Code': `Code`,
  'Color': `Color`,
  'Coming Soon': `Coming Soon`,
  'Comment': `Comment`,
  'Company': `Company`,
  'Components Overview': `Components Overview`,
  'Config brand': `Config brand`,
  'Confirm': `Confirm`,
  'Confirm & Transfer': `Confirm & Transfer`,
  'Confirm New Password': `Confirm New Password`,
  'Contact us': `Contact us`,
  'Content': `Content`,
  'Continue': `Continue`,
  'Cost': `Cost`,
  'Countries': `Countries`,
  'Country': `Country`,
  'Coupons': `Coupons`,
  'Cover is required': `Cover is required`,
  'Create %s': `Create a new %s`,
  'Create success!': `Create success!`,
  'Credit': `Credit`,
  'Currency': `Currency`,
  'Current downloads': `Current downloads`,
  'CVV': `CVV`,
  'Dashboard': `Dashboard`,
  'Date': `Date`,
  'Days': `Days`,
  'Default': `Default`,
  'Delete': `Delete`,
  'Delete %s': `Delete %s`,
  'Delete successful!': `Delete successful!`,
  'Deliver to this address': `Deliver to this address`,
  'Delivery options': `Delivery options`,
  'Demo store for IOS deploy': `Demo store for IOS deploy`,
  'Description': `Description`,
  'Different plans for': `Different plans for`,
  'different sizes and needs': `different sizes and needs`,
  'discount': `discount`,
  'Discount codes / Gifts': `Discount codes / Gifts`,
  'Don\'t have a code?': `Don't have a code?`,
  'Don\'t have an account?': `Don't have an account?`,
  'Download %s': `Download %s`,
  'Draw': `Draw`,
  'draw available for': `draw available for`,
  'draw is finished': `draw is finished`,
  'Draws': `Draws`,
  'Drop files here or click': `Drop files here or click`,
  'Drop or Select file': `Drop or Select file`,
  'E-Commerce': `E-Commerce`,
  'E-Commerce URL': `E-Commerce URL`,
  'E-Commerce visitors': `E-Commerce visitors`,
  'E-mail': `E-mail`,
  'Edit': `Edit`,
  'Edit %s': `Edit %s`,
  'Effort prize that will be given to those who make the most clicks': `Effort prize that will be given to those who make the most clicks`,
  'Effort prizes': `Effort prizes`,
  'Email': `Email`,
  'Email address': `Email address`,
  'Email is required': `Email is required`,
  'Email must be a valid email address': `Email must be a valid email address`,
  'Empty': `Empty`,
  'Empty content': `Empty content`,
  'Enable comments': `Enable comments`,
  'End date': `End date`,
  'Enter your details below': `Enter your details below`,
  'Enter your email': `Enter your email`,
  'Enter your message here': `Enter your message here`,
  'Event': `Event`,
  'Expenses': `Expenses`,
  'Expiration date': `Expiration date`,
  'Facebook page id': `Facebook page id`,
  'Factor': `Factor`,
  'Failed to create': `Failed to create`,
  'Failed to create %s': `Failed to create %s`,
  'Failed to create the %s': `Failed to create the %s`,
  'Failed to delete the %s': `Failed to delete the %s`,
  'Failed to update': `Failed to update`,
  'Failed to update the %s': `Failed to update the %s`,
  'Faqs': `Faqs`,
  'Filter list': `Filter list`,
  'Find friends': `Find friends`,
  'Finish': `Finish`,
  'First name': `First name`,
  'Forgot password?': `Forgot password?`,
  'Forgot your password?': `Forgot your password?`,
  'Frequently asked questions': `Frequently asked questions`,
  'Full name': `Full name`,
  'Gender': `Gender`,
  'General': `General`,
  'get start': `get start`,
  'Get started': `Get started`,
  'Global': `Global`,
  'Go': `Go`,
  'Go to Home': `Go to Home`,
  'Golden Clicks': `Golden Clicks`,
  'Have a Question?': `Have a Question?`,
  'Hi, Welcome back': `Hi, Welcome back`,
  'Hide store link': `Hide store link`,
  'Hide stores': `Hide stores`,
  'Home': `Home`,
  'Hours': `Hours`,
  'Ignore balance': `Ignore balance`,
  'Image height must be greater than': `Image height must be greater than`,
  'Image height must be lower than': `Image height must be lower than`,
  'Image ratio must be': `Image ratio must be`,
  'Image width must be greater than': `Image width must be greater than`,
  'Image width must be lower than': `Image width must be lower than`,
  'Images': `Images`,
  'Images is required': `Images is required`,
  'In stock': `In stock`,
  'Includes worldwide shipping': `Includes worldwide shipping`,
  'Income': `Income`,
  'Insert amount': `Insert amount`,
  'Instagram nickname': `Instagram nickname`,
  'Instagram visitors': `Instagram visitors`,
  'Introduction': `Introduction`,
  'Invoice': `Invoice`,
  'Invoice Details': `Invoice Details`,
  'Invoice from': `Invoice from`,
  'Invoice to': `Invoice to`,
  'Is click77': `Is click77`,
  'Is finished': `Is finished`,
  'Is hidden': `Is hidden`,
  'Is sample': `Is sample`,
  'It\'s in the game': `It's in the game`,
  'Item Orders': `Item Orders`,
  'Kanban': `Kanban`,
  'Last name': `Last name`,
  'Let\'s finish powering you up!': `Let's finish powering you up!`,
  'Link e-commerce': `Link e-commerce`,
  'Log out': `Log out`,
  'Login': `Login`,
  'Login with Facebook': `Login with Facebook`,
  'Login with Google': `Login with Google`,
  'Logout': `Logout`,
  'Look like you have no items in your shopping cart': `Look like you have no items in your shopping cart`,
  'Mail': `Mail`,
  'Maintenance': `Maintenance`,
  'Managment': `Managment`,
  'Mark all as read': `Mark all as read`,
  'max size of': `max size of`,
  'Meta description': `Meta description`,
  'Meta keywords': `Meta keywords`,
  'Meta title': `Meta title`,
  'Minimum exposure time': `Minimum exposure time`,
  'Minimum salary in USD': `Minimum salary in USD`,
  'Minutes': `Minutes`,
  'MM/YY': `MM/YY`,
  'Name': `Name`,
  'Name on card': `Name on card`,
  'New %s': `New %s`,
  'New brand to click77': `New brand to click77`,
  'New Password is required': `New Password is required`,
  'New Users': `New Users`,
  'no-reply@click77.app': `no-reply@click77.app`,
  'NOTES': `NOTES`,
  'Observation': `Observation`,
  'OK': `OK`,
  'Old Password': `Old Password`,
  'Order Summary': `Order Summary`,
  'Password': `Password`,
  'Password must be at least 6 characters': `Password must be at least 6 characters`,
  'Passwords must match': `Passwords must match`,
  'Payment': `Payment`,
  'Payment Method': `Payment Method`,
  'Payment options': `Payment options`,
  'Pending': `Pending`,
  'Phone number': `Phone number`,
  'Photo': `Photo`,
  'Photo URL': `Photo URL`,
  'Photos is required': `Photos is required`,
  'Pick your emoji': `Pick your emoji`,
  'Plan': `Plan`,
  'Please check your email': `Please check your email`,
  'Please enter the email address associated with your account and We will email you a link to reset your password.': `Please enter the email address associated with your account and We will email you a link to reset your password.`,
  'Policy': `Policy`,
  'Post': `Post`,
  'Post Details': `Post Details`,
  'Post Title': `Post Title`,
  'Posts': `Posts`,
  'Press enter to separate one coupon from another': `Press enter to separate one coupon from another`,
  'Preview': `Preview`,
  'Price': `Price`,
  'Price includes taxes': `Price includes taxes`,
  'Price should not be $0.00': `Price should not be $0.00`,
  'Pricing': `Pricing`,
  'Privacy Policy': `Privacy Policy`,
  'Prizes': `Prizes`,
  'prizes should not be empty': `Prizes should not be empty`,
  'Prizes to be included in chests': `Prizes to be included in gift box`,
  'Product': `Product`,
  'Product Code': `Product Code`,
  'Product Details': `Product Details`,
  'Product name': `Product name`,
  'Product SKU': `Product SKU`,
  'Product Sold': `Product Sold`,
  'Product url': `Product url`,
  'Product URL within the e-commerce': `Product URL within the e-commerce`,
  'Products': `Products`,
  'Products found': `Products found`,
  'Professional plan is right for you': `Professional plan is right for you`,
  'Profile': `Profile`,
  'Provider': `Provider`,
  'Public Profile': `Public Profile`,
  'Publish': `Publish`,
  'quantity': `quantity`,
  'Rate': `Rate`,
  'Rating is required': `Rating is required`,
  'Ready': `Ready`,
  'Ready to create draw!': `Ready to create draw!`,
  'Redeemed': `Redeemed`,
  'Register': `Register`,
  'Register with Facebook': `Register with Facebook`,
  'Register with Google': `Register with Google`,
  'Registered': `Registered`,
  'Regular Price': `Regular Price`,
  'Reject': `Reject`,
  'Request sent successfully': `Request sent successfully`,
  'Required field': `Required field`,
  'Resend code': `Resend code`,
  'Reset password': `Reset password`,
  'Review': `Review`,
  'Review (%s)': `Review (%s)`,
  'Role': `Role`,
  'Role number': `Role number`,
  'Salary per minute': `Salary per minute`,
  'Sale Price': `Sale Price`,
  'Sales Profit': `Sales Profit`,
  'Save changes': `Save changes`,
  'Search': `Search`,
  'Search %s': `Search %s`,
  'Search contacts': `Search contacts`,
  'Search product': `Search product`,
  'Seconds': `Seconds`,
  'Section': `Section`,
  'Section name': `Section name`,
  'See administrator as user of this brand': `See administrator as user of this brand`,
  'Select brand': `Select brand`,
  'Select your plan that best suits your needs': `Select your plan that best suits your needs`,
  'Set as coming soon': `Set as coming soon`,
  'Settings': `Settings`,
  'Share image': `Share image`,
  'Share what you are thinking here': `Share what you are thinking here`,
  'Shop': `Shop`,
  'Shown as coming soon': `Shown as coming soon`,
  'Sign in to Click77': `Sign in to Click77`,
  'Sign up in Click77': `Sign up in Click77`,
  'SKU': `SKU`,
  'Sold out': `Sold out`,
  'Soon': `Soon`,
  'Sorry, page not found!': `Sorry, page not found!`,
  'Sorry, we are currently undergoing system maintenance, we will enable this option soon': `Sorry, we are currently undergoing system maintenance, we will enable this option soon`,
  'Sorry, we couldn\'t find the page you\'re looking for. Perhaps you\'ve mistyped the URL? Be sure to check your spelling': `Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be sure to check your spelling`,
  'Start': `Start`,
  'start a': `start a`,
  'Start date': `Start date`,
  'Start today': `Start today`,
  'start your draws today and begin to be in the eyes of thousands of': `start your draws today and begin to be in the eyes of thousands of`,
  'State': `State`,
  'State / Region': `State / Region`,
  'Statistics': `Statistics`,
  'Status': `Status`,
  'Subject': `Subject`,
  'Successfully verified!': `Successfully verified!`,
  'Summary': `Summary`,
  'Support': `Support`,
  'Tags': `Tags`,
  'Task name': `Task name`,
  'Terms of Service': `Terms of Service`,
  'TERMS OF USE': `TERMS OF USE`,
  'Thanks for create you brand in click77': `Thanks for create you brand in click77`,
  'The draws have ended': `The draws have ended`,
  'The instagram profile doesn\'t exist': `The instagram profile doesn't exist`,
  'The url must be valid': `The url must be valid`,
  'There was an error, please try again later': `There was an error, please try again later`,
  'this nickname is used': `this nickname is used`,
  'This setting is active': `This setting is active`,
  'thorough your machine': `thorough your machine`,
  'Title': `Title`,
  'To': `To`,
  'Total': `Total`,
  'Total Balance': `Total Balance`,
  'Total Billed': `Total Billed`,
  'Total Booking': `Total Booking`,
  'Total exposure': `Total exposure`,
  'Total players': `Total players`,
  'Town / City': `Town / City`,
  'Transfer Now': `Transfer Now`,
  'Transfer to': `Transfer to`,
  'Type a message': `Type a message`,
  'Unable to logout!': `Unable to logout!`,
  'Update success!': `Update success!`,
  'URL': `URL`,
  'Use this address as default': `Use this address as default`,
  'User': `User`,
  'Users': `Users`,
  'users who participate by winning your draws': `users who participate by winning your draws`,
  'Verify': `Verify`,
  'very soon you will be able to draw products and get followers!': `very soon you will be able to draw products and get followers!`,
  'View as administrator': `View as administrator`,
  'Waiting': `Waiting`,
  'We appreciate your business. Should you need us to add VAT or extra notes let us know!': `We appreciate your business. Should you need us to add VAT or extra notes let us know!`,
  'We have emailed a 6-digit confirmation code to %s, please enter the code in below box to verify your email': `We have emailed a 6-digit confirmation code to %s, please enter the code in below box to verify your email`,
  'We have sent a confirmation email to': `We have sent a confirmation email to`,
  'Website currently under maintenance': `Website currently under maintenance`,
  'Weekly Sales': `Weekly Sales`,
  'welcome to click77': `welcome to click77`,
  'Winners': `Winners`,
  'with': `with`,
  'With end date': `With end date`,
  'Write a comment': `Write a comment`,
  'Write a message': `Write a message`,
  'Write something awesome': `Write something awesome`,
  'You still don\'t have a product': `You still don't have a product`,
  'Your Balance': `Your Balance`,
  'Zip / Code': `Zip / Code`,
}
