import React from 'react'
import type { ReactNode, ForwardedRef } from 'react'
import { m } from 'framer-motion'
import { forwardRef } from 'react'
import { Box, IconButton } from '@mui/material'
import type { IconButtonProps } from '@mui/material'

interface IProps extends IconButtonProps {
  children: ReactNode
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'
  size?: 'small' | 'medium' | 'large'
}

const IconButtonAnimate = forwardRef(({ children, size = 'medium', ...other }: IProps, ref: ForwardedRef<HTMLButtonElement>) => (
  <AnimateWrap size={size}>
    <IconButton size={size} ref={ref} {...other}>
      {children}
    </IconButton>
  </AnimateWrap>
))

IconButtonAnimate.displayName = 'IconButtonAnimate'

export default IconButtonAnimate

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 }
}

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 }
}

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 }
}

interface Props {
  children: ReactNode
  size?: 'small' | 'medium' | 'large'
}

function AnimateWrap({ size, children }: Props) {
  const isSmall = size === 'small'
  const isLarge = size === 'large'

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{
        display: 'inline-flex'
      }}>
      {children}
    </Box>
  )
}
