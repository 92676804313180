// noinspection JSUnusedGlobalSymbols,JSCheckFunctionSignatures

import JSZip from "jszip";

/**
 * jszip
 * @property {JSZip} zip
 * @property {array|any} files
 * @property {any} data
 */
export class jszip {
  constructor(data,zip) {
    this.data = data
    this.zip = zip ? zip : new JSZip()
    this.files = zip ? zip.files : {}
  }
  file(path,content){
    this.files[path] = content
  }
  async generate(opts){
    await Promise.all(Object.entries(this.files).map(([path,content])=>{
      this.zip.file(path,content)
    }))
    return await this.zip.generateAsync(opts)
  }
  static loadAsync = async function(data,opts){
    const jsz = await JSZip.loadAsync(data,opts)
    await Promise.all(Object.entries(jsz.files).map(async([k,v])=>{
      const data = await jsz.file(k).async('binarystring')
      Object.assign(v,{ asBinary(){ return data } })
    }))
    return new jszip(data, jsz)
  }
}
