import React from 'react'
import { m, AnimatePresence } from 'framer-motion'
import { Dialog, Box, Paper } from '@mui/material'
import type { DialogProps, BoxProps } from '@mui/material'
import { varFade } from './variants'
import type { Breakpoint } from '@mui/system'

interface Props extends DialogProps {
  open: boolean
  variants?: Partial<BoxProps>
  size?: Breakpoint
}

export default function DialogAnimate({ open = false, size, variants, onClose, children, sx, ...other }: Props) {
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth={size || 'xs'}
          open={open}
          onClose={onClose}
          PaperComponent={(props) => (
            <Box
              component={m.div}
              {...(variants ||
                varFade({
                  distance: 120,
                  durationIn: 0.32,
                  durationOut: 0.24,
                  easeIn: 'easeInOut'
                }).inUp)}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <Box onClick={onClose as any} sx={{ width: '100%', height: '100%', position: 'fixed' }} />
              <Paper sx={sx} {...props}>
                {props.children}
              </Paper>
            </Box>
          )}
          {...other}>
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  )
}
