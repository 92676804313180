import { useTheme } from '@mui/material/styles'
import useResponsive from '../hooks/useResponsive'
import type { Typography } from '@mui/material/styles/createTypography'
import type { Breakpoint } from '@mui/material'
import { useRef } from 'react'

export type IVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export default function GetFontValue(variant: IVariants) {
  const theme = useTheme()
  const breakpoints = useWidth()

  const key = theme.breakpoints.up(breakpoints === 'xl' ? 'lg' : breakpoints)

  const hasResponsive = variant === 'h1' || variant === 'h2' || variant === 'h3' || variant === 'h4' || variant === 'h5' || variant === 'h6'

  const getFont: Typography = (hasResponsive && theme.typography[variant][key] ? theme.typography[variant][key] : theme.typography[variant]) as Typography

  const fontSize = remToPx(getFont.fontSize)
  const lineHeight = Number(theme.typography[variant].lineHeight) * fontSize
  const { fontWeight } = theme.typography[variant]
  const { letterSpacing } = theme.typography[variant]

  return { fontSize, lineHeight, fontWeight, letterSpacing }
}

export function remToPx(value: string | number) {
  return Math.round(parseFloat(`${value}`) * 16)
}

export function pxToRem(value: number) {
  return `${value / 16}rem`
}

export function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg)
    }
  }
}

function useWidth() {
  const theme = useTheme()
  const keys = [...theme.breakpoints.keys].reverse()
  const self = useRef({ useResponsive })
  self.current = { useResponsive }
  return (
    keys.reduce((output: Breakpoint | null, key) => {
      const matches = self.current.useResponsive('up', key)
      return !output && matches ? key : output
    }, null) || 'xs'
  )
}
