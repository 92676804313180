import { sprintf } from '@/lib/sprintf'
import useLocales from '@/hooks/useLocales'
import * as Yup from 'yup'

export function useYupRequired(name: string) {
  const { translate: _ } = useLocales()
  return sprintf(_('%s is required'), name)
}
export function useYupNumber(name: string) {
  const { translate: _ } = useLocales()
  return sprintf(_('%s must be a number'), name)
}
export function useYupPositive(name: string) {
  return Yup.number().typeError(useYupNumber(name)).positive().label(name).required(useYupRequired(name))
}
export function useYupStringRequired(name: string) {
  return Yup.string().required(useYupRequired(name))
}
export function useYupEmailRequired() {
  const { translate: _ } = useLocales()
  return Yup.string().email(_('Email must be a valid email address')).required(_('Email is required'))
}

export function useYupURLRequired(name: string) {
  // const { translate: _ } = useLocales()
  return Yup.string().required(useYupRequired(name))
  /*
    .url(_('The url must be valid'))
    .test('checkURL', `The url doesn't exist`, function (value) {
      if (!value) return false
      return new Promise(async (res) => {
        try {
          const result = await axios.get(`${API_HOST}/api/v1/settings/valid?url=${encodeURIComponent(value)}`)
          if (result.data.ok) return res(true)
          return res(false)
        } catch (e) {
          res(false)
        }
      })
    })
     */
}

export function useYupInstagramRequired(name: string) {
  // const { translate: _ } = useLocales()
  return Yup.string().required(useYupRequired(name))
  /*
    .test('checkURL', _(`The instagram profile doesn't exist`), function (value) {
      if (!value) return false
      return new Promise(async (res) => {
        try {
          const result = await axios.get(`${API_HOST}/api/v1/settings/valid?url=${encodeURIComponent(`https://www.instagram.com/${value}`)}`)
          if (result.data.ok && result.data.result.title !== 'Instagram') return res(true)
          return res(false)
        } catch (e) {
          res(false)
        }
      })
    })
    */
}
