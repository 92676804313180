export const he = {
  '%s cards': `%s כרטיסים`,
  '%s created successfully!': `%s נוצר בהצלחה!`,
  '%s is required': `נדרשת %s`,
  '%s list': `רשימת %s`,
  '%s must be a number': `%s חייב להיות מספר`,
  '%s successfully created': `%s נוצר בהצלחה`,
  '%s successfully deleted': `%s נמחק בהצלחה`,
  '%s successfully updated': `%s עודכן בהצלחה`,
  '404 Page Not Found': `עמוד 404 לא נמצא`,
  '500 Internal Server Error': `500 שגיאת שרת פנימית`,
  'About': `על אודות`,
  'About us': `עלינו`,
  'Account': `חֶשְׁבּוֹן`,
  'Account Settings': `הגדרות חשבון`,
  'Active': `נכס`,
  'Add': `לְהוֹסִיף`,
  'Add %s': `הוסף %s`,
  'Add Brand': `הוסף מותג`,
  'Add coupons': `הוסף קופונים`,
  'Add coupons to product': `הוסף קופונים למוצר`,
  'Add product': `הוסף מוצר`,
  'Add products': `הוסף מוצרים`,
  'Add products to chests': `הוסף מוצרים לחזה`,
  'Add products to prizes': `הוסף מוצרים לפרסים`,
  'Address': `כתובת`,
  'Adjust the image correctly': `התאם את התמונה בצורה נכונה`,
  'Admin': `מנהל מערכת`,
  'All day': `כל היום`,
  'Allowed': `מוּתָר`,
  'Already have an account?': `כבר יש לך חשבון?`,
  'Amount': `כמות`,
  'Analytics': `ניתוח`,
  'and': `ו`,
  'App': `אפליקציה`,
  'Apply': `להגיש מועמדות`,
  'Approve': `לְאַשֵׁר`,
  'Are you sure you want to delete %i %s?': `האם אתה בטוח שברצונך למחוק את %i% s?`,
  'Are you sure you want to delete this %s?': `האם אתה בטוח שברצונך למחוק את %s זה?`,
  'Ask before share': `שאל לפני שיתוף`,
  'Available': `זמין`,
  'Avatar is required': `נדרש אווטאר`,
  'Awards for slot #77': `פרסים לקופסה מס' 77`,
  'Back': `תחזור`,
  'Balance': `איזון`,
  'Banking': `בַּנקָאוּת`,
  'Banned': `אָסוּר`,
  'Banner': `דֶגֶל`,
  'Billing': `חיוב`,
  'Billing Address': `כתובת לחיוב`,
  'Blog': `בלוג`,
  'Brand': `מותג`,
  'Brand Facebook ID': `מזהה פייסבוק של מותג`,
  'Brand Facebook URL': `כתובת ה-Facebook של המותג`,
  'Brand ID Code': `קוד מזהה מותג`,
  'Brand identity': `זהות מותג`,
  'Brand Instagram name': `שם מותג אינסטגרם`,
  'Brand legal name': `שם חוקי למותג`,
  'Brand name': `שם מותג`,
  'Brands': `מותגים`,
  'browse': `לַחקוֹר`,
  'Bug Reports': `דיווחי שגיאות`,
  'Buy': `לִקְנוֹת`,
  'By registering, I agree to click77': `בהרשמה אני מסכים ל-click77`,
  'Calendar': `לוּחַ שָׁנָה`,
  'Cancel': `לְבַטֵל`,
  'Card number': `מספר כרטיס`,
  'Cards': `קלפים`,
  'Cart is empty': `עגלת הקניות ריקה`,
  'Category': `קטגוריה`,
  'Change user balance for "%s"': `שנה את יתרת המשתמש עבור "%s"`,
  'Change user brands for "%s"': `שנה מותגי משתמש עבור "%s"`,
  'Change user permissions for "%s"': `שנה הרשאות משתמש עבור "%s"`,
  'Chat': `לְשׂוֹחֵחַ`,
  'Check In': `קבלה`,
  'Check Out': `לבדוק`,
  'Checkout': `לבדוק`,
  'chests should not be empty': `שידות לא אמורות להיות ריקות`,
  'City': `עִיר`,
  'Clear All': `נקה הכל`,
  'Click77': `לחץ 77`,
  'Click77 Box Code': `לחץ על קוד תיבה 77`,
  'Click77 Demo Product': `לחץ על 77 מוצר הדגמה`,
  'Clicks': `קליקים`,
  'Close': `סגור`,
  'Code': `קוד`,
  'Color': `צֶבַע`,
  'Coming Soon': `בקרוב`,
  'Comment': `תגובה`,
  'Company': `חֶברָה`,
  'Components Overview': `סקירת רכיבים`,
  'Config brand': `מותג config`,
  'Confirm': `לְאַשֵׁר`,
  'Confirm & Transfer': `אשר והעבר`,
  'Confirm New Password': `תאשר סיסמא חדשה`,
  'Contact us': `צור קשר`,
  'Content': `תוֹכֶן`,
  'Continue': `לְהַמשִׁיך`,
  'Cost': `עֲלוּת`,
  'Countries': `מדינות`,
  'Country': `מדינה`,
  'Coupons': `קופונים`,
  'Cover is required': `נדרש כיסוי`,
  'Create %s': `צור %s`,
  'Create success!': `צור הצלחה!`,
  'Credit': `אַשׁרַאי`,
  'Currency': `מַטְבֵּעַ`,
  'Current downloads': `הורדות נוכחיות`,
  'CVV': `CVV`,
  'Dashboard': `לוּחַ מַחווָנִים`,
  'Date': `תַאֲרִיך`,
  'Days': `ימים`,
  'Default': `בְּרִירַת מֶחדָל`,
  'Delete': `לִמְחוֹק`,
  'Delete %s': `מחק %s`,
  'Delete successful!': `מחק בהצלחה!`,
  'Deliver to this address': `משלוח לכתובת זו`,
  'Delivery options': `אפשרויות משלוח`,
  'Demo store for IOS deploy': `חנות הדגמה לפריסת IOS`,
  'Description': `תיאור`,
  'Different plans for': `תוכניות שונות עבור`,
  'different sizes and needs': `גדלים וצרכים שונים`,
  'discount': `הנחה`,
  'Discount codes / Gifts': `קודי הנחה / מתנות`,
  'Don\'t have a code?': `אין לך קוד?`,
  'Don\'t have an account?': `אין לך חשבון?`,
  'Download %s': `הורד את %s`,
  'Draw': `הַגרָלָה`,
  'draw available for': `הגרלה זמינה עבור`,
  'draw is finished': `ההגרלה הסתיימה`,
  'Draws': `הגרלות`,
  'Drop files here or click': `זרוק קבצים כאן או לחץ`,
  'Drop or Select file': `זרוק או בחר קובץ`,
  'E-Commerce': `מסחר אלקטרוני`,
  'E-Commerce URL': `E-Commerce URL`,
  'E-Commerce visitors': `מבקרי מסחר אלקטרוני`,
  'E-mail': `אימייל`,
  'Edit': `לַעֲרוֹך`,
  'Edit %s': `ערוך %s`,
  'Effort prize that will be given to those who make the most clicks': `פרס מאמץ שיינתן למי שעושה את הכי הרבה קליקים`,
  'Effort prizes': `פרסי מאמץ`,
  'Email': `אימייל`,
  'Email address': `כתובת דוא"ל`,
  'Email is required': `נדרש דוא"ל`,
  'Email must be a valid email address': `דוא"ל חייב להיות כתובת דוא"ל תקפה`,
  'Empty': `ריק`,
  'Empty content': `תוכן ריק`,
  'Enable comments': `אפשר הערות`,
  'End date': `תאריך סיום`,
  'Enter your details below': `הזן את פרטיך למטה.`,
  'Enter your email': `הכנס את האימייל שלך`,
  'Enter your message here': `הזן את ההודעה שלך כאן`,
  'Event': `מִקרֶה`,
  'Expenses': `הוצאות`,
  'Expiration date': `תאריך תפוגה`,
  'Facebook page id': `מזהה עמוד הפייסבוק`,
  'Factor': `גורם`,
  'Failed to create': `היצירה נכשלה`,
  'Failed to create %s': `יצירת %s נכשלה`,
  'Failed to create the %s': `יצירת ה-%s נכשלה`,
  'Failed to delete the %s': `מחיקת ה-%s נכשלה`,
  'Failed to update': `העדכון נכשל`,
  'Failed to update the %s': `עדכון ה-%s נכשל`,
  'Faqs': `שאלות נפוצות`,
  'Filter list': `רשימת סינון`,
  'Find friends': `למצוא חברים`,
  'Finish': `סיים`,
  'First name': `שם פרטי`,
  'Forgot password?': `שכחת את הסיסמא?`,
  'Forgot your password?': `שכחת ססמה?`,
  'Frequently asked questions': `שאלות נפוצות`,
  'Full name': `שם מלא`,
  'Gender': `מִין`,
  'General': `כללי`,
  'get start': `התחל`,
  'Get started': `להתחיל`,
  'Global': `גלוֹבָּלִי`,
  'Go': `ללכת`,
  'Go to Home': `לך הביתה`,
  'Golden Clicks': `קליקים מוזהבים`,
  'Have a Question?': `יש שאלה?`,
  'Hi, Welcome back': `היי, ברוך שובך`,
  'Hide store link': `הסתר קישור לחנות`,
  'Hide stores': `הסתר חנויות`,
  'Home': `בית`,
  'Hours': `שעה (ות`,
  'Ignore balance': `התעלם מאיזון`,
  'Image height must be greater than': `גובה התמונה חייב להיות גדול מ`,
  'Image height must be lower than': `גובה התמונה חייב להיות נמוך מ`,
  'Image ratio must be': `יחס תמונה חייב להיות`,
  'Image width must be greater than': `רוחב התמונה חייב להיות גדול מ`,
  'Image width must be lower than': `רוחב התמונה חייב להיות נמוך מ-`,
  'Images': `תמונות`,
  'Images is required': `יש צורך בתמונות`,
  'In stock': `במלאי`,
  'Includes worldwide shipping': `כולל משלוח עולמי`,
  'Income': `הַכנָסָה`,
  'Insert amount': `הכנס כמות`,
  'Instagram nickname': `כינוי אינסטגרם`,
  'Instagram visitors': `מבקרים באינסטגרם`,
  'Introduction': `מבוא`,
  'Invoice': `חשבונית`,
  'Invoice Details': `פרטי החשבונית`,
  'Invoice from': `חשבונית מ`,
  'Invoice to': `קבלה עבור`,
  'Is click77': `זה קליק77`,
  'Is finished': `הסתיים`,
  'Is hidden': `מוחבא`,
  'Is sample': `הוא מדגם`,
  'It\'s in the game': `זה במשחק`,
  'Item Orders': `הזמנות פריטים`,
  'Kanban': `Kanban`,
  'Last name': `שם משפחה`,
  'Let\'s finish powering you up!': `בואו לסיים \ להניע אותך!`,
  'Link e-commerce': `קישור למסחר אלקטרוני`,
  'Log out': `להתנתק`,
  'Login': `התחברות`,
  'Login with Facebook': `התחבר עם פייסבוק`,
  'Login with Google': `התחבר עם גוגל`,
  'Logout': `להתנתק`,
  'Look like you have no items in your shopping cart': `נראה כאילו אין לך פריטים בעגלת הקניות שלך`,
  'Mail': `דוֹאַר`,
  'Maintenance': `תחזוקה`,
  'Managment': `ניהול`,
  'Mark all as read': `סמן הכל כנקרא`,
  'max size of': `גודל מקסימלי של`,
  'Meta description': `תיאור מטא`,
  'Meta keywords': `מילות מפתח מטא`,
  'Meta title': `תואר מטה`,
  'Minimum exposure time': `זמן חשיפה מינימלי`,
  'Minimum salary in USD': `שכר מינימום בדולר ארה"ב`,
  'Minutes': `דקות`,
  'MM/YY': `חודש שנה`,
  'Name': `שֵׁם`,
  'Name on card': `שם בכרטיס`,
  'New %s': `%s חדש`,
  'New brand to click77': `מותג חדש ל- Click77`,
  'New Password is required': `יש צורך בסיסמה חדשה`,
  'New Users': `משתמשים חדשים`,
  'no-reply@click77.app': `no-reply@click77.app`,
  'NOTES': `הערות`,
  'Observation': `תַצְפִּית`,
  'OK': `בסדר`,
  'Old Password': `סיסמה ישנה`,
  'Order Summary': `סיכום הזמנה`,
  'Password': `סיסמה`,
  'Password must be at least 6 characters': `סיסמא חייבת להיות לפחות בת 6 תווים`,
  'Passwords must match': `הססמאות חייבות להיות זהות`,
  'Payment': `תַשְׁלוּם`,
  'Payment Method': `אמצעי תשלום`,
  'Payment options': `אפשרויות תשלום`,
  'Pending': `ממתין ל`,
  'Phone number': `מספר טלפון`,
  'Photo': `תמונה`,
  'Photo URL': `כתובת אתר של תמונה`,
  'Photos is required': `יש צורך בתמונות`,
  'Pick your emoji': `בחר את האימוג'י שלך`,
  'Plan': `לְתַכְנֵן`,
  'Please check your email': `אנא בדוק את הדוא"ל שלך`,
  'Please enter the email address associated with your account and We will email you a link to reset your password.': `אנא הזן את כתובת הדוא"ל המשויכת לחשבונך ואנחנו נשלח לך דוא"ל קישור לאיפוס הסיסמה שלך.`,
  'Policy': `מְדִינִיוּת`,
  'Post': `הודעה`,
  'Post Details': `פרטי פוסט`,
  'Post Title': `כותרת פוסט`,
  'Posts': `פוסטים`,
  'Press enter to separate one coupon from another': `לחץ על ENTER כדי להפריד קופון אחד למשנהו`,
  'Preview': `תצוגה מקדימה`,
  'Price': `מחיר`,
  'Price includes taxes': `המחיר כולל מיסים`,
  'Price should not be $0.00': `המחיר לא אמור להיות 0.00 $`,
  'Pricing': `תמחור`,
  'Privacy Policy': `מדיניות הפרטיות`,
  'Prizes': `פרסים`,
  'prizes should not be empty': `פרסים לא צריכים להיות ריקים`,
  'Prizes to be included in chests': `פרסים שייכללו בחזה`,
  'Product': `מוצר`,
  'Product Code': `קוד מוצר`,
  'Product Details': `פרטי מוצר`,
  'Product name': `שם מוצר`,
  'Product SKU': `מוצר SKU`,
  'Product Sold': `המוצר נמכר`,
  'Product url': `כתובת אתר של מוצר`,
  'Product URL within the e-commerce': `כתובת מוצר בתוך המסחר האלקטרוני`,
  'Products': `מוצרים`,
  'Products found': `נמצאו מוצרים`,
  'Professional plan is right for you': `תוכנית מקצועית מתאימה לך`,
  'Profile': `פּרוֹפִיל`,
  'Provider': `ספק`,
  'Public Profile': `פרופיל ציבורי`,
  'Publish': `לְפַרְסֵם`,
  'quantity': `כַּמוּת`,
  'Rate': `ציון`,
  'Rating is required': `נדרש דירוג`,
  'Ready': `מוּכָן`,
  'Ready to create draw!': `מוכן ליצור משחק!`,
  'Redeemed': `נפדה`,
  'Register': `הירשם`,
  'Register with Facebook': `הירשם בפייסבוק`,
  'Register with Google': `הירשם בגוגל`,
  'Registered': `רשום`,
  'Regular Price': `מחיר רגיל`,
  'Reject': `לִדחוֹת`,
  'Request sent successfully': `בקשה נשלחת בהצלחה`,
  'Required field': `שדה נדרש`,
  'Resend code': `שלח קוד שוב`,
  'Reset password': `לאפס את הסיסמה`,
  'Review': `סקירה`,
  'Review (%s)': `סקירה (%s)`,
  'Role': `תַפְקִיד`,
  'Role number': `מספר תפקיד`,
  'Salary per minute': `שכר לדקה`,
  'Sale Price': `מחיר מבצע`,
  'Sales Profit': `רווח מכירות`,
  'Save changes': `שמור שינויים`,
  'Search': `לחפש`,
  'Search %s': `חפש ב-%s`,
  'Search contacts': `חיפוש אנשי קשר`,
  'Search product': `מוצר חיפוש`,
  'Seconds': `שניות`,
  'Section': `סָעִיף`,
  'Section name': `שם קטע`,
  'See administrator as user of this brand': `ראה מנהל מערכת כמשתמש של מותג זה`,
  'Select brand': `בחר מותג`,
  'Select your plan that best suits your needs': `בחר את התוכנית שלך המתאימה ביותר לצרכים שלך`,
  'Set as coming soon': `הגדר כפי שיגיע בקרוב`,
  'Settings': `הגדרות`,
  'Share image': null,
  'Share what you are thinking here': `שתף את מה שאתה חושב כאן`,
  'Shop': `לִקְנוֹת`,
  'Shown as coming soon': `מוצג כמגיע בקרוב`,
  'Sign in to Click77': `היכנס ל- Click77`,
  'Sign up in Click77': `הירשם ב- Click77`,
  'SKU': `SKU`,
  'Sold out': `נמכר`,
  'Soon': `בקרוב`,
  'Sorry, page not found!': `סליחה, הדף לא נמצא!`,
  'Sorry, we are currently undergoing system maintenance, we will enable this option soon': `מצטערים, אנו נמצאים כעת בתחזוקת מערכת, נפעיל את האפשרות הזו בקרוב.`,
  'Sorry, we couldn\'t find the page you\'re looking for. Perhaps you\'ve mistyped the URL? Be sure to check your spelling': `מצטערים, לא הצלחנו למצוא את הדף שאתה מחפש. אולי טעית בכתובת האתר? הקפד לבדוק את האיות שלך`,
  'Start': `הַתחָלָה`,
  'start a': `התחל`,
  'Start date': `תאריך התחלה`,
  'Start today': `מתחיל היום`,
  'start your draws today and begin to be in the eyes of thousands of': `התחל את ההגרלות שלך היום ותתחיל להיות בעיני אלפים`,
  'State': `מדינה`,
  'State / Region': `מחוז`,
  'Statistics': `סטָטִיסטִיקָה`,
  'Status': `סטָטוּס`,
  'Subject': `נושא`,
  'Successfully verified!': `אומת בהצלחה!`,
  'Summary': `סיכום`,
  'Support': `תמיכה`,
  'Tags': `תגיות`,
  'Task name': `שם המטלה`,
  'Terms of Service': `תנאי השירות`,
  'TERMS OF USE': `תנאי שימוש`,
  'Thanks for create you brand in click77': `תודה שיצרת את המותג שלך ב- Click77`,
  'The draws have ended': `ההגרלות הסתיימו`,
  'The instagram profile doesn\'t exist': `פרופיל האינסטגרם לא קיים`,
  'The url must be valid': `כתובת האתר חייבת להיות חוקית`,
  'There was an error, please try again later': `אירעה שגיאה, אנא נסה שוב מאוחר יותר`,
  'this nickname is used': `נעשה שימוש בכינוי הזה`,
  'This setting is active': `הגדרה זו פעילה`,
  'thorough your machine': `יסודי את המכונה שלך`,
  'Title': `כותרת`,
  'To': `ל`,
  'Total': `סה"כ`,
  'Total Balance': `יתרה כוללת`,
  'Total Billed': `סה"כ מחויב`,
  'Total Booking': `סך ההזמנה`,
  'Total exposure': `חשיפה כוללת`,
  'Total players': `סך הכל שחקנים`,
  'Town / City': `עיר / עיר`,
  'Transfer Now': `העבר עכשיו`,
  'Transfer to': `העבר ל`,
  'Type a message': `הקלד הודעה`,
  'Unable to logout!': `לא ניתן להתנתק!`,
  'Update success!': `עדכן הצלחה!`,
  'URL': `כתובת אתר`,
  'Use this address as default': `השתמש בכתובת זו כברירת מחדל`,
  'User': `מִשׁתַמֵשׁ`,
  'Users': `משתמשים`,
  'users who participate by winning your draws': `משתמשים שמשתתפים על ידי זכייה בהגרלות שלך`,
  'Verify': `תאשר`,
  'very soon you will be able to draw products and get followers!': `בקרוב מאוד תוכל להגרע מוצרים ולקבל עוקבים!`,
  'View as administrator': `הצג כמנהל`,
  'Waiting': `הַמתָנָה`,
  'We appreciate your business. Should you need us to add VAT or extra notes let us know!': `אנו מעריכים את העסק שלך. אם תזדקק לנו להוסיף מע"מ או הערות נוספות, ספר לנו על כך!`,
  'We have emailed a 6-digit confirmation code to %s, please enter the code in below box to verify your email': `שלחנו בדוא"ל קוד אישור בן 6 ספרות ל- %s, אנא הזן את הקוד בתיבה שלהלן כדי לאמת את הדוא"ל שלך`,
  'We have sent a confirmation email to': `שלחנו דוא"ל אישור ל`,
  'Website currently under maintenance': `האתר כרגע בתחזוקה`,
  'Weekly Sales': `מכירות שבועיות`,
  'welcome to click77': `ברוך הבא ל- Click77`,
  'Winners': `זוכים`,
  'with': `עם`,
  'With end date': `עם תאריך סיום`,
  'Write a comment': `כתוב הערה`,
  'Write a message': `לכתוב הודעה`,
  'Write something awesome': `כתוב משהו מדהים`,
  'You still don\'t have a product': `עדיין אין לך מוצר`,
  'Your Balance': `האיזון שלך`,
  'Zip / Code': `מיקוד`,
}
