import React from 'react'
import type { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { PATH_DASHBOARD } from '@/routes/paths'
import { useAuth } from '@/redux/auth'

export default function GuestGuard({ children }: { children: ReactNode }) {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />
  }

  return <>{children}</>
}
