// noinspection JSUnusedGlobalSymbols

/**
 * Xi Util
 * creator: efexi
 * version: 1.1.4
 * update: 2023-05-05
 */

import CryptoJS from 'crypto-js'

/**
 * Xi: sleep
 * @param {number} ms
 * @return {Promise<void>}
 */
export async function _sleep(ms: number): Promise<void> {
  await new Promise((res) => setTimeout(res, ms))
}

/**
 * Xi: normalize filer
 * @param {string} filter
 * @return {string}
 */
export function _normalizeFilter(filter: string) {
  return filter
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

/**
 * Xi: uniq
 * @param {array} arr
 * @param {string=} key
 * @return {array}
 */
export function _uniq<T>(arr: T[], key?: string) {
  if (key) return Object.values(_indexBy(arr, key as any))
  return arr.filter((value, index, array) => array.indexOf(value) === index)
}

/**
 * Xi: group by
 * @param {array} xs
 * @param {string} key
 * @return {array}
 */
export function _groupBy<T>(xs: T[], key: string): Record<string, T[]> {
  return xs.reduce(function (rv: any, x: any) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

/**
 * Xi: uuid
 * @param {number=} bytes
 * @param {'hash'|'hex'=} type
 * @return string
 */
export function _uuid(bytes: number = 16, type: 'hash' | 'hex' = 'hash'): string {
  const wa = CryptoJS.lib.WordArray.random(bytes)
  const u: string = wa.toString()
  if (type === 'hex') return u
  return `${u.substring(0, 8)}-${u.substring(8, 12)}-${u.substring(12, 16)}-${u.substring(16, 20)}-${u.substring(20)}`
}

/**
 * Xi: upper first
 * @param {string} string
 * @return {string}
 */
export function _upperFirst(string: string) {
  return string.slice(0, 1).toUpperCase() + string.slice(1, string.length)
}

/**
 * Xi: snake to pascal
 * @param {string} string
 * @return {string}
 */
export function _snakeToPascal(string: string) {
  return string
    .replace(/[-_:]/g, '_')
    .split('_')
    .map((str, i) => (i === 0 ? str : _upperFirst(str)))
    .join('')
}

/**
 * Xi: pascal to snake
 * @param {string} string
 * @param {string=} key
 * @return {string}
 */
export function _pascalToSnake(string: string, key: string = '-') {
  return string
    .replace(/\.?([A-Z])/g, function (x, y) {
      return key + y.toLowerCase()
    })
    .replace(new RegExp(`^${key}`), '')
    .replace(/([0-9]+)/g, '-$1')
}

/**
 * Xi: pascal to snake key
 * @param {object} obj
 * @return {object}
 */
export function _pascalToSnakeKey<T>(obj: T): T {
  return Object.entries(obj as any).reduce((c, v) => {
    return { ...c, [_pascalToSnake(v[0])]: v[1] }
  }, {} as any)
}

/**
 * Xi: index by
 * @param {array} arr
 * @param {string} key
 * @param {function} cond
 * @return {object}
 */
export function _indexBy<T>(arr: T[], key: keyof T, cond?: (item: T) => boolean): Record<string, T> {
  return arr.reduce((c, v) => ({ ...c, ...(typeof cond === 'function' && !cond(v) ? {} : { [v[key] as string]: v }) }), {} as any)
}

/**
 * Xi: pluck
 * @param {array} arr
 * @param {string} key
 * @return {array}
 */
export function _pluck<T>(arr: T[], key: keyof T): any[] {
  return arr.map((i: any) => i[key])
}

/**
 * Xi: extract by
 * @param {array} arr
 * @param {string} key
 * @param {string} extract
 * @param {function} cond
 * @return {object}
 */
export function _extractBy<T, K extends keyof T>(arr: T[], key: keyof T, extract: K, cond?: (item: T) => boolean): Record<string, T[K]> {
  return arr.reduce((c, v) => ({ ...c, ...(typeof cond === 'function' && !cond(v) ? {} : { [v[key] as string]: extract ? v[extract] : v }) }), {} as any)
}

/**
 * Xi: new array
 * @param {number} length
 * @param {any|'i'|'n'=} fill
 * @param {number=} mult
 * @param {number=} ini
 * @return {array}
 */
export function _newArray(length: number, fill?: 'i' | 'n' | any, mult: number = 1, ini: number = 0) {
  return Array.from({ length }, (_, i) => {
    if (fill === undefined) return null
    if (['i', 'n'].includes(fill)) return (i + (fill === 'i' ? 0 : 1)) * mult + ini
    return fill
  })
}

/**
 * Xi: sort keys
 * @param {Record<string,any>} obj
 * @param {string=} key
 * @return {Record<string,any>}
 */
export function _sortKeys<T extends Record<string, any>>(obj: T, key?: string): T {
  if (key !== undefined) {
    const keys: Record<string, string> = {}
    Object.entries(obj).forEach(([k, v]) => (keys[key ? v[key] : v] = k))
    return Object.keys(keys)
      .sort()
      .reduce((c, k) => {
        return { ...c, [keys[k]]: obj[keys[k]] }
      }, {} as any)
  }
  return Object.keys(obj)
    .sort()
    .reduce((o, k) => {
      o[k] = obj[k]
      return o
    }, {} as any)
}

/**
 * Xi: to fixed
 * @param {number} value
 * @param {number} decimals
 * @param {number} range
 * @return {number}
 */
export function _toFixed(value: number, decimals: number, range: number = 0): number {
  const dec = Math.pow(10, decimals)
  const res = Math.round(value * dec) / dec
  if (range > 0) {
    const index = (res + '').indexOf('.')
    if (index > -1) {
      if (index < range) {
        const _dec = decimals - (res + '').substring(range).length
        if (_dec < 1) return value
        const $dec = Math.pow(10, _dec)
        return Math.round(res * $dec) / $dec
      }
      return Math.round(res)
    }
  }
  return res
}

/**
 * Xi: trasposeMatrix
 * @param {array} array
 * @return {array}
 */
export function _trasposeMatrix(array: number[][]): number[][] {
  return array[0].map((_, colIndex) => array.map((row) => row[colIndex]))
}

/**
 * Xi: debounce
 * @param {Function} func
 * @param {number} wait
 * @param {boolean=} immediate
 * @return {void}
 */
export function _debounce(func: Function, wait: number, immediate?: boolean) {
  let timeout: any
  return function (this: any) {
    const context = this,
      args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, wait)
    if (immediate && !timeout) func.apply(context, args)
  }
}

/**
 * Xi: extend
 * @param {any} target
 * @param {...any} sources
 */
export function _extend(target: any, ...sources: any[]) {
  const length = sources.length
  if (length < 1 || target == null) return target
  for (let i = 0; i < length; i++) {
    const source = sources[i]
    for (const key in source) target[key] = source[key]
  }
  return target
}

/**
 * Xi: moustache
 * @param {string} url
 * @param {Record<string,string>} params
 */
export function _moustache(url: string, params: Record<string, string>) {
  return url.replace(/\{.*?([a-zA-Z0-9]*).*?}/g, (__: string, key: string) => {
    return key && params[key] ? params[key] : ''
  })
}
