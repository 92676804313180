import React from 'react'
import type { ReactNode, Reducer } from 'react'
import { createContext, useEffect, useReducer } from 'react'
import { apiAuthLoadScript } from '@/redux/auth'
import type { DTO_200_Brand } from '@/api/models'

const initialState: IFacebookContextState = {
  isInitialized: false,
  isAuthenticated: false,
  loading: false,
  page: {
    idPageFacebook: '',
    name: '',
    photo: ''
  },
  brands: []
}

interface IFacebookContextState {
  isInitialized: boolean
  isAuthenticated: boolean
  loading: boolean
  page: {
    idPageFacebook: string
    name: string
    photo: string
  }
  brands: DTO_200_Brand[]
}

const reducer = (state: IFacebookContextState, action: { type: string; payload: Partial<IFacebookContextState> }) => {
  if (action.type === 'INITIALISE') {
    return {
      ...state,
      isInitialized: true
    }
  }

  if (action.type === 'AUTH') {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: true
    }
  }

  if (action.type === 'AUTH-FAIL') {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: false
    }
  }

  if (action.type === 'START-LOADING-PAGES') {
    return {
      ...state,
      loading: true
    }
  }

  if (action.type === 'END-LOADING-PAGES') {
    return {
      ...state,
      loading: false,
      brands: action.payload
    }
  }

  if (action.type === 'SET-PAGES') {
    return {
      ...state,
      page: action.payload
    }
  }

  return state
}

const FacebookContext = createContext({
  ...initialState,
  login: (...args: any[]) => Promise.resolve(),
  setPage: (...args: any[]) => Promise.resolve(),
  getPage: (...args: any[]) => Promise.resolve(),
  getPages: (...args: any[]) => Promise.resolve()
})

function FacebookProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer<Reducer<any, any>>(reducer, initialState)
  useEffect(() => {
    ;(async () => {
      await apiAuthLoadScript('facebook-api', 'https://connect.facebook.net/es_CL/sdk.js')
      await FB.init({
        appId: 175891485794118,
        autoLogAppEvents: true,
        version: 'v17.0'
      })
      dispatch({ type: 'INITIALISE' })
    })().then()
  }, [dispatch])

  const getPage = async (pageId: string) => {
    const path = `/${pageId}`
    return new Promise((resolve, reject) => {
      window.FB.api(path, 'get', { fields: ['name', 'fan_count', 'picture', 'can_post'] }, (response: any) => {
        if (!response || response.error) {
          reject()
        } else {
          const { fan_count, can_post, ...others } = response
          const payload = {
            ...others,
            fanCount: fan_count,
            canPost: can_post
          }
          resolve(payload)
        }
      })
    })
  }

  const setPage = async (pageId: string) => {
    const { brands } = state

    const page = brands.find((brand: DTO_200_Brand) => brand._id === pageId)

    const { picture } = page
    const { data } = picture
    const { url } = data

    const payload = {
      idPageFacebook: page.id,
      name: page.name,
      photo: url
    }

    dispatch({ type: 'SET-PAGES', payload })
  }

  const getPages = async () =>
    new Promise((resolve: (...args: any[]) => any, reject) => {
      dispatch({ type: 'START-LOADING-PAGES' })
      const path = '/me'
      FB.api(path, 'get', { fields: 'accounts' }, async (response: any) => {
        if (!response || response.error) {
          reject()
        } else {
          const payload: any[] = []
          const { accounts } = response
          if (accounts) {
            await Promise.all(
              accounts.data.map(async (pageInfo: any) => {
                const { id: pageId } = pageInfo
                const info = await getPage(pageId)
                payload.push(info)
              })
            )
          }
          dispatch({ type: 'END-LOADING-PAGES', payload })
          resolve()
        }
      })
    })

  const login = async () =>
    new Promise((resolve: (...args: any[]) => any, reject) => {
      window.FB.login(
        (response: any) => {
          if (response.authResponse) {
            dispatch({ type: 'AUTH' })
            resolve()
          } else {
            dispatch({ type: 'AUTH-FAIL' })
            reject()
          }
        },
        {
          scope: 'pages_show_list',
          auth_type: 'rerequest'
        }
      )
    })

  return (
    <FacebookContext.Provider
      value={{
        ...state,
        login,
        setPage,
        getPage,
        getPages
      }}>
      {children}
    </FacebookContext.Provider>
  )
}

export { FacebookContext, FacebookProvider }
