import React from 'react'
import createAvatar from '../utils/createAvatar'
import Avatar from './Avatar'
import type { AvatarProps } from '@mui/material/Avatar/Avatar'
import { useAuthUserData } from '@/redux/auth'

export default function MyAvatar({ ...other }: AvatarProps) {
  const user = useAuthUserData()
  const color = user?.photoURL ? 'default' : createAvatar(user?.displayName).color

  return (
    <Avatar src={user?.photoURL} alt={user?.displayName} color={color as any} {...other}>
      {createAvatar(user?.displayName).name}
    </Avatar>
  )
}
