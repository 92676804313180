import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import { Box, Stack, Drawer, Typography } from '@mui/material'
import useResponsive from '../../../hooks/useResponsive'
import useCollapseDrawer from '../../../hooks/useCollapseDrawer'
import cssStyles from '../../../utils/cssStyles'
import { NAVBAR, VERSION } from '@/config'
import Logo from '../../../components/Logo'
import Scrollbar from '../../../components/Scrollbar'
import { NavSectionVertical } from '../../../components/nav-section'
import NavbarAccount from './NavbarAccount'
import CollapseButton from './CollapseButton'
import type { SxProps } from '@mui/system'
import { useNavConfig } from '@/routes'
import { useIsAdmin } from '@/redux/auth'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter
    })
  }
}))

interface NavbarVerticalProps {
  isOpenSidebar?: boolean
  onCloseSidebar?(...args: any[]): any
}

export default function NavbarVertical({ isOpenSidebar = false, onCloseSidebar }: NavbarVerticalProps) {
  const theme = useTheme()
  const { pathname } = useLocation()
  const isDesktop = useResponsive('up', 'lg')
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer()
  const self = useRef({ onCloseSidebar, isOpenSidebar })
  self.current = { onCloseSidebar, isOpenSidebar }
  useEffect(() => {
    if (self.current.isOpenSidebar) self.current.onCloseSidebar?.()
  }, [pathname])
  const navConfig = useNavConfig()
  const isAdmin = useIsAdmin()

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
      }}>
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' })
        }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Logo />

          {isDesktop && !isCollapse && <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
        </Stack>

        {!isAdmin && <NavbarAccount isCollapse={isCollapse} />}
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
      <Typography variant="inherit" sx={{ m: 3, lineHeight: 1.5714285714285714, fontSize: '0.875rem', fontFamily: 'Nunito,sans-serif', fontWeight: 900, opacity: 0.8, fontStyle: 'italic', color: '#2962ff' }}>{`v${VERSION}`}</Typography>
    </Scrollbar>
  )

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}>
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: ($theme: ITheme) =>
                $theme.transitions.create?.('width', {
                  duration: $theme.transitions.duration?.standard
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: ($theme: ITheme) => $theme.customShadows.z24
              })
            } as SxProps
          }}>
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  )
}
