import React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { Typography, Grid, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'
import useLocales from '../../../hooks/useLocales'
import { UploadAvatar, UploadSingleFile } from '../../../components/upload'
import { fData } from '@/utils/formatNumber'
import { apiBrandCreate, apiBrandSetDirect } from '@/redux/brand'
import { useAuthFacebook, useAuthUser } from '@/redux/auth'
import { sprintf } from '@/lib'
import { checkEasyCrop, setLoading } from '@/redux/reaction'
import { _fromFileToImageFile, _getFilesFromEvent, _validateImage } from '@/lib/xi'
import type { IImageFile } from '@/components/EasyCrop'
import type { DTO_200_Brand } from '@/api/models'

const PhotoBoxStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 3),
  borderRadius: 16,
  textAlign: 'center'
}))
const PhotoBoxFlagStyle = styled('div')(() => ({
  borderRadius: 16,
  textAlign: 'center'
}))

interface Props {
  nextStep?(...args: any[]): any
  isSubmitting?: boolean
}

export function CreateStoreForm({ nextStep }: Props) {
  const self = useRef<any>({})
  const user = useAuthUser()
  const { page } = useAuthFacebook()
  const [isValidatingNickname] = useState(false)
  const [photo, setPhoto] = useState<any>(null)
  const [photoFlag, setPhotoFlag] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const { translate: _ } = useLocales()

  const CreateStoreSchema = Yup.object().shape({
    name: Yup.string().required(_('Required field')),
    instagramNickname: Yup.string().required(_('Required field')),
    ecommerce: Yup.string().required(_('Required field'))
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // name: '',
      instagramNickname: '',
      ecommerce: '',
      ...page,
      photo: '',
      photoFlag: ''
    },
    validationSchema: CreateStoreSchema,
    onSubmit: async (values, { setFieldError, setSubmitting }) => {
      try {
        const brand = await apiBrandCreate(values, photo, photoFlag)
        if (brand) apiBrandSetDirect(brand as DTO_200_Brand, user)
        setSubmitting(false)
        enqueueSnackbar(sprintf(_('%s created successfully!'), _('Brand')), { variant: 'success' })
        nextStep?.()
      } catch (error) {
        const { code, message } = error as Error & { code: number }
        if (code === 400 && message === 'this nickname is used') {
          setFieldError('nickname', _('this nickname is used'))
        } else {
          enqueueSnackbar(sprintf(_('Failed to create %s'), _('Brand')), { variant: 'error' })
        }
        setSubmitting(false)
      }
    }
  })

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik

  self.current.page = page
  self.current.setFieldValue = setFieldValue
  useEffect(() => {
    const donwloadFile = async () => {
      fetch(self.current.page.photo, {
        method: 'GET',
        headers: {}
      })
        .then((response) => {
          response.arrayBuffer().then(function (buffer) {
            const file = new Blob([buffer])
            setPhoto(file)
            self.current.setFieldValue('photo', {
              file,
              preview: URL.createObjectURL(file)
            })
          })
        })
        .catch((err) => {
          console.error(err)
        })
    }
    donwloadFile().then()
  }, [])

  const handleDropPhoto = useCallback(
    async (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) return
      setLoading(true)
      let file = await _fromFileToImageFile(acceptedFiles[0] as IImageFile)
      if (!file) return setLoading(false)
      file.options = {
        maxWidth: 600,
        maxHeight: 600,
        ratio: 1,
        quality: 0.8,
        type: 'image/jpeg'
      }
      setLoading(false)
      file = await checkEasyCrop(file, true)
      if (file) await setFieldValue('photo', file)
    },
    [setFieldValue]
  )

  const handleDropBanner = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      if (file) {
        setFieldValue('photoFlag', file)
        setPhotoFlag(file)
      }
    },
    [setFieldValue]
  )

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ pt: 1 }}>
          <Grid item xs={12} md={4}>
            <PhotoBoxStyle>
              <UploadAvatar
                accept="image/*"
                file={values.photo}
                maxSize={3145728}
                onDrop={handleDropPhoto}
                error={Boolean(touched.photo && errors.photo)}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary'
                    }}>
                    {`${_('Allowed')} *.jpeg, *.jpg, *.png, *.gif`}
                    <br /> {_('max size of')} {fData(3145728)}
                  </Typography>
                }
              />
            </PhotoBoxStyle>
          </Grid>
          <Grid item xs={12} md={6}>
            <PhotoBoxFlagStyle>
              <UploadSingleFile
                accept="image/*"
                file={values.photoFlag}
                maxSize={3145728}
                onDrop={handleDropBanner}
                error={Boolean(touched.photoFlag && errors.photoFlag)}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary'
                    }}>
                    {`${_('Allowed')} *.jpeg, *.jpg, *.png, *.gif`}
                    <br /> {_('max size of')} {fData(3145728)}
                  </Typography>
                }
                getFilesFromEvent={_getFilesFromEvent({
                  minWidth: 900,
                  minHeight: 600,
                  ratio: 1.5
                })}
                validator={_validateImage(_, {
                  minWidth: 900,
                  minHeight: 600,
                  ratio: 1.5
                })}
              />
            </PhotoBoxFlagStyle>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth required label={_('Name')} {...getFieldProps('name')} value={values.name} disabled={isSubmitting} error={Boolean(errors.name && touched.name)} helperText={touched.name && errors.name} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth required label={_('Instagram nickname')} {...getFieldProps('instagramNickname')} value={values.instagramNickname} error={Boolean(errors.instagramNickname && touched.instagramNickname)} helperText={touched.instagramNickname && errors.instagramNickname} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth required label={_('E-Commerce')} {...getFieldProps('ecommerce')} value={values.ecommerce} disabled={isSubmitting} error={Boolean(errors.ecommerce && touched.ecommerce)} helperText={touched.ecommerce && errors.ecommerce} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton variant="contained" loading={isSubmitting || isValidatingNickname} type="submit">
              {sprintf(_('Create %s'), _('Brand'))}
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}
