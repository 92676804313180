import type { BreadcrumbsProps } from '@mui/material'
import type { ReactNode } from 'react'
import HeaderBreadcrumbs from '@/components/HeaderBreadcrumbs'
import React from 'react'
import { useBreadCrumbs } from '@/routes'

interface PropTypes extends BreadcrumbsProps {
  heading?: string
  name?: string
  action?: ReactNode
  hideBreadcrumbs?: boolean
}
export function NavBreadcrumbs({ name, heading, action, hideBreadcrumbs, sx, ...other }: PropTypes) {
  const link = useBreadCrumbs()
  const links = link?.links || []
  if (name && links.length) links[links.length - 1].name = name
  return <HeaderBreadcrumbs heading={heading || link?.title || ''} links={hideBreadcrumbs ? [] : links} action={action} sx={sx} {...other} />
}
