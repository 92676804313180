import React, { useMemo } from 'react'
import type { ReactNode } from 'react'
import { CssBaseline } from '@mui/material'
import type { Direction, ThemeOptions } from '@mui/material'
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import useSettings from '../hooks/useSettings'
import palette from './palette'
import typography from './typography'
import breakpoints from './breakpoints'
import componentsOverride from './overrides'
import shadows, { customShadows } from './shadows'
import type { PaletteOptions } from '@mui/material'
import type { TypographyOptions } from '@mui/material/styles/createTypography'
import type { Shadows } from '@mui/material/styles/shadows'

export default function ThemeProvider({ children }: { children: ReactNode }) {
  const { themeMode, themeDirection } = useSettings()
  const isLight = themeMode === 'light'

  const themeOptions = useMemo<ThemeOptions>(
    () => ({
      palette: (isLight ? palette.light : palette.dark) as PaletteOptions,
      typography: typography as TypographyOptions,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection as Direction,
      shadows: (isLight ? shadows.light : shadows.dark) as Shadows,
      customShadows: isLight ? customShadows.light : customShadows.dark
    }),
    [isLight, themeDirection]
  )

  const theme = createTheme(themeOptions)
  theme.components = componentsOverride(theme as unknown as ITheme)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
