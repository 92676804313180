import React, { useRef } from 'react'
import * as Yup from 'yup'
import { useCallback, useEffect, useMemo } from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, Card, Grid, Stack, Typography } from '@mui/material'
import { fData } from '@/utils/formatNumber'
import { PATH_DASHBOARD } from '@/routes/paths'
import { FormProvider, RHFSwitch, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form'
import useLocales from '@/hooks/useLocales'
import { sprintf } from '@/lib'
import { useYupEmailRequired, useYupStringRequired } from '@/lib/helpers'
import { useIdEditPath } from '@/hooks/usePath'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '@/redux/user'

export default function UserNewForm() {
  const isEdit = useIdEditPath()
  const currentUser = useSelector(selectCurrentUser)

  const { translate: _ } = useLocales()
  const navigate = useNavigate()

  const { enqueueSnackbar } = useSnackbar()

  const NewUserSchema = Yup.object().shape({
    name: useYupStringRequired(_('Name')),
    lastName: useYupStringRequired(_('Last name')),
    email: useYupEmailRequired()
    // phoneNumber: useYupStringRequired(_('Phone number')),
    // address: useYupStringRequired(_('Address')),
    // country: useYupStringRequired(_('Country')),
    // company: useYupStringRequired(_('Company')),
    // state: useYupStringRequired(_('State')),
    // city: useYupStringRequired(_('City')),
    // role: useYupStringRequired(_('Role number')),
    // photo: Yup.mixed().test('required', _('Avatar is required'), (value) => value !== '')
  })

  const defaultValues = useMemo(
    () => ({
      name: currentUser?.name || '',
      lastName: currentUser?.lastName || '',
      email: currentUser?.email || '',
      password: currentUser?.password || '',
      photo: currentUser?.photo || '',
      /*
      phoneNumber: currentUser?.phoneNumber || '',
      address: currentUser?.address || '',
      country: currentUser?.country || '',
      state: currentUser?.state || '',
      city: currentUser?.city || '',
      zipCode: currentUser?.zipCode || '',
      photo: currentUser?.photo || '',
      status: currentUser?.status,
      */
      // company: currentUser?.company || '',
      isVerified: true
    }),
    [currentUser]
  )

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues
  })

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting }
  } = methods

  // noinspection JSUnusedLocalSymbols
  const values = watch()

  const self = useRef({ reset, defaultValues })
  self.current = { reset, defaultValues }

  useEffect(() => {
    if (isEdit && currentUser) self.current.reset(self.current.defaultValues)
    if (!isEdit) self.current.reset(self.current.defaultValues)
  }, [isEdit, currentUser])

  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500))
      reset()
      enqueueSnackbar(!isEdit ? _('Create success!') : _('Update success!'))
      navigate(PATH_DASHBOARD.user.list)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]

      if (file) {
        setValue(
          'photo',
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      }
    },
    [setValue]
  )

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3 }}>
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="photo"
                accept="image/*"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary'
                    }}>
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>

            <RHFSwitch
              name="isVerified"
              labelPlacement="start"
              label={
                <>
                  <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Email Verified
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Disabling this will automatically send the user a verification email
                  </Typography>
                </>
              }
              sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }
              }}>
              <RHFTextField name="name" label={_('Name')} />
              <RHFTextField name="name" label={_('Last name')} />
              <RHFTextField name="email" label={_('Email address')} />
              <RHFTextField name="password" label={_('Password')} />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? sprintf(_('Create %s'), _('User')) : _('Save changes')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
