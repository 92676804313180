// noinspection JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import { saveAs } from 'file-saver'

export const ExpoFileSystemData: Record<string, Blob> = {}

export type DeletingOptions = {
  /**
   * If `true`, don't throw an error if there is no file or directory at this URI.
   * @default false
   */
  idempotent?: boolean
}

export type WritingOptions = {
  /**
   * The encoding format to use when writing the file.
   * @default FileSystem.EncodingType.UTF8
   */
  encoding?: EncodingType | 'utf8' | 'base64'
}

export enum EncodingType {
  /**
   * Standard encoding format.
   */
  UTF8 = 'utf8',
  /**
   * Binary, radix-64 representation.
   */
  Base64 = 'base64'
}

export type ReadingOptions = {
  /**
   * The encoding format to use when reading the file.
   * @default EncodingType.UTF8
   */
  encoding?: EncodingType | 'utf8' | 'base64'
  /**
   * Optional number of bytes to skip. This option is only used when `encoding: FileSystem.EncodingType.Base64` and `length` is defined.
   * */
  position?: number
  /**
   * Optional number of bytes to read. This option is only used when `encoding: FileSystem.EncodingType.Base64` and `position` is defined.
   */
  length?: number
}

export const documentDirectory = ''

export async function readAsStringAsync(fileUri: string, options?: ReadingOptions): Promise<string> {
  const res = await fetch(fileUri, { mode: 'no-cors' })
  switch (options?.encoding) {
    case 'base64':
      const blob = await res.blob()
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      const result = await new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result as any)
        }
      })
      return result as string
    case 'utf8':
    default:
      return res.text()
  }
}

export async function downloadAsync(uri: string, fileUri: string, options: DownloadOptions = {}): Promise<FileSystemDownloadResult> {
  let blob = ExpoFileSystemData[fileUri]
  let headers: any = {}
  if (!blob) {
    const res = await fetch(uri)
    headers = res.headers
    blob = await res.blob()
  }
  saveAs(blob, fileUri)
  return { headers, md5: '', mimeType: '', status: 0, uri }
}

function b64toBlob(b64Data: string, contentType?: string, sliceSize = 512) {
  const byteCharacters = atob(b64Data)
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  return new Blob(byteArrays, contentType ? { type: contentType } : undefined)
}

export async function writeAsStringAsync(fileUri: string, contents: string, options?: WritingOptions): Promise<void> {
  switch (options?.encoding) {
    case 'base64':
      ExpoFileSystemData[fileUri] = b64toBlob(contents)
      break
    case 'utf8':
    default:
      ExpoFileSystemData[fileUri] = new Blob([contents])
      break
  }
}

export async function deleteAsync(fileUri: string, options: DeletingOptions = {}): Promise<void> {}

export type DownloadOptions = {
  /**
   * If `true`, include the MD5 hash of the file in the returned object. Provided for convenience since it is common to check the integrity of a file immediately after downloading.
   * @default false
   */
  md5?: boolean
  // @docsMissing
  cache?: boolean
  /**
   * An object containing all the HTTP header fields and their values for the download network request. The keys and values of the object are the header names and values respectively.
   */
  headers?: Record<string, string>
  /**
   * A session type. Determines if tasks can be handled in the background. On Android, sessions always work in the background and you can't change it.
   * @default FileSystemSessionType.BACKGROUND
   * @platform ios
   */
  sessionType?: FileSystemSessionType
}

export enum FileSystemSessionType {
  /**
   * Using this mode means that the downloading/uploading session on the native side will work even if the application is moved to background.
   * If the task completes while the application is in background, the Promise will be either resolved immediately or (if the application execution has already been stopped) once the app is moved to foreground again.
   * > Note: The background session doesn't fail if the server or your connection is down. Rather, it continues retrying until the task succeeds or is canceled manually.
   */
  BACKGROUND = 0,
  /**
   * Using this mode means that downloading/uploading session on the native side will be terminated once the application becomes inactive (e.g. when it goes to background).
   * Bringing the application to foreground again would trigger Promise rejection.
   */
  FOREGROUND = 1
}

export type FileSystemDownloadResult = FileSystemHttpResult & {
  /**
   * A `file://` URI pointing to the file. This is the same as the `fileUri` input parameter.
   */
  uri: string
  /**
   * Present if the `md5` option was truthy. Contains the MD5 hash of the file.
   */
  md5?: string
}

export type FileSystemHttpResult = {
  /**
   * An object containing all the HTTP response header fields and their values for the download network request.
   * The keys and values of the object are the header names and values respectively.
   */
  headers: Record<string, string>
  /**
   * The HTTP response status code for the download network request.
   */
  status: number
  // @docsMissing
  mimeType: string | null
}
