import React, { useCallback, useMemo } from 'react'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { alpha } from '@mui/material/styles'
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material'
import { PATH_AUTH } from '@/routes/paths'
import useIsMountedRef from '../../../hooks/useIsMountedRef'
import MyAvatar from '../../../components/MyAvatar'
import MenuPopover from '../../../components/MenuPopover'
import { IconButtonAnimate } from '../../../components/animate'
import { selectAuthUserBrandId, useLogout, useViewAsUser } from '@/redux/auth'
import useLocales from '@/hooks/useLocales'
import { useSelector } from 'react-redux'

export default function AccountPopover() {
  const { translate: _ } = useLocales()
  const navigate = useNavigate()
  const [logout, user] = useLogout()
  const isMountedRef = useIsMountedRef()
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(null)

  const handleOpen = useCallback((event: any) => {
    setOpen(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(null)
  }, [])

  const handleLogout = useCallback(async () => {
    try {
      await logout()
      navigate(PATH_AUTH.login, { replace: true })
      if (isMountedRef.current) handleClose()
    } catch (error) {
      console.error(error)
      enqueueSnackbar(_('Unable to logout!'), { variant: 'error' })
    }
  }, [_, enqueueSnackbar, handleClose, isMountedRef, logout, navigate])

  const authUserBrandId = useSelector(selectAuthUserBrandId)
  const viewAsUser = useViewAsUser()

  const MENU_OPTIONS = useMemo(
    () => [
      {
        label: _('Home'),
        linkTo: '/'
      },
      /*
      {
        label: _('Profile'),
        linkTo: PATH_DASHBOARD.user.profile
      },
      {
        label: _('Settings'),
        linkTo: PATH_DASHBOARD.user.account
      }
      */
      ...(authUserBrandId
        ? [
            {
              label: _('View as administrator'),
              onClick() {
                handleClose()
                viewAsUser(undefined)
              }
            }
          ]
        : [])
    ],
    [_, authUserBrandId, handleClose, viewAsUser]
  )

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...((open || {}) && {
            '&:before': {
              zIndex: 1,
              content: `''`,
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8)
            }
          })
        }}>
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75
          }
        }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option?.linkTo || '#'} component={RouterLink} onClick={() => (option.onClick ? option.onClick() : handleClose())}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  )
}
