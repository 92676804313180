import React from 'react'
import type { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import { forwardRef } from 'react'
import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { useBreadCrumbs } from '@/routes'

interface Props extends BoxProps {
  children: ReactNode
  title?: string
  meta?: ReactNode
  noTitle?: boolean
}

const Page = forwardRef(({ children, title, noTitle, meta, ...other }: Props, ref) => {
  const link = useBreadCrumbs()
  return (
    <>
      {noTitle ? null : (
        <Helmet>
          <title>{`${title || link?.title || ''} | Click77`}</title>
          {meta}
        </Helmet>
      )}
      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  )
})

Page.displayName = 'Page'

export default Page
