import React from 'react'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { styled } from '@mui/material/styles'
import { Typography, Box, Container, Button, AppBar, Toolbar, Card, Stepper, Step, StepLabel, StepContent, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import useLocales from '../../hooks/useLocales'
import useOffSetTop from '../../hooks/useOffSetTop'
import Iconify from '../../components/Iconify'
import Logo from '../../components/Logo'
import { CreateStoreForm } from '@/sections/auth/register-brand'
import { useAuthFacebook, useLogout } from '@/redux/auth'
import { apiBrandSetHasBrand } from '@/redux/brand'
import { useYupStringRequired } from '@/lib/helpers'

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 88

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}))

const ToolbarShadowStyle = styled('div')(({ theme: $theme }) => {
  const theme = $theme as unknown as ITheme
  return {
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    margin: 'auto',
    borderRadius: '50%',
    position: 'absolute',
    width: 'calc(100% - 48px)',
    boxShadow: theme.customShadows.z8
  }
})

const ToolbarSpacer = styled('div')(({ theme }) => ({
  width: '100%',
  height: APP_BAR_MOBILE,
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}))

const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '100%',
  background: 'linear-gradient(30deg, #b9f3fa, transparent)',
  paddingBottom: theme.spacing(6)
}))

const RegisterStore = () => {
  const isOffset = useOffSetTop(100)
  const [logout] = useLogout()
  const { isAuthenticated } = useAuthFacebook()
  const [activeStep, setActiveStep] = useState(0)
  const { translate: _ } = useLocales()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  useEffect(() => {
    if (isAuthenticated) {
      setActiveStep(1)
    }
  }, [isAuthenticated])

  const PaymentSchema = Yup.object().shape({
    idPageFacebook: useYupStringRequired(_('Facebook page id'))
  })

  const defaultValues = {
    idPageFacebook: '',
    name: '',
    photo: '',
    ecommerce: ''
  }

  const methods = useForm({
    resolver: yupResolver(PaymentSchema),
    defaultValues
  })

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods

  const onSubmit = async () => {
    try {
      handleNext()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
        <ToolbarStyle
          disableGutters
          sx={{
            bgcolor: 'background.default',
            ...(isOffset && {
              height: { md: APP_BAR_DESKTOP - 16 }
            })
          }}>
          <Container
            maxWidth="lg"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <Stack justifyContent="space-between" direction="row" sx={{ flex: 1 }}>
              <Logo />
              <Button onClick={logout} startIcon={<Iconify icon={'eva:log-out-outline'} color="#434343" width={24} height={24} />}>
                <Typography sx={{ color: '#434343' }}>{_('Logout')}</Typography>
              </Button>
            </Stack>
          </Container>
        </ToolbarStyle>
        <ToolbarShadowStyle />
      </AppBar>
      <RootStyle>
        <ToolbarSpacer />
        <Container maxWidth="lg">
          <Typography variant="h3" align="center" color="primary.main" sx={{ mt: 5 }}>
            {_('welcome to click77')}
          </Typography>
          <Typography variant="body1" align="center" sx={{ mt: 1 }}>
            {_('very soon you will be able to draw products and get followers!')}
          </Typography>
          <Card sx={{ p: 3, mt: 4 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step>
                <StepLabel>
                  <Typography variant="h5" color={activeStep === 0 ? 'primary.main' : 'text.disabled'}>
                    {_('Config brand')}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <CreateStoreForm nextStep={handleNext} isSubmitting={isSubmitting} />
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  <Typography variant="h5" color={activeStep === 3 ? 'primary.main' : 'text.disabled'}>
                    {_('Ready')}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Box
                    sx={{
                      mt: 2,
                      px: 2,
                      py: 5,
                      borderRadius: 4,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      boxShadow: 'inset 2px 2px 8px .5px rgba(0,0,0,.1)'
                    }}>
                    <Typography variant="h4" color="primary.main" sx={{ mt: 2 }} align="center">
                      {_('Ready to create draw!')}
                    </Typography>
                    <Typography variant="body1" align="center">
                      {_('Thanks for create you brand in click77')}
                    </Typography>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => apiBrandSetHasBrand(true)}>
                      {_('Start')}
                    </Button>
                  </Box>
                </StepContent>
              </Step>
            </Stepper>
          </Card>
        </Container>
      </RootStyle>
    </>
  )
}

export default RegisterStore
