import React from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { UploadIllustration } from '../../assets'
import useLocales from '../../hooks/useLocales'

export default function BlockContent() {
  const { translate: _ } = useLocales()

  return (
    <Stack spacing={2} alignItems="center" justifyContent="center" direction={{ xs: 'column', md: 'row' }} sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}>
      <UploadIllustration sx={{ width: 220 }} />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          {_('Drop or Select file')}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {_('Drop files here or click')}&nbsp;
          <Typography variant="body2" component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
            {_('browse')}
          </Typography>
          &nbsp;{_('thorough your machine')}
        </Typography>
      </Box>
    </Stack>
  )
}
