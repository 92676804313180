import { createAction, createSlice } from '@reduxjs/toolkit'
import { BaseApi } from '@/api'
import { apiBrandGetId } from '@/redux/brand'
import { createSelector } from 'reselect'
import { setLoading } from '@/redux/reaction'
import type { DTO_200_DashboardData, DTO_200_Settings } from '@/api/models'
import type { AxiosResponse } from 'axios'

export type IDashboardKeys = 'gamers' | 'exposureTime' | 'shares' | 'instagram' | 'ecommerce'
export type IDashboard = DTO_200_DashboardData

interface IDashboardState {
  data: DTO_200_DashboardData
  settings?: DTO_200_Settings
}
const getInitialState = (): IDashboardState => ({
  data: { anual: {}, total: {}, downloads: { android: 0, ios: 0 } },
  settings: undefined
})

export const setDashboardInitState = createAction('SET_DASHBOARD_INIT_STATE')
export const setDashboardSuccess = createAction<IDashboardState['data']>('SET_DASHBOARD_SUCCESS')
export const setDashboard = createAction<IDashboardState['data']>('SET_DASHBOARD')
export const setDashboardSettings = createAction<DTO_200_Settings | undefined>('SET_DASHBOARD_SETTINGS')

const dahsboardSlice = createSlice({
  name: 'dashboard',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setDashboardInitState, (state) => {
        Object.assign(state, getInitialState())
      })
      .addCase(setDashboardSuccess, (state, { payload }) => {
        state.data = payload
      })
      .addCase(setDashboard, (state, { payload }) => {
        state.data = payload
      })
      .addCase(setDashboardSettings, (state, { payload }) => {
        state.settings = payload
      })
  }
})

/**
 * REDUCERs
 */
export const dashboardReducer = dahsboardSlice.reducer
export const dashboardDispatch: { dispatch?: Function } = {}
export const selectDashboardSettings = createSelector([(store: IRootState) => store.dashboard.settings], (settings) => settings)
export const selectDashboardData = createSelector([(store: IRootState) => store.dashboard.data], (data) => data)

/**
 * APIs
 */

// Actions
export async function apiDashboardGet(userBrandId?: string) {
  const { dispatch } = dashboardDispatch
  setLoading(true)
  try {
    const brand = apiBrandGetId()
    let response: AxiosResponse<DTO_200_DashboardData>
    if (userBrandId) response = await BaseApi.get(`/api/v1/dashboard/{id}`, { 'x-business': brand }, { id: userBrandId })
    else response = await BaseApi.get(`/api/v1/dashboard`, { 'x-business': brand }, undefined)
    setLoading(false)
    dispatch?.(setDashboardSuccess(response.data))
  } catch (error) {
    setLoading(false)
    console.error(error)
  }
}
export const apiDashboardGetSettings = async () => {
  const { dispatch } = dashboardDispatch
  setLoading(true)
  try {
    const response = await BaseApi.get(`/api/v1/admin/settings`, undefined, undefined)
    setLoading(false)
    dispatch?.(setDashboardSettings(response.data))
  } catch (error) {
    setLoading(false)
    console.error(error)
  }
}

export const apiDashboardPostSettings = async (settings: DTO_200_Settings) => {
  const { dispatch } = dashboardDispatch
  setLoading(true)
  try {
    const response = await BaseApi.post(`/api/v1/admin/settings`, { settings }, undefined, undefined)
    setLoading(false)
    dispatch?.(setDashboardSettings(response.data))
  } catch (error) {
    setLoading(false)
    console.error(error)
  }
}
