import React from 'react'
import { PATH_DOCS } from '@/routes/paths'
import Iconify from '../../components/Iconify'

const ICON_SIZE = {
  width: 22,
  height: 22
}

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/'
  },
  {
    title: 'About us',
    path: '/about-us',
    icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />
  },
  {
    title: 'Contact',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: PATH_DOCS
  }
]

export default menuConfig
