import { downloadAsync, readAsStringAsync, deleteAsync, writeAsStringAsync, EncodingType, documentDirectory } from './expo-file-system'
import moment from 'moment/moment'
import { XLSX } from './xlsx'
import JSZip from 'jszip'
import { getDocumentAsync } from './expo-document-picker'
import { shareAsync } from './expo-sharing'
// import { getPermissionAndroid } from '@/components/permissions'
// import { _ } from '@/i18n'

const Platform = { OS: 'web' }

interface ISaveFileProps {
  mimeType: string
  encoding: 'utf8' | 'base64'
}

export abstract class FileManager {
  static async saveFile(file: string, data: any, options: ISaveFileProps): Promise<boolean> {
    if (Platform.OS === 'android') {
      // const granted = await getPermissionAndroid()
      // if (!granted) return false
    }
    if (options.encoding === 'base64' && data.includes(';base64,')) data = data.split(';base64,')[1] ?? data
    const fileUri = documentDirectory + file
    const { encoding, mimeType } = options
    await writeAsStringAsync(fileUri, data, { encoding })
    if (Platform.OS === 'web') await downloadAsync(fileUri, file)
    else await shareAsync(fileUri, { mimeType })
    await deleteAsync(fileUri)
    return true
  }
  static async shareImageWithURL(url: string, title: string = ''): Promise<boolean | string> {
    // _('Share image')
    if (Platform.OS === 'web') {
      let file = url.indexOf('?') > -1 ? url.split('?')[0] ?? url : url
      file = file.split('/').pop() ?? ''
      const ext = file.split('.').pop() ?? ''
      const type = `image/${ext === 'jpg' ? 'jpeg' : ext}`
      const res = await fetch(url)
      const blob = new Blob([await res.blob()], { type })
      try {
        navigator
          .share({
            title,
            files: [new File([blob], file, { type })]
          })
          .then((d) => console.log('share', d))
          .catch((e) => console.error('share', e))
      } catch (error: any) {
        return error.message
      }
    } else {
      /*
      try {
        const result = await Share.share({ url })
        if (result.action === Share.sharedAction) return true
        else if (result.action === Share.dismissedAction) return false
      } catch (error: any) {
        return error.message
      }
      */
    }
    return true
  }
  static async downloadCSV(file: string, data: any[][]): Promise<boolean> {
    const csv = data
      .map((r) =>
        r
          .map((c) => {
            if (c instanceof Date) return `"${moment(c).format('YYYY-MM-DD HH:mm:ss')}"`
            if (!isNaN(c)) return `"${(c + '').replace('.', ',')}"`
            return `"${c}"`
          })
          .join(',')
      )
      .join('\n')
    return await this.saveFile(file + '.csv', csv, { mimeType: 'text/csv', encoding: EncodingType.UTF8 })
  }
  static async downloadExcel(file: string, sheetTitles: string[], data: any[][]): Promise<boolean> {
    if (data == null || !Array.isArray(data) || data.length === 0) return false
    function datenum(v: any, date1904?: boolean) {
      if (date1904) v += 1462
      const epoch = Date.parse(v)
      return (epoch - new Date(Date.UTC(1899, 11, 30)).getTime()) / (24 * 60 * 60 * 1000)
    }

    function hourDate(v: string) {
      const h = (parseInt(v.split(':')[0] ?? 0, 10) - 613656) * 60 * 60 * 1000
      const m = parseInt(v.split(':')[1] ?? 0, 10) * 60 * 1000
      return (h + m - new Date(Date.UTC(1899, 11, 30)).getTime()) / (24 * 60 * 60 * 1000)
    }

    function getSheet(_data: any) {
      // opts
      const ws: any = {}
      const range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } }

      for (let R = 0; R !== _data.length; ++R) {
        for (let C = 0; C !== _data[R].length; ++C) {
          if (range.s.r > R) range.s.r = R
          if (range.s.c > C) range.s.c = C
          if (range.e.r < R) range.e.r = R
          if (range.e.c < C) range.e.c = C
          const cell: any = { v: _data[R][C] }
          if (cell.v == null) continue
          const cell_ref = XLSX.utils.encode_cell({ c: C, r: R })

          if (typeof cell.v === 'number') cell['t'] = 'n'
          else if (typeof cell.v === 'boolean') cell['t'] = 'b'
          else if (cell.v instanceof Date) {
            cell['t'] = 'n'
            cell['z'] = XLSX.SSF._table[14]
            cell.v = datenum(cell.v)
          } else if (cell.v.match(/[0-9]{2}:[0-9]{2}:[0-9]{2}/)) {
            cell['t'] = 'n'
            cell['z'] = XLSX.SSF._table[50]
            cell.v = hourDate(cell.v)
          } else {
            cell['t'] = 's'
            if (cell.v.indexOf('</b>') > -1) {
              cell['z'] = 'Bold'
              cell.v = cell.v.replace(/<b[^>]*>(.*?)<\/b>/m, '$1')
              cell['s'] = { font: { bold: true } }
            }
          }
          ws[cell_ref] = cell
        }
      }
      if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range)
      return ws
    }
    const Workbook: any = function (this: any) {
      // noinspection JSUnusedGlobalSymbols
      this.SheetNames = []
      // noinspection JSUnusedGlobalSymbols
      this.Sheets = {}
    }
    const wb = new Workbook()
    data.forEach((wbdata, index) => {
      const ws = getSheet(wbdata)
      const cFile = sheetTitles[index]
      /* add worksheet to workbook */
      wb.SheetNames.push(cFile)
      wb.Sheets[cFile] = ws
    })
    const opts = { bookType: 'xlsx', bookSST: true, type: 'binary' }
    const wbout = await XLSX.write(wb, opts)
    function s2ab(s: any) {
      const buf = new ArrayBuffer(s.length)
      const view = new Uint8Array(buf)
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
      return buf
    }
    return await this.saveFile(file + '.xlsx', s2ab(wbout), { mimeType: 'application/octet-stream', encoding: 'utf8' })
  }
  static async processXLSX(file: IFile, noJSON?: boolean): Promise<[any, any, string, any]> {
    return await new Promise(async (res) => {
      function excelAtoN(val: any) {
        const base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        let i,
          j,
          result = 0
        for (i = 0, j = val.length - 1; i < val.length; i += 1, j -= 1) {
          result += Math.pow(base.length, j) * (base.indexOf(val[i]) + 1)
        }
        return result - 1
      }
      const name = file.name
      const workbook = await XLSX.read(file.data, { type: 'base64', cellDates: true, cellStyles: true })
      const sheet_name_list = workbook.SheetNames
      const json: any = {}
      const $json: any = {}

      sheet_name_list.forEach((y: any) => {
        const worksheet = workbook.Sheets[y]
        const headers: any = {}
        const data: any[] = []
        const $data: any[] = []
        Object.keys(worksheet).forEach((z) => {
          if (z[0] === '!') return
          //parse out the column, row, and value
          let tt = 0
          for (let i = 0; i < z.length; i++) {
            if (!isNaN(parseFloat(z[i]))) {
              tt = i
              break
            }
          }
          const col = z.substring(0, tt)
          const row = parseFloat(z.substring(tt))
          const $row = worksheet[z]
          const value = $row.v
          if (!noJSON) {
            //store header names
            if (row === 1 && value) {
              headers[col] = value
              return
            }
            if (!data[row]) data[row] = {}
            if (!$data[row]) $data[row] = {}
            data[row][headers[col]] = value
            $data[row][headers[col]] = $row
          } else {
            if (!data[row]) data[row] = []
            if (!$data[row]) $data[row] = []
            data[row][excelAtoN(col)] = value
            $data[row][excelAtoN(col)] = $row
          }
        })
        data.shift()
        $data.shift()
        //drop those first two rows which are empty
        if (!noJSON) {
          data.shift()
          $data.shift()
        }

        json[y] = data
        $json[y] = $data
      })

      res([json, workbook, name, $json])
    })
  }
  static async getXLSX(raw?: boolean): Promise<[IXLSX, any, string, IXSLXRaw] | undefined> {
    const result = await this.loadFile('vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'base64')
    // type: "*/*" // all files
    // type: "image/*" // all images files
    // type: "audio/*" // all audio files
    // type: "application/*" // for pdf, doc and docx
    // type: "application/pdf" // .pdf
    // type: "application/msword" // .doc
    // type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" // .docx
    // type: "vnd.ms-excel" // .xls
    // type: "vnd.openxmlformats-officedocument.spreadsheetml.sheet" // .xlsx
    // type: "text/csv" // .csv
    if (!Array.isArray(result) || result[0].type !== 'success') return
    const [$file, data] = result
    const file = $file as any
    file.data = data.includes(';base64,') ? data.split(';base64,')[1] ?? data : data
    // _stringToArrayBuffer(data)
    return await this.processXLSX(file, raw)
  }
  static async getRawXLSX(): Promise<[Record<string, any[][]>, any, string, Record<string, IXSLXRow[][]>] | undefined> {
    return (await this.getXLSX(true)) as any
  }
  static async loadFile(type: string, encoding: 'utf8' | 'base64'): Promise<[any, string] | undefined> {
    const doc: any = await getDocumentAsync({ type, copyToCacheDirectory: true })
    if (doc.type !== 'success') return
    // let { name, size, uri } = response
    if (Platform.OS === 'android' && doc.uri.startsWith('/')) {
      doc.uri = `file://${doc.uri}`
      doc.uri = doc.uri.replace(/%/g, '%25')
    }
    const data = await readAsStringAsync(doc.uri, { encoding })
    return [doc, data]
  }
  // noinspection JSUnusedGlobalSymbols
  static stringToArrayBuffer(binary_string: string) {
    // const binary_string = window.atob(base64)
    const len = binary_string.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) bytes[i] = binary_string.charCodeAt(i)
    return bytes.buffer
  }
  static async genZipFromURLs(file: string, urls: string[]) {
    const zip = new JSZip()
    await Promise.all(
      urls.map(async (url) => {
        const res = await fetch(url)
        const blob = await res.blob()
        let f = url.indexOf('?') > -1 ? url.split('?')[0] : url
        f = f.split('/').pop() ?? ''
        zip.file(f, blob)
      })
    )
    const zipFile = await zip.generateAsync({ type: 'base64' })
    await this.saveFile(file, zipFile, { mimeType: 'application/zip', encoding: 'base64' })
  }
}

export interface IXLSXProp {
  id: string
  name: string
  miniView: boolean
  metricSystem: string
  type: 'number' | 'options' | 'select' | 'boolean'
  placeholder?: string
  options?: string[]
}

export interface IFile {
  type: 'success'
  /**
   * Document original name.
   */
  name: string
  /**
   * Document size in bytes.
   */
  size?: number
  /**
   * An URI to the local document file.
   */
  uri: string
  /**
   * Document MIME type.
   */
  mimeType?: string
  /**
   * Timestamp of last document modification.
   */
  lastModified?: number
  /**
   * `File` object for the parity with web File API.
   * @platform web
   */
  file?: File
  /**
   * `FileList` object for the parity with web File API.
   * @platform web
   */
  output?: FileList | null
  /**
   * data
   */
  data: string
}

export type IXLSX = Record<string, Record<string, any>[]>
export interface IXSLXRow {
  h: string
  r: string
  s: Record<string, string>
  t: string
  v: string // valor
  w: string
  z: string // kind
}
export type IXSLXRaw = Record<string, Record<string, IXSLXRow>[]>
