import React, { useEffect, useRef, useState } from 'react'
import LoadingScreen from '@/components/LoadingScreen'
import { useIsLoading } from "@/redux/reaction";

export default function LoadingMain() {
  const loading = useIsLoading()
  const self = useRef<{ t?: NodeJS.Timeout; loader: boolean }>({ loader: false })
  const [loader, setLoader] = useState(false)
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    if (loading === self.current.loader) return
    self.current.loader = loading
    clearTimeout(self.current.t!)
    if (loading) {
      setOpacity(1)
      setLoader(true)
    } else {
      setOpacity(0)
      self.current.t = setTimeout(() => {
        setLoader(false)
      }, 500)
    }
  }, [loading])

  if (!loader) return null
  return <LoadingScreen bgOpacity={0.8} opacity={opacity} />
}
