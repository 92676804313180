import React from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import RadioGroup from '@mui/material/RadioGroup'
import Stack from '@mui/material/Stack'
import { Typography, Container, AppBar, Toolbar, Card, Button } from '@mui/material'
import { PATH_APP } from '@/routes/paths'
import useLocales from '../../hooks/useLocales'
import useOffSetTop from '../../hooks/useOffSetTop'
import Logo from '../../components/Logo'
import Iconify from '../../components/Iconify'
import { useAuthUser } from '@/redux/auth'
import { apiBrandSet } from '@/redux/brand'
import useResponsive from '@/hooks/useResponsive'
import type { SxProps } from '@mui/system'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Box from '@mui/material/Box'
import Image from '@/components/Image'
import type { DTO_200_Brand } from '@/api/models'
const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 88

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}))

const ToolbarShadowStyle = styled('div')(({ theme: $theme }) => {
  const theme = $theme as unknown as ITheme
  return {
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    margin: 'auto',
    borderRadius: '50%',
    position: 'absolute',
    width: 'calc(100% - 48px)',
    boxShadow: theme.customShadows.z8
  }
})

const ToolbarSpacer = styled('div')(({ theme }) => ({
  width: '100%',
  height: APP_BAR_MOBILE,
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}))

const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '100%',
  paddingBottom: theme.spacing(6)
}))

export function SelectionBrand() {
  const navigate = useNavigate()
  const isOffset = useOffSetTop(100)
  const user = useAuthUser()
  const { brands = [] } = user || {}
  const { translate: _ } = useLocales()

  const handleSelected = async (id: string) => {
    try {
      await apiBrandSet(id, user)
      navigate('/app')
    } catch (error) {
      console.error(error)
    }
  }

  const handleAddBrand = () => {
    navigate(PATH_APP.brand.register)
  }

  return (
    <>
      <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
        <ToolbarStyle
          disableGutters
          sx={{
            bgcolor: 'background.default',
            ...(isOffset && {
              height: { md: APP_BAR_DESKTOP - 16 }
            })
          }}>
          <Container
            maxWidth="lg"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <Logo />
          </Container>
        </ToolbarStyle>
        <ToolbarShadowStyle />
      </AppBar>
      <RootStyle>
        <ToolbarSpacer />
        <Container maxWidth="sm">
          <Typography variant="h3" color="primary.main" sx={{ mt: 5 }}>
            {_('Select brand')}
          </Typography>
          <Card sx={{ p: 3, mt: 4 }}>
            <>
              <RadioGroup
                onChange={(event) => {
                  handleSelected(event.target.value).then()
                }}>
                <Stack spacing={2}>
                  {brands.map((item) => {
                    return <SelectBrandItem key={item._id} item={item} selected={false} />
                  })}
                </Stack>
              </RadioGroup>

              <Button size="small" startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />} onClick={handleAddBrand} sx={{ my: 3 }}>
                {_('Add Brand')}
              </Button>
            </>
          </Card>
        </Container>
      </RootStyle>
    </>
  )
}

const OptionStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2.5),
  justifyContent: 'space-between',
  transition: theme.transitions.create('all'),
  border: `solid 1px ${theme.palette.divider}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.5
}))

interface Props {
  selected: boolean
  facebook?: boolean
  item?: DTO_200_Brand
}

export function SelectBrandItem({ item, facebook = false, selected }: Props) {
  const isDesktop = useResponsive('up', 'sm')
  const { translate: _ } = useLocales()
  // ---------------------------------------------
  const { name, photo, _id } = item || {}
  return (
    <OptionStyle
      sx={
        {
          ...(selected && {
            boxShadow: (theme: ITheme) => theme.customShadows.z20
          })
        } as SxProps
      }>
      <FormControlLabel
        value={_id}
        control={<Radio checkedIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />} />}
        label={
          <Box sx={{ ml: 1 }}>
            <Typography variant="subtitle2">{name}</Typography>
            {facebook && (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {`${_('Facebook page id')}: ${_id}`}
              </Typography>
            )}
          </Box>
        }
        sx={{ flexGrow: 1, py: 3 }}
      />

      {isDesktop && (
        <Stack direction="row" spacing={1} flexShrink={0}>
          <Image alt="photo" src={photo} sx={{ width: 65, heigth: 65 }} />
        </Stack>
      )}
    </OptionStyle>
  )
}
