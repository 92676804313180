// noinspection JSUnusedGlobalSymbols

export type ProductsControllerList_GET_200 = DTO_200_Product[]

export type PhotoControllerCreate_POST_200 = string[]

export type SettingsControllerCountry_PUT_200 = boolean

export type AdminControllerGetBrands_GET_200 = DTO_200_Brand[]

export type AdminControllerGetDrawListOrder_POST_200 = DTO_200_Brand[]

export type AdminControllerList_GET_200 = DTO_200_User[]

export type BotControllerCheck_GET_200 = string

export type DTO_200_EmptyResponse = any

export type IBoxTypes = '' | 'boaz' | 'casaideas' | 'cinemark' | 'creamfields' | 'dgmedios' | 'everlast' | 'falabella' | 'fullbike' | 'infanti' | 'kfc' | 'lenovo' | 'masterg' | 'melt' | 'passline' | 'procircuit' | 'rebel' | 'reebok' | 'ripley' | 'rolos' | 'rotterkraus' | 'spy' | 'superzoo' | 'teleton' | 'ultra'

export interface DTO_200_Coupon {
  _id?: string
  code: string
  status: 'PENDING' | 'APPROVED' | 'REJECT' | 'USED' | 'DELIVERY' | 'DELETE'
  observation?: string
  createdAt?: string
  updatedAt?: string
}

export interface DTO_200_Product {
  _id?: string
  name?: string
  brand?: string
  description?: string
  color?: string
  sku?: string
  photos?: string[]
  url?: string
  cost?: number
  coupons?: DTO_200_Coupon[]
  available?: number
  pending?: number
  reject?: number
  status: 'PENDING' | 'APPROVED' | 'REJECTED'
  observation?: string
  createdAt?: string
  updatedAt?: string
}

export interface DTO_200_ProductGetList {
  products: DTO_200_Product[]
}

export interface DTO_ProductCreate {
  brand: string
  name: string
  description?: string
  sku?: string
  url: string
  cost: number
  photos?: string[]
  coupons?: string[]
}

export interface DTO_ProductUpdate {
  brand?: string
  name?: string
  description?: string
  sku?: string
  url?: string
  cost?: number
  photos?: string[]
  coupons?: string[]
}

export interface DTO_200_DeleteResponse {
  acknowledged: boolean
  deletedCount: number
}

export interface DTO_CouponsAdd {
  brand?: string
  name?: string
  description?: string
  sku?: string
  url?: string
  cost?: number
  photos?: string[]
  coupons: string[]
}

export interface DTO_200_ProductsUpload {
  urls: string[]
}

export interface DTO_Photo {
  folders?: string[]
  types?: string[]
}

export interface DTO_UserSignUp {
  /**
   * The email of the user to register
   */
  email: string
  /**
   * The unencrypted password of the user to register
   */
  password: string
  name?: string
  lastName?: string
}

export interface DTO_201_OK {
  /**
   * If the request was successful or not
   */
  ok: boolean
}

export interface DTO_400_BadRequest {
  status: number
  error: string
  message: string
}

export interface DTO_409_EmailAlreadyInUse {
  status: number
  error: string
  message: string
}

export interface DTO_ConfirmEmail {
  /**
   * The e-mail of the user to register
   */
  email: string
  /**
   * The confirmation code from the email
   */
  code: string
}

export interface DTO_200_AwardPopulated {
  click?: number
  chest?: number
  address?: string
  quantity?: number
  coupon?: string
  cost?: number
  order?: number
  status?: boolean
  user?: string
  prize?: boolean
  draw?: string
  unread?: boolean
  byClain?: boolean
  winTime?: string
  award?: boolean
  product?: DTO_200_Product
}

export interface DTO_200_Award {
  click?: number
  chest?: number
  product?: string
  address?: string
  quantity?: number
  coupon?: string
  cost?: number
  order?: number
  status?: boolean
  user?: string
  prize?: boolean
  draw?: string
  unread?: boolean
  byClain?: boolean
  winTime?: string
  award?: boolean
}

export interface DTO_DrawGamer {
  _id: string
  name: string
  email: string
  photo: string
  pin: string
  color: string
  position: number
  phantom: boolean
  bot: boolean
  date: string
}

export interface DTO_200_Draw {
  _id?: string
  brand?: string
  user?: string
  start: string
  end?: string
  lastAward?: string
  color?: number
  isFB?: boolean
  lastChestAward?: number
  amountPrizes?: number
  amountChests?: number
  amountTotal?: number
  isGlobal?: boolean
  country?: string
  exposure?: number
  chestsTime?: number[]
  prizes: DTO_200_Award[]
  chests: DTO_200_Award[]
  awards: DTO_200_Award[]
  chestsAwards?: DTO_200_Award[]
  prizesAwards?: DTO_200_Award[]
  click?: number
  status?: boolean
  isFinished?: boolean
  progress?: number
  canShare?: boolean
  asleep?: boolean
  box?: IBoxTypes
  isDemo?: boolean
  order?: number
  progressStatus?: number
  gamers?: DTO_DrawGamer[]
  createdAt?: string
  updatedAt?: string
}

export interface DTO_200_Brand {
  /**
   * The user's ID
   */
  _id?: string
  name: string
  identity?: string
  identityCode?: string
  legalName?: string
  ecommerce?: string
  fanPageFacebook?: string
  idPageFacebook?: string
  instagramNickname: string
  website?: string
  photo?: string
  banner?: string
  status?: string
  order?: number
  box?: IBoxTypes
  isDemo?: boolean
  isClickSevenSeven?: boolean
  isHidden?: boolean
  isSample?: boolean
  draw?: DTO_200_Draw
  drawsCount?: number
  productsCount?: number
  createdAt?: string
  updatedAt?: string
}

export interface DTO_200_Store {
  productID: string
  name?: string
  price: number
  coin: 'clp' | 'usd' | 'gc'
  amount: number
  type: 'gc' | 'hat'
  subtype?: string
}

export interface DTO_200_PurchasePopulated {
  store?: DTO_200_Store
  user: string
  productID: string
  receipt?: any
}

export interface DTO_200_UserPopulated {
  /**
   * The user's ID
   */
  _id?: string
  /**
   * The firstname of the user
   */
  name?: string
  /**
   * The lastname of the user
   */
  lastName?: string
  /**
   * The email of the user
   */
  email?: string
  password?: string
  /**
   * A pseudorandom, probabilistically-unique token for use in our account verification emails
   */
  emailProofToken?: string
  /**
   * The user's avatar picture
   */
  photo?: string
  /**
   * The user's Unique ID
   */
  uid?: string
  level?: number
  prestige?: number
  address?: string
  balance?: number
  credit?: number
  clicks?: number
  role?: 'user' | 'admin' | 'banned'
  device?: string[]
  ips?: string[]
  apple: {
    id?: string
    token?: string
    device?: string
  }[]
  usercheckmarks?: boolean[]
  onboarding?: number[]
  phantom?: boolean
  bot?: boolean
  ignoreBalance?: boolean
  userData?: any
  createdAt?: string
  updatedAt?: string
  extra?: any
  brandCount?: number
  awards?: DTO_200_AwardPopulated[]
  draws?: DTO_200_Draw[]
  brands?: DTO_200_Brand[]
  purchases?: DTO_200_PurchasePopulated[]
}

export interface DTO_200_UserData {
  /**
   * The user's token code
   */
  token?: string
  /**
   * The user's Unique ID
   */
  uid?: string
  /**
   * The user data
   */
  user?: DTO_200_UserPopulated
  /**
   * The google login status
   */
  state?: string
  /**
   * The error message
   */
  error?: string
  /**
   * The unique identifier of the device
   */
  deviceid?: 'E8CE8397-1BD3-55D0-B68F-DA15B543A36F'
}

export interface DTO_404_NotFound {
  status: number
  error: string
  message: string
}

export interface DTO_498_Expired {
  status: number
  error: string
  message: string
}

export interface DTO_UserLogin {
  /**
   * The email to try in this attempt
   */
  email: string
  /**
   * The unencrypted password to try in this attempt
   */
  password: string
}

export interface DTO_SendOnlyEmail {
  /**
   * The email address of the alleged user who wants to recover their password
   */
  email: string
}

export interface DTO_UpdatePasswordAndLogin {
  /**
   * The confirmation code from the email
   */
  code: string
  /**
   * The email address of the alleged user who wants to recover their password
   */
  email: string
  /**
   * The new, unencrypted password
   */
  password: string
}

export interface DTO_Google {
  /**
   * The Google JWT token
   */
  jwt: string
  /**
   * The Unity GUID for the transaction of the Google login
   */
  state?: string
  /**
   * To start waiting for Google token, you need to add this attribute in "start"
   */
  start?: string
  /**
   * The google's access id
   */
  id?: string
  /**
   * The google's user email
   */
  email?: string
  /**
   * The google's user first name
   */
  given_name?: string
  /**
   * The google's user last name
   */
  family_name?: string
  /**
   * The google's user picture
   */
  picture?: string
}

export type DTO_302_Redirect = any

export interface DTO_FacebookLogin {
  /**
   * The facebook's access token code
   */
  token: string
}

export interface DTO_AppleLogin {
  /**
   * The apple's access id
   */
  id: string
  /**
   * The apple's access token code
   */
  token: string
  /**
   * The apple's access authorization code
   */
  authorization: string
  /**
   * The uuid device code
   */
  device: string
  /**
   * The apple's user's data
   */
  data: string
}

export interface DTO_BrandCreate {
  name: string
  instagramNickname: string
  ecommerce?: string
  photo?: string
  banner?: string
}

export interface DTO_200_DashboardData {
  anual: {
    gamers?: number[]
    exposureTime?: number[]
    shares?: number[]
    instagram?: number[]
    ecommerce?: number[]
  }
  total: {
    gamers?: number
    exposureTime?: number
    shares?: number
    instagram?: number
    ecommerce?: number
  }
  downloads: {
    ios?: number
    android?: number
  }
}

export interface DTO_200_Challenge {
  _id?: string
  brand: string
  name: string
  description: string
  goal: number
  current?: number
  case: string
  status?: boolean
  isFinish?: boolean
  createdAt?: string
  updatedAt?: string
}

export interface DTO_ClaimReward {
  /**
   * How many golden clicks do you win
   */
  balance: number
  /**
   * It's locked
   */
  locked: boolean
  /**
   * It's claimed
   */
  claimed: boolean
}

export interface DTO_200_Challenges {
  /**
   * The challenges
   */
  challenges: DTO_200_Challenge[]
  /**
   * The user data
   */
  user?: DTO_200_UserPopulated
  /**
   * The claim matrix
   */
  claims?: DTO_ClaimReward[]
}

export interface DTO_200_ChallengePopulated {
  _id?: string
  name: string
  description: string
  goal: number
  current?: number
  case: string
  status?: boolean
  isFinish?: boolean
  createdAt?: string
  updatedAt?: string
  brand: DTO_200_Brand
}

export interface DTO_200_ChallengeGet {
  challenge?: DTO_200_ChallengePopulated
}

export interface DTO_ChallengeCreate {
  name: string
  description?: string
  goal: string
  case: string
}

export interface DTO_200_ChallengeCreate {
  challenge?: DTO_200_Challenge
}

export interface DTO_200_ClaimReward {
  /**
   * If the request was successful or not
   */
  ok: boolean
  /**
   * The total number of golden clicks the user now has
   */
  balance: number
}

export interface DTO_DrawPrizes {
  productId: string
  quantity: number
}

export interface DTO_DrawCreate {
  brand: string
  start: string
  country: string
  isGlobal?: boolean
  states?: string[]
  prizes?: DTO_DrawPrizes[]
  chests?: DTO_DrawPrizes[]
  color?: number
  isFinished?: boolean
  end?: string
}

export interface DTO_200_DrawPopulated {
  _id?: string
  user?: string
  start: string
  end?: string
  lastAward?: string
  color?: number
  isFB?: boolean
  lastChestAward?: number
  amountPrizes?: number
  amountChests?: number
  amountTotal?: number
  isGlobal?: boolean
  country?: string
  exposure?: number
  chestsTime?: number[]
  click?: number
  status?: boolean
  isFinished?: boolean
  progress?: number
  canShare?: boolean
  asleep?: boolean
  box?: IBoxTypes
  isDemo?: boolean
  order?: number
  progressStatus?: number
  gamers?: DTO_DrawGamer[]
  createdAt?: string
  updatedAt?: string
  brand?: DTO_200_Brand
  prizes: DTO_200_AwardPopulated[]
  chests: DTO_200_AwardPopulated[]
  awards?: DTO_200_AwardPopulated[]
  prizesAwards?: DTO_200_AwardPopulated[]
  chestsAwards?: DTO_200_AwardPopulated[]
}

export interface DTO_DrawUpdate {
  prizes?: DTO_DrawPrizes[]
  chests?: DTO_DrawPrizes[]
  color?: number
  isFinished?: boolean
  end?: string
}

export interface DTO_200_DrawsExposure {
  exposure: number
}

export interface DTO_200_DrawsListDraws {
  draws: DTO_200_DrawPopulated[]
}

export interface DTO_200_DrawsListGeolocation {
  data: DTO_200_DrawPopulated[]
}

export interface DTO_200_DrawsGetGamers {
  gamers: DTO_DrawGamer[]
}

export interface DTO_200_DrawGetAllGamers {
  gamers: DTO_DrawGamer[]
}

export interface DTO_DrawPlayV2 {
  drawId: string
  bonuses?: number
}

export interface DTO_200_DrawPlay {
  token: string
  clickCountDraw: number
  like: boolean
}

export interface DTO_200_DrawUserStatus {
  click_count: number
}

export interface DTO_ApiClick {
  address: string
  becomeFacebook: boolean
  bonuses: number
  captcha: boolean
  chest: {
    chest?: number
    award?: boolean
    click?: number
    createdAt?: string
    status?: boolean
    updatedAt?: string
    _id?: string
  }
  click: number
  clickCount: number
  clickCountDraw: number
  clickCountUser: number
  clickDraw: {
    clickCount?: number
    clickCountDraw?: string
    draw?: string
    _id?: string
  }
  createdAt: string
  draw: string
  golden_clicks: number
  isAnonymous: boolean
  isFinished: boolean
  isLikePage: boolean
  progress?: number
  status: boolean
  strike: number
  isStrike: boolean
  updatedAt: string
  gamers: DTO_DrawGamer[]
}

export interface DTO_PurchaseItem {
  id: string
  receipt?: string
}

export interface DTO_200_PurchaseItem {
  ok: boolean
  wins: number
  type: string
  balance: number
  message: string
}

export interface DTO_200_StoreItems {
  items?: DTO_200_Store[]
}

export interface DTO_200_InteractionShare {
  brand: {
    _id?: string
    name?: string
    banner?: string
    photo?: string
  }
  time: string
  wins: number
  balance: number
  game_clicks: number
  total_clicks: number
}

export interface DTO_200_Version {
  /**
   * The seed
   */
  set: string
  /**
   * The version of the API
   */
  version: number
  /**
   * The user data
   */
  user?: DTO_200_UserPopulated
  /**
   * The user token
   */
  token?: string
  welcome?: string
  applink: string
}

export interface DTO_200_Onboarding {
  tutorial: number
  golden_clicks: number
  balance: number
  done: boolean
}

export interface DTO_200_SettingsValidUrl {
  ok: boolean
  error: string
  result?: any
}

export interface DTO_200_Country {
  id?: string
  name?: string
  minimumSalaryUSD?: number
  minutePerSalary?: number
  factor?: number
  currency?: string
  rate?: number
}

export interface DTO_SettingsCountry {
  minimumSalaryUSD: number
  minutePerSalary: number
  name?: number
  currency: string
}

export interface DTO_Payment {
  planId: string
}

export interface DTO_200_Price {
  credit?: number
  name?: string
  amount?: number
  amount_unit?: number
  icon?: string
  discount?: number
  status?: boolean
}

export interface DTO_200_PaymentPrices {
  plans: DTO_200_Price[]
}

export type Function = any

export interface DTO_200_Transaction {
  user?: string
  code?: string
  provider?: string
  planId?: string
  credit?: string
  amount?: string
  status: 'INIT' | 'PENDING' | 'SUCCESS' | 'FAILURE' | 'CANCEL'
}

export interface DTO_200_PaymentTransactions {
  transactions: DTO_200_Transaction[]
}

export interface DTO_200_ProductPopulated {
  _id?: string
  name?: string
  description?: string
  color?: string
  sku?: string
  photos?: string[]
  url?: string
  cost?: number
  coupons?: DTO_200_Coupon[]
  available?: number
  pending?: number
  reject?: number
  status: 'PENDING' | 'APPROVED' | 'REJECTED'
  observation?: string
  createdAt?: string
  updatedAt?: string
  brand?: DTO_200_Brand
}

export interface DTO_200_AdminCoupons {
  products: DTO_200_ProductPopulated[]
}

export interface DTO_200_BrandPartial {
  /**
   * The user's ID
   */
  _id?: string
  name?: string
  identity?: string
  identityCode?: string
  legalName?: string
  ecommerce?: string
  fanPageFacebook?: string
  idPageFacebook?: string
  instagramNickname?: string
  website?: string
  photo?: string
  banner?: string
  status?: string
  order?: number
  box?: IBoxTypes
  isDemo?: boolean
  isClickSevenSeven?: boolean
  isHidden?: boolean
  isSample?: boolean
  draw?: DTO_200_Draw
  drawsCount?: number
  productsCount?: number
  createdAt?: string
  updatedAt?: string
}

export interface DTO_BrandSet {
  brand: DTO_200_BrandPartial
}

export interface DTO_200_AdminSetDraw {
  brand: DTO_200_Brand
}

export interface DTO_BrandsOrder {
  order: string[]
}

export interface DTO_BrandOrder {
  order: number
}

export interface DTO_200_Settings {
  /**
   * The user's ID
   */
  _id?: string
  minutePerSalary?: number
  isActive?: boolean
  countries?: DTO_200_Country[]
  hideStores: {
    ios?: boolean
    android?: boolean
    bear?: boolean
  }
  askShare: {
    ios?: boolean
    android?: boolean
    bear?: boolean
  }
  hideStoreLink: {
    ios?: boolean
    android?: boolean
    bear?: boolean
  }
  click77product?: DTO_200_Product
  ignoreBalance?: boolean
  createdAt?: string
  updatedAt?: string
}

export interface DTO_SettingsSet {
  settings: DTO_200_Settings
}

export interface DTO_200_Purchase {
  store?: string
  user: string
  productID: string
  receipt?: any
}

export interface DTO_200_User {
  /**
   * The user's ID
   */
  _id?: string
  /**
   * The firstname of the user
   */
  name?: string
  /**
   * The lastname of the user
   */
  lastName?: string
  /**
   * The email of the user
   */
  email?: string
  password?: string
  /**
   * A pseudorandom, probabilistically-unique token for use in our account verification emails
   */
  emailProofToken?: string
  /**
   * The user's avatar picture
   */
  photo?: string
  /**
   * The user's Unique ID
   */
  uid?: string
  level?: number
  prestige?: number
  address?: string
  balance?: number
  credit?: number
  clicks?: number
  awards?: DTO_200_Award[]
  draws?: string[]
  brands?: string[]
  role?: 'user' | 'admin' | 'banned'
  device?: string[]
  ips?: string[]
  apple: {
    id?: string
    token?: string
    device?: string
  }[]
  usercheckmarks?: boolean[]
  onboarding?: number[]
  phantom?: boolean
  bot?: boolean
  ignoreBalance?: boolean
  purchases?: DTO_200_Purchase[]
  userData?: any
  createdAt?: string
  updatedAt?: string
  extra?: any
  brandCount?: number
}

export interface DTO_UserSet {
  user: DTO_200_User
}

export interface DTO_200_Winners {
  winners: DTO_200_User[]
  products: DTO_200_Product[]
  brands: DTO_200_Brand[]
}

export interface DTO_200_AES {
  iv: string
  message: string
}

export type DTO_200_OK_RESULT = any

export interface DTO_200_FixPrizes {
  errors: string[]
  users: DTO_200_User[]
}

export interface DTO_200_FixAwards {
  ok: boolean
  found: number
  notFound: number
  total: number
}

export const DTO_200_CouponStatusEnum: DTO_200_Coupon['status'][] = ['PENDING', 'APPROVED', 'REJECT', 'USED', 'DELIVERY', 'DELETE']

export const DTO_200_ProductStatusEnum: DTO_200_Product['status'][] = ['PENDING', 'APPROVED', 'REJECTED']

export const DTO_200_BrandBoxEnum: IBoxTypes[] = ['', 'boaz', 'casaideas', 'cinemark', 'creamfields', 'dgmedios', 'everlast', 'falabella', 'fullbike', 'infanti', 'kfc', 'lenovo', 'masterg', 'melt', 'passline', 'procircuit', 'rebel', 'reebok', 'ripley', 'rolos', 'rotterkraus', 'spy', 'superzoo', 'teleton', 'ultra']

export const DTO_200_StoreCoinEnum: DTO_200_Store['coin'][] = ['clp', 'usd', 'gc']

export const DTO_200_StoreTypeEnum: DTO_200_Store['type'][] = ['gc', 'hat']

export const DTO_200_UserPopulatedRoleEnum: DTO_200_UserPopulated['role'][] = ['user', 'admin', 'banned']

export const DTO_200_UserDataDeviceidEnum: DTO_200_UserData['deviceid'][] = ['E8CE8397-1BD3-55D0-B68F-DA15B543A36F']

export const DTO_200_TransactionStatusEnum: DTO_200_Transaction['status'][] = ['INIT', 'PENDING', 'SUCCESS', 'FAILURE', 'CANCEL']

export const DTO_200_ProductPopulatedStatusEnum: DTO_200_ProductPopulated['status'][] = ['PENDING', 'APPROVED', 'REJECTED']

export const DTO_200_UserRoleEnum: DTO_200_User['role'][] = ['user', 'admin', 'banned']

export interface IApi {
  '/api/v1/products': {
    get: {
      header: { 'x-business': any }
      path: undefined
      response: DTO_200_ProductGetList
      body: any
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    post: {
      header: { 'x-business': any }
      path: undefined
      response: DTO_200_Product
      body: DTO_ProductCreate
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/products/brand/{brandId}': {
    get: {
      header: undefined
      path: { brandId: string }
      response: ProductsControllerList_GET_200
      body: any
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/products/{id}': {
    get: {
      header: undefined
      path: { id: string }
      response: DTO_200_Product
      body: any
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: { id: string }
      response: DTO_200_Product
      body: DTO_ProductUpdate
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    delete: {
      header: undefined
      path: { id: string }
      response: DTO_200_DeleteResponse
      body: any
      security: 'AdminUserToken'
      tags: 'Admin'
    }
  }
  '/api/v1/products/{id}/coupons': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: { id: string }
      response: DTO_200_EmptyResponse
      body: DTO_CouponsAdd
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/products/upload': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: { 'Content-Type': 'multipart/form-data' }
      path: undefined
      response: DTO_200_ProductsUpload
      body: any
      security: undefined
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/products/coupons/{id}': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: { id: string }
      response: DTO_200_Coupon
      body: any
      security: 'AdminUserToken'
      tags: 'Admin'
    }
  }
  '/api/v3/photo': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: { 'Content-Type': 'multipart/form-data' }
      path: undefined
      response: PhotoControllerCreate_POST_200
      body: DTO_Photo
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v3/user/signup': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    /**
     * Sign up for a new user account
     */
    post: {
      header: { deviceid: 'E8CE8397-1BD3-55D0-B68F-DA15B543A36F' | any }
      path: undefined
      response: DTO_201_OK
      body: DTO_UserSignUp
      security: undefined
      tags: 'Unity' | 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v3/user/confirm-email': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    /**
     * Confirm email
     */
    post: {
      header: undefined
      path: undefined
      response: DTO_200_UserData
      body: DTO_ConfirmEmail
      security: undefined
      tags: 'Unity' | 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v3/user/login': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    /**
     * Log in using the provided email and password combination
     */
    post: {
      header: undefined
      path: undefined
      response: DTO_200_UserData
      body: DTO_UserLogin
      security: undefined
      tags: 'Unity' | 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v3/user/send-password-recovery-email': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    /**
     * Send a password recovery notification to the user with the specified email address
     */
    post: {
      header: undefined
      path: undefined
      response: DTO_201_OK
      body: DTO_SendOnlyEmail
      security: undefined
      tags: 'Unity' | 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v3/user/update-password-and-login': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    /**
     * Finish the password recovery flow by setting the new password and logging in the requesting user, based on the authenticity of their code
     */
    post: {
      header: undefined
      path: undefined
      response: DTO_200_UserData
      body: DTO_UpdatePasswordAndLogin
      security: undefined
      tags: 'Unity' | 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v3/user/google': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    /**
     * Log in with the provided Google JWT or by setting the "GUID" state, starting with the "start" variable and then prompting every so often until you get the login details
     */
    post: {
      header: { deviceid: 'E8CE8397-1BD3-55D0-B68F-DA15B543A36F' | any }
      path: undefined
      response: DTO_200_UserData
      body: DTO_Google
      security: undefined
      tags: 'Unity' | 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v3/user/facebook': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    /**
     * Log in with the provided Facebook Token
     */
    post: {
      header: { deviceid: 'E8CE8397-1BD3-55D0-B68F-DA15B543A36F' | any }
      path: undefined
      response: DTO_200_UserData
      body: DTO_FacebookLogin
      security: undefined
      tags: 'Unity' | 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/users/profile': {
    get: {
      header: undefined
      path: undefined
      response: DTO_200_UserPopulated
      body: any
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/brands': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: { 'Content-Type': 'multipart/form-data' }
      path: undefined
      response: DTO_200_Brand
      body: DTO_BrandCreate
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/dashboard': {
    get: {
      header: { 'x-business': any }
      path: undefined
      response: DTO_200_DashboardData
      body: any
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/dashboard/{id}': {
    get: {
      header: { 'x-business': any }
      path: { id: string }
      response: DTO_200_DashboardData
      body: any
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/challenge': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: { 'x-business': string }
      path: undefined
      response: DTO_200_ChallengeCreate
      body: DTO_ChallengeCreate
      security: 'AdminToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/draws': {
    get: {
      header: { 'x-business': any }
      path: undefined
      response: DTO_200_DrawsListDraws
      body: any
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    post: {
      header: { 'x-business': any }
      path: undefined
      response: DTO_200_DrawPopulated
      body: DTO_DrawCreate
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/draws/{id}': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: { id: string }
      response: DTO_200_DrawPopulated
      body: DTO_DrawUpdate
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    delete: {
      header: undefined
      path: { id: string }
      response: DTO_200_DeleteResponse
      body: any
      security: 'AdminUserToken'
      tags: 'Admin'
    }
  }
  '/api/v1/draws/exposure': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: { 'x-business': any }
      path: undefined
      response: DTO_200_DrawsExposure
      body: DTO_DrawCreate
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/draws/all-gamers/{id}': {
    get: {
      header: undefined
      path: { id: string }
      response: DTO_200_DrawGetAllGamers
      body: any
      security: 'GameUserToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/settings/valid': {
    get: {
      header: undefined
      path: undefined
      response: DTO_200_SettingsValidUrl
      body: any
      security: undefined
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/settings/{country}': {
    get: {
      header: undefined
      path: { country: string }
      response: DTO_200_Country
      body: any
      security: undefined
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: { country: string }
      response: SettingsControllerCountry_PUT_200
      body: DTO_SettingsCountry
      security: 'AdminToken'
      tags: 'Admin'
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/payment': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: { 'x-business': any }
      path: undefined
      response: DTO_200_EmptyResponse
      body: DTO_Payment
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/payment/plan': {
    get: {
      header: undefined
      path: undefined
      response: DTO_200_PaymentPrices
      body: any
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/payment/result': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: undefined
      path: undefined
      response: DTO_200_EmptyResponse
      body: Function
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/payment/transactions': {
    get: {
      header: undefined
      path: undefined
      response: DTO_200_PaymentTransactions
      body: any
      security: 'AdminUserToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/coupons/pending': {
    get: {
      header: undefined
      path: undefined
      response: DTO_200_AdminCoupons
      body: any
      security: 'AdminToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/coupons/{id}/approve': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: undefined
      path: { id: string }
      response: DTO_200_Coupon
      body: any
      security: 'AdminToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/coupons/{id}/reject': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: undefined
      path: { id: string }
      response: DTO_200_Coupon
      body: any
      security: 'AdminToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/product/{id}/approve': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: undefined
      path: { id: string }
      response: DTO_200_Product
      body: any
      security: 'AdminToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/product/{id}/reject': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: undefined
      path: { id: string }
      response: DTO_200_Product
      body: any
      security: 'AdminToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/brands': {
    get: {
      header: undefined
      path: undefined
      response: AdminControllerGetBrands_GET_200
      body: any
      security: 'AdminToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/brand/{id}': {
    get: {
      header: undefined
      path: { id: string }
      response: DTO_200_Brand
      body: any
      security: 'AdminToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: { id: string }
      response: DTO_201_OK
      body: any
      security: 'AdminToken'
      tags: 'Admin'
    }
  }
  '/api/v1/admin/brand': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: undefined
      path: undefined
      response: DTO_200_AdminSetDraw
      body: DTO_BrandSet
      security: 'AdminToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/brands/order': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: undefined
      path: undefined
      response: AdminControllerGetDrawListOrder_POST_200
      body: DTO_BrandsOrder
      security: 'AdminToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/brand/{id}/order': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: undefined
      path: { id: string }
      response: DTO_201_OK
      body: DTO_BrandOrder
      security: 'AdminToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/settings': {
    get: {
      header: undefined
      path: undefined
      response: DTO_200_Settings
      body: any
      security: 'AdminToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: DTO_200_Settings
      body: DTO_SettingsSet
      security: 'AdminToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/users': {
    get: {
      header: undefined
      path: undefined
      response: AdminControllerList_GET_200
      body: any
      security: 'AdminToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/user': {
    get: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    post: {
      header: undefined
      path: undefined
      response: DTO_200_User
      body: DTO_UserSet
      security: 'AdminToken'
      tags: 'Admin'
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/winners': {
    get: {
      header: undefined
      path: undefined
      response: DTO_200_Winners
      body: any
      security: 'AdminToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v1/admin/winners/{id}': {
    get: {
      header: undefined
      path: { id: string }
      response: DTO_200_Winners
      body: any
      security: 'AdminToken'
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v3/bugfix/captcha/{address}': {
    get: {
      header: undefined
      path: { address: string }
      response: DTO_200_OK_RESULT
      body: any
      security: undefined
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v3/bugfix/only-captcha/{address}': {
    get: {
      header: undefined
      path: { address: string }
      response: DTO_200_OK_RESULT
      body: any
      security: undefined
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v3/bugfix/prizes': {
    get: {
      header: undefined
      path: undefined
      response: DTO_200_FixPrizes | DTO_200_OK_RESULT
      body: any
      security: undefined
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
  '/api/v3/bugfix/awards': {
    get: {
      header: undefined
      path: undefined
      response: DTO_200_FixAwards | DTO_200_OK_RESULT
      body: any
      security: undefined
      tags: 'Admin'
    }
    post: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    put: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
    delete: {
      header: undefined
      path: undefined
      response: undefined
      body: undefined
      security: undefined
      tags: undefined
    }
  }
}
