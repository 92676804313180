import React from 'react'
import { Container } from '@mui/material'
import useSettings from '@/hooks/useSettings'
import Page from '@/components/Page'
import useLocales from '@/hooks/useLocales'
import { sprintf } from '@/lib'
import { NavBreadcrumbs } from '@/components/NavBreadcrumbs'
import UserNewForm from '@/pages/admin/users/UserNewForm'

export function UserCreate() {
  const { translate: _ } = useLocales()
  const { themeStretch } = useSettings()

  return (
    <Page title={`${_('User')}: ${sprintf(_('Create %s'), _('User'))}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <NavBreadcrumbs name={undefined} />
        <UserNewForm />
      </Container>
    </Page>
  )
}
