import { createAction, createSlice } from '@reduxjs/toolkit'
import { BaseApi } from '@/api'
import { _clone, _pluck, _uniq } from '@/lib/xi'
import { setAuthBrandId, setAuthUser } from '@/redux/auth'
import { createSelector } from 'reselect'
import { arrayMove } from '@dnd-kit/sortable'
import { setLoading } from '@/redux/reaction'
import type { DTO_200_Brand, DTO_200_UserPopulated } from "@/api/models";

interface DTO_200_BrandState {
  data: DTO_200_Brand | undefined
  brands: Record<string, DTO_200_Brand>
}

const getInitialState = (): DTO_200_BrandState => ({
  data: undefined,
  brands: {}
})

export const setBrandInitState = createAction('SET_BRAND_INIT_STATE')
export const setBrandStore = createAction<DTO_200_Brand | undefined>('SET_BRAND_STORE')
export const setBrands = createAction<Record<string, DTO_200_Brand>>('SET_BRANDS')
export const setBrandOrder = createAction<string[]>('SET_BRAND_ORDER')
export const setBrandDelete = createAction<string>('SET_BRAND_DELETE')

const brandSlice = createSlice({
  name: 'brand',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setBrandInitState, (state) => {
        Object.assign(state, getInitialState())
      })
      .addCase(setBrandStore, (state, { payload }) => {
        state.data = payload
      })
      .addCase(setBrands, (state, { payload }) => {
        Object.assign(state.brands, payload)
      })
      .addCase(setBrandOrder, (state, { payload }) => {
        const brands: Record<string, DTO_200_Brand> = {}
        payload.forEach((id) => (brands[id] = state.brands[id]))
        state.brands = brands
      })
      .addCase(setBrandDelete, (state, { payload }) => {
        delete state.brands[payload]
      })
  }
})

// Reducer
export const brandReducer = brandSlice.reducer
export const brandDispatch: { dispatch?: Function } = {}

/**
 * SELECTORS
 */
export const selectBrands = createSelector([(store: IRootState) => store.brand.brands], (brands) => brands)
export const selectBrand = (_id?: string) => createSelector([(store: IRootState) => (!_id ? undefined : (store.brand.brands[_id] as DTO_200_Brand | undefined))], (brand) => brand)

export interface DTO_200_BrandCreate {
  photo: string
  photoFlag: string
  idPageFacebook: string
  name: string
  instagramNickname: string
  ecommerce: string
}

/**
 * APIs
 */
export async function apiBrandCreate(body: DTO_200_BrandCreate, photo: string | null = null, photoFlag: string | null = null) {
  const { dispatch } = brandDispatch
  setLoading(true)
  try {
    // delete body.photo;
    const data = new FormData()
    if (photo) {
      data.append('photo', photo)
    }
    if (photoFlag) {
      data.append('photoFlag', photoFlag)
    }
    Object.keys(body).forEach((item) => {
      const key = item as keyof DTO_200_BrandCreate
      if (photo && item === 'photo') {
        return
      }
      if (typeof body[key] === 'object') {
        const file = body[key]
        data.append(item, file)
      } else {
        const value = body[key] || ''
        if (value) {
          data.append(item, value)
        }
      }
    })
    const response = await BaseApi.post('/api/v1/brands', data as unknown as DTO_200_BrandCreate, { 'Content-Type': 'multipart/form-data' }, undefined)
    setLoading(false)
    const brand = response.data as DTO_200_Brand
    dispatch?.(setBrandStore(brand))
    return brand
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function apiBrandUpdate(brand: DTO_200_Brand) {
  const { dispatch } = brandDispatch
  setLoading(true)
  try {
    const response = await BaseApi.post('/api/v1/admin/brand', { brand }, undefined, undefined)
    setLoading(false)
    const $brand = response.data.brand as DTO_200_Brand
    if ($brand._id && $brand._id !== 'new') dispatch?.(setBrands({ [$brand._id]: $brand }))
    return $brand
  } catch (error) {
    setLoading(false)
    console.error(error)
    throw error
  }
}

export async function apiBrandDelete(id: string) {
  const { dispatch } = brandDispatch
  setLoading(true)
  try {
    await BaseApi.delete(`/api/v1/admin/brand/{id}`, undefined, { id })
    dispatch?.(setBrandDelete(id))
    setLoading(false)
    return true
  } catch (error) {
    setLoading(false)
    console.error(error)
    throw error
  }
}

export async function apiBrandsGet() {
  const { dispatch } = brandDispatch
  setLoading(true)
  try {
    const response = await BaseApi.get('/api/v1/admin/brands', undefined, undefined)
    setLoading(false)
    dispatch?.(setBrands((response.data || []).reduce((pv: Record<string, DTO_200_Brand>, cv: DTO_200_Brand) => ({ ...pv, [cv._id || '']: cv }), {} as Record<string, DTO_200_Brand>)))
  } catch (error) {
    setLoading(false)
    console.error(error)
  }
}

/**
 * APIs
 */

export function apiBrandGetId() {
  return localStorage.getItem('brandIdToken')
}
export function apiBrandSet(id: string, user?: DTO_200_UserPopulated) {
  const { brands = [] } = user || {}
  const brand = brands.find((b) => b._id === id)
  if (brand) apiBrandSetDirect(brand, user)
}
export function apiBrandSetDirect(brand: DTO_200_Brand, user?: DTO_200_UserPopulated) {
  const { dispatch } = brandDispatch
  const { _id } = brand
  if (user) {
    const brands = _clone([...(user.brands || [])])
    const $brand = brands.find((b) => b._id === brand._id)
    if (!$brand) brands.push(brand)
    else Object.assign($brand, brand)
    dispatch?.(setAuthUser({ brands }))
  }
  dispatch?.(setAuthBrandId(_id))
}
export function apiBrandSetHasBrand(_: boolean) {
  //
}
export async function apiBrandOrder(brands: Record<string, DTO_200_Brand>, activeId: string, overId: string) {
  const { dispatch } = brandDispatch
  const ids = Object.keys(brands)
  const orders = _uniq(_pluck(Object.values(brands), 'order')) as number[]
  const oldIndex = ids.indexOf(activeId)
  const newIndex = ids.indexOf(overId)
  const order = arrayMove(ids, oldIndex, newIndex)
  dispatch?.(setBrandOrder(order))
  setLoading(true)
  if (orders.length < order.length) {
    await BaseApi.post('/api/v1/admin/brands/order', { order }, undefined, undefined)
  } else {
    await BaseApi.post(`/api/v1/admin/brand/{id}/order`, { order: newIndex }, undefined, { id: activeId })
    await BaseApi.post(`/api/v1/admin/brand/{id}/order`, { order: order.indexOf(overId) }, undefined, { id: overId })
  }
  setLoading(false)
}
