import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { Box, Link, ListItemText } from '@mui/material'
import useLocales from '../../../hooks/useLocales'
import Iconify from '../../Iconify'
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style'
import { isExternalLink } from '..'

interface Props {
  active?: boolean
  open?: boolean
  isCollapse?: boolean
  onOpen?: any
  item?: {
    children?: any[]
    icon?: any
    info?: any
    path?: string
    title?: string
  }
}

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }: Props) {
  const { translate: _ } = useLocales()
  const { title, path, icon, info, children } = item || {}

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle disableTypography primary={_(title || '')} isCollapse={isCollapse} />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  )

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeRoot={active}>
        {renderContent}
      </ListItemStyle>
    )
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeRoot={active}>
      {renderContent}
    </ListItemStyle>
  )
}

export function NavItemSub({ item, open = false, active = false, onOpen }: Props) {
  const { title, path, info, children } = item || {}

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText disableTypography primary={title} />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  )

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeSub={active} subItem>
        {renderContent}
      </ListItemStyle>
    )
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener" subItem>
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
      {renderContent}
    </ListItemStyle>
  )
}

export function DotIcon({ active }: { active?: boolean }) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main'
          })
        }}
      />
    </ListItemIconStyle>
  )
}

export function ArrowIcon({ open }: { open?: boolean }) {
  return <Iconify icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'} sx={{ width: 16, height: 16, ml: 1 }} />
}
