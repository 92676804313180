import { createSlice } from '@reduxjs/toolkit'
import { BaseApi } from '@/api'

function objFromArray(array: any[], key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current
    return accumulator
  }, {})
}

const initialState: IMail = {
  isLoading: false,
  error: null,
  mails: { byId: {}, allIds: [] },
  labels: []
}

interface IMail {
  isLoading: boolean
  error: Error | null
  mails: { byId: Record<string, any>; allIds: string[] }
  labels: any[]
}

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET LABELS
    getLabelsSuccess(state, action) {
      state.isLoading = false
      state.labels = action.payload
    },

    // GET MAILS
    getMailsSuccess(state, action) {
      const mails = action.payload

      state.isLoading = false
      state.mails.byId = objFromArray(mails)
      state.mails.allIds = Object.keys(state.mails.byId)
    },

    // GET MAIL
    getMailSuccess(state, action) {
      const mail = action.payload

      state.mails.byId[mail.id] = mail
      if (!state.mails.allIds.includes(mail.id)) {
        state.mails.allIds.push(mail.id)
      }
    }
  }
})

// Reducer
export const mailReducer = slice.reducer
export const mailDispatch: { dispatch?: Function } = {}

export function getLabels() {
  return async () => {
    const { dispatch } = mailDispatch
    dispatch?.(slice.actions.startLoading())
    try {
      // TODO: const response = await BaseApi.get('/api/mail/labels')
      const response = { data: { labels: [] } }
      dispatch?.(slice.actions.getLabelsSuccess(response.data.labels))
    } catch (error) {
      dispatch?.(slice.actions.hasError(error))
    }
  }
}

export function getMails(params: any) {
  return async () => {
    const { dispatch } = mailDispatch
    dispatch?.(slice.actions.startLoading())
    try {
      // TODO: const response = await BaseApi.get('/api/mail/mails', { params })
      const response = { data: { mails: [] } }
      dispatch?.(slice.actions.getMailsSuccess(response.data.mails))
    } catch (error) {
      dispatch?.(slice.actions.hasError(error))
    }
  }
}

export function getMail(mailId: string) {
  return async () => {
    const { dispatch } = mailDispatch
    dispatch?.(slice.actions.startLoading())
    try {
      // TODO: const response = await BaseApi.get('/api/mail/mail', { params: { mailId } })
      const response = { data: { mail: {} } }
      dispatch?.(slice.actions.getMailSuccess(response.data.mail))
    } catch (error) {
      dispatch?.(slice.actions.hasError(error))
    }
  }
}
