import React from 'react'
import * as Yup from 'yup'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link, Stack, Alert, IconButton, InputAdornment, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { PATH_AUTH } from '@/routes/paths'
import useIsMountedRef from '../../../hooks/useIsMountedRef'
import useLocales from '../../../hooks/useLocales'
import Iconify from '../../../components/Iconify'
import { FormProvider, RHFTextField } from '../../../components/hook-form'
import { useAuthLogin, apiAuthFbLogin, apiAuthGoogleLogin } from '@/redux/auth'
import { useYupEmailRequired, useYupStringRequired } from '@/lib/helpers'

export default function LoginForm() {
  const [login, isLoading] = useAuthLogin()
  const { translate: _ } = useLocales()
  const isMountedRef = useIsMountedRef()

  const [showPassword, setShowPassword] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: useYupEmailRequired(),
    password: useYupStringRequired(_('Password'))
  })

  const defaultValues = {
    email: '',
    password: '',
    remember: true
  }

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues
  })

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors }
  } = methods

  const onSubmit = async (data: any) => {
    try {
      await login(data.email, data.password)
    } catch (error) {
      console.error(error)
      reset()
      if (isMountedRef.current) {
        setError('afterSubmit' as any, error as Error)
      }
    }
  }

  async function loginWithFacebook(e: any) {
    e.preventDefault()
    e.stopPropagation()
    const res = await apiAuthFbLogin()
    if (typeof res === 'string') setError('afterSubmit' as any, new Error(res))
  }
  async function loginWithGoogle(e: any) {
    e.preventDefault()
    e.stopPropagation()
    const res = await apiAuthGoogleLogin()
    if (typeof res === 'string') setError('afterSubmit' as any, new Error(res))
  }

  const submitError = (errors as any)?.afterSubmit

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {submitError && <Alert severity="error">{submitError.message ? submitError.message : _(submitError.code)}</Alert>}
        <RHFTextField name="email" label={_('Email address')} disabled={isLoading} />
        <RHFTextField
          name="password"
          label={_('Password')}
          type={showPassword ? 'text' : 'password'}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          {_('Forgot password?')}
        </Link>
      </Stack>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
        {_('Login')}
      </LoadingButton>
      <br />
      <br />
      <br />
      <Button size="large" onClick={loginWithGoogle} className="btn-google" variant="contained">
        <i className="zmdi zmdi-google"></i>
        {'\xa0\xa0'}
        <span>{_('Login with Google')}</span>
      </Button>
      <div id="google-login"></div>
      {/* <div id="g_id_onload" data-client_id={GoogleAuth.client_id} data-login_uri={GoogleAuth.login_uri} data-prompt_parent_id="g_id_onload"></div> */}
      <br />
      <Button size="large" onClick={loginWithFacebook} className="btn-facebook" variant="contained">
        <i className="zmdi zmdi-facebook"></i>
        {'\xa0\xa0'}
        <span>{_('Login with Facebook')}</span>
      </Button>
    </FormProvider>
  )
}
