import React from 'react'
import type { ChangeEvent, ReactNode } from 'react'
import { createContext } from 'react'
import useLocalStorage from '../hooks/useLocalStorage'
import getColorPresets, { colorPresets, defaultPreset } from '../utils/getColorPresets'
import { defaultSettings } from '@/config'

const initialState: ISettingsContext = {
  ...defaultSettings,
  onChangeMode: () => {},
  onToggleMode: () => {},
  onChangeDirection: () => {},
  onChangeColor: () => {},
  onToggleStretch: () => {},
  onChangeLayout: () => {},
  onResetSetting: () => {},
  setColor: defaultPreset,
  colorOption: []
}

interface ISettingsTheme {
  themeMode: string
  themeDirection: string
  themeColorPresets: string
  themeLayout: string
  themeStretch: boolean
}

interface ISettingsContext extends ISettingsTheme {
  onChangeMode(): any
  onToggleMode(): any
  onChangeDirection(event?: { target: { value: string } }): any
  onChangeColor(): any
  onToggleStretch(): any
  onChangeLayout(): any
  onResetSetting(): any
  setColor: Record<string, string>
  colorOption: []
}

const SettingsContext = createContext(initialState)

function SettingsProvider({ children }: { children: ReactNode }) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    themeDirection: initialState.themeDirection,
    themeColorPresets: initialState.themeColorPresets,
    themeStretch: initialState.themeStretch,
    themeLayout: initialState.themeLayout
  })

  const onChangeMode = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeMode: event.target.value
    })
  }

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light'
    })
  }

  const onChangeDirection = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeDirection: event.target.value
    })
  }

  const onChangeColor = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeColorPresets: event.target.value
    })
  }

  const onChangeLayout = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeLayout: event.target.value
    })
  }

  const onToggleStretch = () => {
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch
    })
  }

  const onResetSetting = () => {
    setSettings({
      themeMode: initialState.themeMode,
      themeLayout: initialState.themeLayout,
      themeStretch: initialState.themeStretch,
      themeDirection: initialState.themeDirection,
      themeColorPresets: initialState.themeColorPresets
    })
  }

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        onChangeMode,
        onToggleMode,
        onChangeDirection,
        onChangeColor,
        setColor: getColorPresets(settings.themeColorPresets),
        colorOption: colorPresets.map((color) => ({
          name: color.name,
          value: color.main
        })),
        onToggleStretch,
        onChangeLayout,
        onResetSetting
      }}>
      {children}
    </SettingsContext.Provider>
  )
}

export { SettingsProvider, SettingsContext }
