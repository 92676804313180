import React from 'react'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Login } from '@/pages/auth/Login'
import LoadingScreen from '../components/LoadingScreen'
import RegisterBrand from '../pages/app/RegisterBrand'
import { useAuth } from '@/redux/auth'

export default function AuthGuard({ children }: { children: ReactNode }) {
  const { isAuthenticated, isInitialized, hasBrand } = useAuth()
  const { pathname } = useLocation()
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null)
  if (!isInitialized) return <LoadingScreen />
  if (!isAuthenticated) {
    if (pathname !== requestedLocation) setRequestedLocation(pathname)
    return <Login />
  }
  if (!hasBrand) return <RegisterBrand />
  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }
  return <>{children}</>
}
