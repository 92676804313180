import { useLocation } from 'react-router-dom'

export function useIsAdminPath() {
  const { pathname } = useLocation()
  return pathname.includes('/app/admin/')
}

export function useIdEditPath() {
  const { pathname } = useLocation()
  return pathname.includes('edit')
}
