import React from 'react'
import type { ForwardedRef, MouseEventHandler } from 'react'
import { forwardRef } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { Box, Link } from '@mui/material'
import { ICON } from '@/config'
import Iconify from '../../Iconify'
import { ListItemStyle } from './style'
import { isExternalLink } from '..'

interface Props {
  active?: boolean
  open?: boolean
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>
  item?: {
    children?: any[]
    icon?: any
    info?: any
    path?: string
    title?: string
  }
}

export const NavItemRoot = forwardRef(({ item, active, open, onMouseEnter, onMouseLeave }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  const { title, path, icon, children } = item || {}

  if (children) {
    return (
      <ListItemStyle ref={ref} open={open} activeRoot={active} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <NavItemContent icon={icon} title={title}>
          {children}
        </NavItemContent>
      </ListItemStyle>
    )
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
      <NavItemContent icon={icon} title={title}>
        {children}
      </NavItemContent>
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeRoot={active}>
      <NavItemContent icon={icon} title={title}>
        {children}
      </NavItemContent>
    </ListItemStyle>
  )
})

NavItemRoot.displayName = 'NavItemRoot'

export const NavItemSub = forwardRef(({ item, active, open, onMouseEnter, onMouseLeave }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  const { title, path, icon, children } = item || {}

  if (children) {
    return (
      <ListItemStyle ref={ref} subItem disableRipple open={open} activeSub={active} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <NavItemContent icon={icon} title={title} subItem>
          {children}
        </NavItemContent>
      </ListItemStyle>
    )
  }

  return isExternalLink(path) ? (
    <ListItemStyle subItem href={path} disableRipple rel="noopener" target="_blank" component={Link}>
      <NavItemContent icon={icon} title={title} subItem>
        {children}
      </NavItemContent>
    </ListItemStyle>
  ) : (
    <ListItemStyle disableRipple component={RouterLink} to={path} activeSub={active} subItem>
      <NavItemContent icon={icon} title={title} subItem>
        {children}
      </NavItemContent>
    </ListItemStyle>
  )
})

NavItemSub.displayName = 'NavItemSub'

interface NavItemContentProps {
  icon?: any
  children?: any[]
  subItem?: boolean
  title?: string
}

function NavItemContent({ icon, title, children, subItem }: NavItemContentProps) {
  return (
    <>
      {icon && (
        <Box
          component="span"
          sx={{
            mr: 1,
            width: ICON.NAVBAR_ITEM_HORIZONTAL,
            height: ICON.NAVBAR_ITEM_HORIZONTAL,
            '& svg': { width: '100%', height: '100%' }
          }}>
          {icon}
        </Box>
      )}
      {title}
      {children && (
        <Iconify
          icon={subItem ? 'eva:chevron-right-fill' : 'eva:chevron-down-fill'}
          sx={{
            ml: 0.5,
            width: ICON.NAVBAR_ITEM_HORIZONTAL,
            height: ICON.NAVBAR_ITEM_HORIZONTAL
          }}
        />
      )}
    </>
  )
}
