import React from 'react'
import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material'

export type SvgIconTypes =
  | 'ic_bear' //
  | 'ic_mug'
  | 'ic_analytics'
  | 'ic_avatar'
  | 'ic_booking'
  | 'ic_chat'
  | 'ic_flag_en'
  | 'ic_gear'
  | 'ic_mail'
  | 'ic_verifed'
  | 'ic_android'
  | 'ic_banking'
  | 'ic_calendar'
  | 'ic_dashboard'
  | 'ic_flag_es'
  | 'ic_invoice'
  | 'ic_mercadopago'
  | 'ic_apple'
  | 'ic_blog'
  | 'ic_cart'
  | 'ic_ecommerce'
  | 'ic_flag_il'
  | 'ic_kanban'
  | 'ic_user'
  | 'ic_excel'

interface Props extends BoxProps {
  src: SvgIconTypes
  fill?: string
  noMask?: boolean
}

export default function SvgIconStyle({ fill, noMask, src, sx }: Props) {
  src = `/icons/${src}.svg` as SvgIconTypes
  return (
    <Box
      component="span"
      sx={{
        width: 24,
        height: 24,
        display: 'inline-block',
        bgcolor: noMask ? undefined : fill || 'currentColor',
        mask: noMask ? undefined : `url(${src}) no-repeat center / contain`,
        WebkitMask: noMask ? undefined : `url(${src}) no-repeat center / contain`,
        background: noMask ? `url(${src}) no-repeat center / contain` : undefined,
        ...sx
      }}
    />
  )
}
