import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { brandDispatch, brandReducer } from './brand'
import { paymentReducer } from './payment'
import { dashboardDispatch, dashboardReducer } from './dashboard'
import { productDispatch, productReducer } from './product'
import { kanbanDispatch, kanbanReducer } from './kanban'
import { calendarReducer } from './calendar'
import { chatReducer } from './chat'
import { mailDispatch, mailReducer } from './mail'
import { authDispatch, authReducer } from './auth'
import { reactionDispatch, reactionReducer } from './reaction'
import { userDispatch, userReducer } from '@/redux/user'
import { drawDispatch, drawReducer } from "@/redux/draw";

const authPersistConfig = {
  key: 'auth',
  storage,
  keyPrefix: 'redux-',
  blackList: []
}

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
}

const rootReducer = combineReducers({
  brand: brandReducer,
  draw: drawReducer,
  dashboard: dashboardReducer,
  payment: paymentReducer,
  kanban: kanbanReducer,
  calendar: calendarReducer,
  chat: chatReducer,
  mail: mailReducer,
  reaction: reactionReducer,
  product: persistReducer(productPersistConfig, productReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  user: userReducer
})

export type IRootState = ReturnType<typeof rootReducer>
export { rootReducer }

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    })
})

const persistor = persistStore(store)
const { dispatch } = store
authDispatch.dispatch = dispatch
authDispatch.persistor = persistor
productDispatch.dispatch = dispatch
kanbanDispatch.dispatch = dispatch
drawDispatch.dispatch = dispatch
mailDispatch.dispatch = dispatch
brandDispatch.dispatch = dispatch
dashboardDispatch.dispatch = dispatch
userDispatch.dispatch = dispatch
reactionDispatch.dispatch = dispatch
export { store, persistor, dispatch }
