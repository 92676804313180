export const es = {
  '%s cards': `Cartas de %s`,
  '%s created successfully!': `%s creado exitosamente`,
  '%s is required': `Debe llenar %s`,
  '%s list': `Listado de %s`,
  '%s must be a number': `%s debe ser un número`,
  '%s successfully created': `%s creado exitosamente`,
  '%s successfully deleted': `%s eliminado exitosamente`,
  '%s successfully updated': `%s actualizado exitosamente`,
  '404 Page Not Found': `404 Pagina no encontrada`,
  '500 Internal Server Error': `500 Error interno de servidor`,
  'About': `Sobre`,
  'About us': `Sobre nosotros`,
  'Account': `Cuenta`,
  'Account Settings': `Configuración`,
  'Active': `Activo`,
  'Add': `Agregar`,
  'Add %s': `Agregar %s`,
  'Add Brand': `Agregar marca`,
  'Add coupons': `Agregar cupones`,
  'Add coupons to product': `Agregar cupones al producto`,
  'Add product': `Agregar producto`,
  'Add products': `Agregar productos`,
  'Add products to chests': `Agregar producto a las cajas de regalo`,
  'Add products to prizes': `Agregar producto al premio esfuerzo`,
  'Address': `Dirección`,
  'Adjust the image correctly': `Ajuste la imagen correctamente`,
  'Admin': `Admin`,
  'All day': `Todo el día`,
  'Allowed': `Permitido`,
  'Already have an account?': `¿Ya tienes una cuenta?`,
  'Amount': `Monto`,
  'Analytics': `Analíticas`,
  'and': `y`,
  'App': `App`,
  'Apply': `Aplicar`,
  'Approve': `Aprobar`,
  'Are you sure you want to delete %i %s?': `¿Estás seguro de que quieres eliminar %i %s?`,
  'Are you sure you want to delete this %s?': `¿Estás seguro de que deseas eliminar este %s?`,
  'Ask before share': `Preguntar antes de compartir`,
  'Available': `Disponible`,
  'Avatar is required': `Debe ingresar un avatar`,
  'Awards for slot #77': `Premios caja #77`,
  'Back': `Volver`,
  'Balance': `Saldo`,
  'Banking': `Banca`,
  'Banned': `Baneado`,
  'Banner': `Portada`,
  'Billing': `Facturación`,
  'Billing Address': `Datos`,
  'Blog': `Blog`,
  'Brand': `Marca`,
  'Brand Facebook ID': `ID de Facebook de la marca`,
  'Brand Facebook URL': `URL de Facebook de la marca`,
  'Brand ID Code': `DUNS de la marca`,
  'Brand identity': `Identidad de la marca`,
  'Brand Instagram name': `Nombre de la marca en Instagram`,
  'Brand legal name': `Nombre legal de la marca`,
  'Brand name': `Nombre de la marca`,
  'Brands': `Marcas`,
  'browse': `explorar`,
  'Bug Reports': `Reporte de bugs`,
  'Buy': `Comprar`,
  'By registering, I agree to click77': `Al registrarse, aceptas los`,
  'Calendar': `Calendario`,
  'Cancel': `Cancelar`,
  'Card number': `Número de tarjeta`,
  'Cards': `Tarjetas`,
  'Cart is empty': `El carrito esta vacío`,
  'Category': `Cateogría`,
  'Change user balance for "%s"': `Cambiar saldo para "%s"`,
  'Change user brands for "%s"': `Cambiar marcas para "%s"`,
  'Change user permissions for "%s"': `Cambiar permisos de usuario para "%s"`,
  'Chat': `Chat`,
  'Check In': `Check Im`,
  'Check Out': `Check Out`,
  'Checkout': `Checkout`,
  'chests should not be empty': `Los premios caja #77 no deben estar vacíos.`,
  'City': `Ciudad`,
  'Clear All': `Borrar todo`,
  'Click77': `Click77`,
  'Click77 Box Code': `Código Box de Click77`,
  'Click77 Demo Product': `Producto Demo Click77`,
  'Clicks': `Clicks`,
  'Close': `Cerrar`,
  'Code': `Código`,
  'Color': `Color`,
  'Coming Soon': `Muy pronto`,
  'Comment': `Comentario`,
  'Company': `Empresa`,
  'Components Overview': `Descripción general`,
  'Config brand': `Configurar marca`,
  'Confirm': `Confirmar`,
  'Confirm & Transfer': `Confirmar y transferir`,
  'Confirm New Password': `Confirmar contraseña`,
  'Contact us': `Contacta con nosotros`,
  'Content': `Contenido`,
  'Continue': `Continuar`,
  'Cost': `Costo`,
  'Countries': `Países`,
  'Country': `País`,
  'Coupons': `Cupones`,
  'Cover is required': `Debe ingresar una portada`,
  'Create %s': `Crear %s`,
  'Create success!': `¡Creado satisfactoriamente!`,
  'Credit': `Creditos`,
  'Currency': `Divisa`,
  'Current downloads': `Descargas actuales`,
  'CVV': `CVV`,
  'Dashboard': `Dashboard`,
  'Date': `Fecha`,
  'Days': `Días`,
  'Default': `Defecto`,
  'Delete': `Eliminar`,
  'Delete %s': `Eliminar %s`,
  'Delete successful!': `Eliminado exitoso!`,
  'Deliver to this address': `Entregar a esta dirección`,
  'Delivery options': `Opciones de entrega`,
  'Demo store for IOS deploy': `Tienda demo para deploy IOS`,
  'Description': `Descripción`,
  'Different plans for': `Diferentes planes para`,
  'different sizes and needs': `diferentes tamaños y necesidades`,
  'discount': `de descuento`,
  'Discount codes / Gifts': `Códigos de descuento / Regalos`,
  'Don\'t have a code?': `¿No tienes el código?`,
  'Don\'t have an account?': `¿Aun no tienes cuenta?`,
  'Download %s': `Descargar %s`,
  'Draw': `Juego`,
  'draw available for': `juego disponible para`,
  'draw is finished': `El juego ha terminado`,
  'Draws': `Juegos`,
  'Drop files here or click': `Arrastra los archivos aquí o haz click`,
  'Drop or Select file': `Arrastra o selecciona los archivos`,
  'E-Commerce': `E-Commerce`,
  'E-Commerce URL': `E-Commerce URL`,
  'E-Commerce visitors': `Visitantes de\nE-commerce`,
  'E-mail': `E-mail`,
  'Edit': `Editar`,
  'Edit %s': `Editar %s`,
  'Effort prize that will be given to those who make the most clicks': `Premios al esfuerzo, se darán a los jugadores que hagan más clicks`,
  'Effort prizes': `Premios esfuerzo`,
  'Email': `Correo electrónico`,
  'Email address': `Dirección de correo electrónico`,
  'Email is required': `Debe ingresar un correo electrónico`,
  'Email must be a valid email address': `El correo electrónico debe ser válido`,
  'Empty': `Datos vacíos`,
  'Empty content': `Contenido vacío`,
  'Enable comments': `Habilitar comentarios`,
  'End date': `Fecha de término`,
  'Enter your details below': `Ingresa tus datos a continuación`,
  'Enter your email': `Ingresa tu correo`,
  'Enter your message here': `Ingresa tu mensaje aquí`,
  'Event': `Evento`,
  'Expenses': `Gastos`,
  'Expiration date': `Fecha de expiración`,
  'Facebook page id': `ID de la página de Facebook`,
  'Factor': `Factor`,
  'Failed to create': `Error al crear`,
  'Failed to create %s': `Error al crear %s`,
  'Failed to create the %s': `Error al crear %s`,
  'Failed to delete the %s': `Error al eliminar %s`,
  'Failed to update': `Error al actualizar`,
  'Failed to update the %s': `Error al actualizar %s`,
  'Faqs': `Preguntas frecuentes`,
  'Filter list': `Filtros`,
  'Find friends': `Encuentra amigos`,
  'Finish': `Finalizado`,
  'First name': `Nombre`,
  'Forgot password?': `¿Olvidaste tu contraseña?`,
  'Forgot your password?': `¿Olvidaste tu contraseña?`,
  'Frequently asked questions': `Preguntas frecuentes`,
  'Full name': `Nombre completo`,
  'Gender': `Género`,
  'General': `General`,
  'get start': `comienza ahora`,
  'Get started': `Comienza ahora`,
  'Global': `Global`,
  'Go': `Ir`,
  'Go to Home': `Ir al home`,
  'Golden Clicks': `Golden Clicks`,
  'Have a Question?': `¿Tengo una pregunta?`,
  'Hi, Welcome back': `Hola bienvenido de nuevo`,
  'Hide store link': `Ocultar enlace a tienda`,
  'Hide stores': `Ocultar tiendas`,
  'Home': `Home`,
  'Hours': `Horas`,
  'Ignore balance': `Ignorar saldo`,
  'Image height must be greater than': `La altura de la imagen debe ser mayor que`,
  'Image height must be lower than': `La altura de la imagen debe ser menor que`,
  'Image ratio must be': `La relación de imagen debe ser`,
  'Image width must be greater than': `El ancho de la imagen debe ser mayor que`,
  'Image width must be lower than': `El ancho de la imagen debe ser menor que`,
  'Images': `Imágenes`,
  'Images is required': `Debe ingresar imágenes`,
  'In stock': `En stock`,
  'Includes worldwide shipping': `Envío a todo el mundo`,
  'Income': `Ingreso`,
  'Insert amount': `Insertar cantidad`,
  'Instagram nickname': `Nickname de Instagram`,
  'Instagram visitors': `Visitantes de\nInstagram`,
  'Introduction': `Introducción`,
  'Invoice': `Factura`,
  'Invoice Details': `Detalles de factura`,
  'Invoice from': `Factura de`,
  'Invoice to': `Factura hasta`,
  'Is click77': `Es click77`,
  'Is finished': `Juego terminado`,
  'Is hidden': `Está oculto`,
  'Is sample': `Es de muestra`,
  'It\'s in the game': `Está en el juego`,
  'Item Orders': `Pedidos de artículos`,
  'Kanban': `Kanban`,
  'Last name': `Apellido`,
  'Let\'s finish powering you up!': `¡Terminemos de darte energía!`,
  'Link e-commerce': `Link e-commerce`,
  'Log out': `Cerrar sesión`,
  'Login': `Iniciar sesión`,
  'Login with Facebook': `Iniciar con Facebook`,
  'Login with Google': `Iniciar con Google`,
  'Logout': `Cerrar sesión`,
  'Look like you have no items in your shopping cart': `Parece que no tienes productos en tu carrito de compras`,
  'Mail': `E-mail`,
  'Maintenance': `Mantenimiento`,
  'Managment': `Gestión`,
  'Mark all as read': `Marcar todo como leido`,
  'max size of': `tamaño máximo de`,
  'Meta description': `(Meta) Descripción`,
  'Meta keywords': `(Meta) Palabras clave`,
  'Meta title': `(Meta) Título`,
  'Minimum exposure time': `Tiempo mínimo de exposición`,
  'Minimum salary in USD': `Salario mínimo en USD`,
  'Minutes': `Minutos`,
  'MM/YY': `MM/YY`,
  'Name': `Nombre`,
  'Name on card': `Nombre de la tarjeta`,
  'New %s': `Nuevo %s`,
  'New brand to click77': `Agregar nueva marca a click77`,
  'New Password is required': `Debe ingresar una nueva contraseña`,
  'New Users': `Nuevos usuarios`,
  'no-reply@click77.app': `no-reply@click77.app`,
  'NOTES': `NOTAS`,
  'Observation': `Observación`,
  'OK': `OK`,
  'Old Password': `Antigua contraseña`,
  'Order Summary': `Resumen del pedido`,
  'Password': `Contraseña`,
  'Password must be at least 6 characters': `La contraseña debe tener al menos 6 caracteres`,
  'Passwords must match': `Las contraseñas deben ser iguales`,
  'Payment': `Pagar`,
  'Payment Method': `Formas de pago`,
  'Payment options': `Opciones de pago`,
  'Pending': `Pendiente`,
  'Phone number': `Número Telefónico`,
  'Photo': `Foto`,
  'Photo URL': `URL de foto`,
  'Photos is required': `Debe ingresar fotos`,
  'Pick your emoji': `Elige tu emoji`,
  'Plan': `Plan`,
  'Please check your email': `Por favor revise su correo electrónico`,
  'Please enter the email address associated with your account and We will email you a link to reset your password.': `Ingresa tu correo electrónico y te enviaremos un correo electrónico con las instrucciones para continuar.`,
  'Policy': `Política`,
  'Post': `Publicación`,
  'Post Details': `Detalles de publicación`,
  'Post Title': `Título de la entrada`,
  'Posts': `Publicaciones`,
  'Press enter to separate one coupon from another': `Presiona enter para separar un cupón de otro`,
  'Preview': `Previsualización`,
  'Price': `Precio`,
  'Price includes taxes': `El precio incluye impuestos`,
  'Price should not be $0.00': `El precio no debe ser $0.00`,
  'Pricing': `Precios`,
  'Privacy Policy': `Politicas de privacidad`,
  'Prizes': `Premios`,
  'prizes should not be empty': `Los premios esfuerzo no deben estar vacíos`,
  'Prizes to be included in chests': `Premios incluidos en las cajas de regalo`,
  'Product': `Producto`,
  'Product Code': `Código del producto`,
  'Product Details': `Detalles del producto`,
  'Product name': `Nombre del producto`,
  'Product SKU': `SKU del producto`,
  'Product Sold': `Producto vendido`,
  'Product url': `URL del producto`,
  'Product URL within the e-commerce': `URL del producto dentro del e-commerce`,
  'Products': `Productos`,
  'Products found': `Productos encontrados`,
  'Professional plan is right for you': `El plan profesional es para tí`,
  'Profile': `Perfil`,
  'Provider': `Proveedor`,
  'Public Profile': `Perfil público`,
  'Publish': `Publicar`,
  'quantity': `cantidad`,
  'Rate': `Tasa`,
  'Rating is required': `Debe ingresar un rating`,
  'Ready': `¡Listo!`,
  'Ready to create draw!': `Lista para crear juego!`,
  'Redeemed': `Canjeado`,
  'Register': `Registrar`,
  'Register with Facebook': `Registrar con Facebook`,
  'Register with Google': `Registrar con Google`,
  'Registered': `Registrado`,
  'Regular Price': `Precio`,
  'Reject': `Rechazar`,
  'Request sent successfully': `Solicitud enviada con éxito`,
  'Required field': `Campo requerido`,
  'Resend code': `Reenviar código`,
  'Reset password': `Resetear contraseña`,
  'Review': `Revisar`,
  'Review (%s)': `Revisar (%s)`,
  'Role': `Rol`,
  'Role number': `Número de rol`,
  'Salary per minute': `Salario por minuto`,
  'Sale Price': `Precio de venta`,
  'Sales Profit': `Beneficios de ventas`,
  'Save changes': `Guardar cambios`,
  'Search': `Buscar`,
  'Search %s': `Buscar %s`,
  'Search contacts': `Buscar contactos`,
  'Search product': `Buscar producto`,
  'Seconds': `Segundos`,
  'Section': `Sección`,
  'Section name': `Nombre de la sección`,
  'See administrator as user of this brand': `Ver el administrador como usuario de esta marca`,
  'Select brand': `Seleccione una marca`,
  'Select your plan that best suits your needs': `Selecciona el plan que mejor se adapte a tus necesidades`,
  'Set as coming soon': `Establecer como próximamente`,
  'Settings': `Configuración`,
  'Share image': `Compartir imagen`,
  'Share what you are thinking here': `Comparte lo que estás pensando aquí`,
  'Shop': `Tienda`,
  'Shown as coming soon': `Mostrar como próximamente`,
  'Sign in to Click77': `Entra a Click77`,
  'Sign up in Click77': `Registrate en Click77`,
  'SKU': `SKU`,
  'Sold out': `Vendido`,
  'Soon': `Pronto`,
  'Sorry, page not found!': `Lo sentimos, página no encontrada!`,
  'Sorry, we are currently undergoing system maintenance, we will enable this option soon': `Lo sentimos, actualmente estamos en mantenimiento del sistema, habilitaremos esta opción pronto.`,
  'Sorry, we couldn\'t find the page you\'re looking for. Perhaps you\'ve mistyped the URL? Be sure to check your spelling': `Lo sentimos, no pudimos encontrar la página que estás buscando. ¿Quizás has escrito mal la URL? Asegúrate de revisar tu ortografía`,
  'Start': `Iniciar`,
  'start a': `inicia un`,
  'Start date': `Fecha inicio`,
  'Start today': `¡Inicia hoy!`,
  'start your draws today and begin to be in the eyes of thousands of': `comienza tus sorteos hoy y comienza a estar en los ojos de miles de`,
  'State': `Estado`,
  'State / Region': `Estado / Región`,
  'Statistics': `Estadísticas`,
  'Status': `Estado`,
  'Subject': `Asunto`,
  'Successfully verified!': `¡Verificado con éxito!`,
  'Summary': `Resumen`,
  'Support': `Soporte`,
  'Tags': `Etiquetas`,
  'Task name': `Nombre de la tarea`,
  'Terms of Service': `Terminos de servicio`,
  'TERMS OF USE': `CONDICIONES DE USO`,
  'Thanks for create you brand in click77': `Gracias por crear una nueva marca en click77`,
  'The draws have ended': `Se acabaron los juegos`,
  'The instagram profile doesn\'t exist': `El perfil de instagram no existe`,
  'The url must be valid': `La URL debe ser válida`,
  'There was an error, please try again later': `Hubo un error, inténtalo de nuevo más tarde`,
  'this nickname is used': `El link ya está registrado`,
  'This setting is active': `Esta configuración está activa`,
  'thorough your machine': `en tu computador`,
  'Title': `Título`,
  'To': `Para`,
  'Total': `Total`,
  'Total Balance': `Balance total`,
  'Total Billed': `Total a pagar`,
  'Total Booking': `Reserva Total`,
  'Total exposure': `Total de\nexposición`,
  'Total players': `Total de\njugadores`,
  'Town / City': `Pueblo / Ciudad`,
  'Transfer Now': `Transferir ahora`,
  'Transfer to': `Transferir a`,
  'Type a message': `Escribe un mensaje`,
  'Unable to logout!': `¡No se puede cerrar la sesión!`,
  'Update success!': `¡Modificado satisfactoriamente!`,
  'URL': `URL`,
  'Use this address as default': `Usar esta dirección como predeterminada`,
  'User': `Usuario`,
  'Users': `Usuarios`,
  'users who participate by winning your draws': `usuarios que participan ganando tus sorteos`,
  'Verify': `Verificar`,
  'very soon you will be able to draw products and get followers!': `¡Falta poco para que comiences a sortear productos y ganar seguidores!`,
  'View as administrator': `Ver como administrador`,
  'Waiting': `En espera`,
  'We appreciate your business. Should you need us to add VAT or extra notes let us know!': `Apreciamos tu negocio. Si necesita que agreguemos el IVA o notas adicionales, ¡háganoslo saber!`,
  'We have emailed a 6-digit confirmation code to %s, please enter the code in below box to verify your email': `Hemos enviado por correo electrónico un código de confirmación de 6 dígitos a %s, ingrese el código en el cuadro a continuación para verificar su correo electrónico`,
  'We have sent a confirmation email to': `Hemos enviado un correo electrónico de confirmación a`,
  'Website currently under maintenance': `Sitio web actualmente en mantenimiento`,
  'Weekly Sales': `Ventas Semanales`,
  'welcome to click77': `Bienvenido a click77`,
  'Winners': `Ganadores`,
  'with': `con`,
  'With end date': `Con fecha de término`,
  'Write a comment': `Escribe un comentario`,
  'Write a message': `Escribe un mensaje`,
  'Write something awesome': `Escribe algo genial`,
  'You still don\'t have a product': `Aún no tienes un producto`,
  'Your Balance': `Tu balance`,
  'Zip / Code': `Código postal`,
}
