import { createAction, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { setLoading } from '@/redux/reaction'
import { BaseApi } from '@/api'
import type { DTO_200_User, DTO_200_UserPopulated } from '@/api/models'

interface IUserState {
  users: DTO_200_User[]
  currentUser?: DTO_200_UserPopulated
}
const getInitialState = (): IUserState => ({
  users: [],
  currentUser: undefined
})

export const setUserInitState = createAction('SET_USER_INIT_STATE')
export const setUsers = createAction<DTO_200_User[]>('SET_USERS')
export const setUser = createAction<DTO_200_User>('SET_USER')
export const setCurrentUser = createAction<DTO_200_UserPopulated | undefined>('SET_USERSET_CURRENT_USER')

const dahsboardSlice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setUserInitState, (state) => {
        Object.assign(state, getInitialState())
      })
      .addCase(setUsers, (state, { payload }) => {
        state.users = (payload ?? []).map((u) => parseUser(u))
      })
      .addCase(setUser, (state, { payload }) => {
        const user = state.users.find((u) => u._id === payload._id)
        if (user) Object.assign(user, parseUser(payload))
        else state.users.push(parseUser(payload))
      })
      .addCase(setCurrentUser, (state, { payload }) => {
        state.currentUser = payload
      })
  }
})

function parseUser<K extends DTO_200_User>(u: Partial<K>): K {
  u.name = u.name || ''
  u.lastName = u.lastName || ''
  u.email = u.email || ''
  u.clicks = u.clicks || 0
  u.balance = u.balance || 0
  u.brandCount = u.brands?.length || 0
  return u as K
}

// Reducer
export const userReducer = dahsboardSlice.reducer
export const userDispatch: { dispatch?: Function } = {}
export const selectUsers = createSelector([(store: IRootState) => store.user.users], (users) => users)
export const selectCurrentUser = createSelector([(store: IRootState) => store.user.currentUser], (user) => user)

/**
 * APIs
 */
export async function apiUsersGet() {
  const { dispatch } = userDispatch
  setLoading(true)
  try {
    const response = await BaseApi.get(`/api/v1/admin/users`, undefined, undefined)
    setLoading(false)
    dispatch?.(setUsers(response.data))
  } catch (error) {
    setLoading(false)
    console.error(error)
  }
}

export async function apiUserPost(user: Partial<DTO_200_UserPopulated>) {
  const { dispatch } = userDispatch
  setLoading(true)
  try {
    const response = await BaseApi.post(`/api/v1/admin/user`, { user: user as Omit<DTO_200_UserPopulated, 'awards' | 'draws' | 'brands' | 'purchases'> }, undefined, undefined)
    setLoading(false)
    dispatch?.(setUser(response.data as Omit<DTO_200_UserPopulated, 'awards' | 'draws' | 'brands' | 'purchases'>))
  } catch (error) {
    setLoading(false)
    console.error(error)
  }
}
