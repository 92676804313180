import { createAction, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import type { IImageFile } from '@/components/EasyCrop'

interface IReactionState {
  loading: boolean
  imageCrop?: IImageFile
}
const getInitialState = (): IReactionState => ({
  loading: false,
  imageCrop: undefined
})
export const setReactionInitState = createAction('SET_REACTION_INIT_STATE')
export const setReactionLoading = createAction<boolean>('SET_LOADING')
export const setReactionImageCrop = createAction<IImageFile | undefined>('SET_REACTION_IMAGE_CROP')

const userSlice = createSlice({
  name: 'reaction',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setReactionInitState, (state) => {
        Object.assign(state, getInitialState())
      })
      .addCase(setReactionLoading, (state, { payload }) => {
        state.loading = payload
      })
      .addCase(setReactionImageCrop, (state, { payload }) => {
        state.imageCrop = payload
      })
      .addDefaultCase(() => {
        //
      })
  }
})
export const selectReactionLoading = createSelector([(state: IRootState) => state.reaction.loading as boolean], (data) => data)
export const selectReactionImageCrop = createSelector([(state: IRootState) => state.reaction.imageCrop], (imageCrop) => imageCrop)
export const reactionReducer = userSlice.reducer
export const reactionDispatch: { dispatch?: Function } = {}

/**
 * API
 */
export function setLoading(loading: boolean) {
  reactionDispatch.dispatch?.(setReactionLoading(loading))
}
export function setLoadingAsync(loading: boolean) {
  setTimeout(() => {
    reactionDispatch.dispatch?.(setReactionLoading(loading))
  }, 10)
}

export async function checkEasyCrop(image?: IImageFile, force: boolean = false) {
  if (!image) return undefined
  const { options } = image
  if (!options) return image
  let process = false
  if (force) process = true
  else if (options.maxWidth && image.width > options.maxWidth) process = true
  else if (options.maxHeight && image.height > options.maxHeight) process = true
  else if (options.ratio && options.ratio !== image.width / image.height) process = true
  if (process) {
    image = await new Promise((res: (image: IImageFile | undefined) => void) => {
      options.callback = res
      reactionDispatch.dispatch?.(setReactionImageCrop(image))
    })
  }
  return image
}

/**
 * REDUX
 */
export function useIsLoading() {
  return useSelector(selectReactionLoading)
}
