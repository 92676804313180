import type { IImageFile } from '@/components/EasyCrop'
import type { DropEvent } from 'react-dropzone'
import { checkEasyCrop, setLoading } from '@/redux/reaction'
import type { i18n } from 'i18next'

export async function _waitForPic(pic: string) {
  await new Promise((res) => {
    const img = new Image()
    img.onload = () => res(img)
    img.onerror = () => setTimeout(() => _waitForPic(pic).then(res), 500)
    img.src = pic
  })
}
export async function _waitFor(done: () => boolean, ms: number = 100) {
  await new Promise((res) => {
    const t = setInterval(() => {
      if (!done()) return
      clearInterval(t)
      res(true)
    }, ms)
  })
}

export async function _fromFileToImageFile(file?: IImageFile): Promise<IImageFile | undefined> {
  if (!file) return undefined
  return await new Promise((resolve) => {
    const image = new Image()
    image.onload = function () {
      file.width = image.width
      file.height = image.height
      file.src = image.src
      file.preview = image.src
      resolve(file)
    }
    image.src = URL.createObjectURL(file)
  })
}

export function _getFilesFromEvent(props: IImageFile['options']) {
  return async (event: DropEvent): Promise<(File | DataTransferItem)[]> => {
    setLoading(true)
    const $files = (event as any)?.target?.files || (event as any)?.dataTransfer?.files || []
    const promises = []
    for (let index = 0; index < $files.length; index++) {
      promises.push(_fromFileToImageFile($files[index]))
    }
    await Promise.all(promises)
    let files: (IImageFile | undefined)[] = []
    for (const file of $files) {
      file.options = props
      setLoading(false)
      files.push(await checkEasyCrop(file, true))
      setLoading(true)
    }
    files = files.filter((f) => !!f)
    setLoading(false)
    return files as IImageFile[]
  }
}

interface IPhotoValidator {
  maxWidth?: number
  maxHeight?: number
  minWidth?: number
  minHeight?: number
  ratio?: number
}
export function _validateImage(_: i18n['t'], props: IPhotoValidator) {
  return ($file: File) => {
    const file = $file as IImageFile
    if (props.minWidth && Math.round(file.width) < props.minWidth) {
      return {
        code: 'small-width',
        message: `${_('Image width must be greater than')} ${props.minWidth}`
      }
    }
    if (props.minHeight && Math.round(file.height) < props.minHeight) {
      return {
        code: 'small-height',
        message: `${_('Image height must be greater than')} ${props.minHeight}`
      }
    }
    if (props.maxWidth && Math.round(file.width) > props.maxWidth) {
      return {
        code: 'big-width',
        message: `${_('Image width must be lower than')} ${props.minWidth}`
      }
    }
    if (props.maxHeight && Math.round(file.height) > props.maxHeight) {
      return {
        code: 'big-height',
        message: `${_('Image height must be lower than')} ${props.minHeight}`
      }
    }
    const ratio = Math.round((file.width / file.height) * 10) / 10
    if (ratio !== props.ratio) {
      return {
        code: 'ratio-wrong',
        message: `${_('Image ratio must be')} 1.5:1`
      }
    }
    return null
  }
}
