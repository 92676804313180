import React from 'react'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { alpha, ThemeProvider, createTheme, useTheme } from '@mui/material/styles'
import useSettings from '../hooks/useSettings'
import componentsOverride from '../theme/overrides'
import type { ThemeOptions } from '@mui/material'

export default function ThemeColorPresets({ children }: { children: ReactNode }) {
  const defaultTheme: ITheme = useTheme()
  const { setColor } = useSettings()

  const themeOptions = useMemo(
    () =>
      ({
        ...defaultTheme,
        palette: {
          ...defaultTheme.palette,
          primary: setColor
        },
        customShadows: {
          ...defaultTheme.customShadows,
          primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`
        }
      }) as ThemeOptions,
    [setColor, defaultTheme]
  )

  const theme = createTheme(themeOptions)
  theme.components = componentsOverride(theme as unknown as ITheme)

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
