// noinspection JSUnusedLocalSymbols

import React, { useEffect, useMemo } from 'react'
import type { LazyExoticComponent } from 'react'
import { Suspense, lazy } from 'react'
import { Navigate, useRoutes, useLocation, useParams } from 'react-router-dom'
import type { Params } from 'react-router-dom'
import MainLayout from '../layouts/main'
import DashboardLayout from '../layouts/dashboard'
import LogoOnlyLayout from '../layouts/LogoOnlyLayout'
import GuestGuard from '../guards/GuestGuard'
import AuthGuard from '../guards/AuthGuard'
import { PATH_AFTER_LOGIN } from '@/config'
import LoadingScreen from '../components/LoadingScreen'
import { apiAuthCheckUser, useIsAdmin } from '@/redux/auth'
import useLocales from '@/hooks/useLocales'
import type { i18n } from 'i18next'
import SvgIconStyle from '@/components/SvgIconStyle'
import type { SvgIconTypes } from '@/components/SvgIconStyle'
import { sprintf } from '@/lib'
import { UserCreate } from '@/pages/admin/users/UserCreate'

type IAccessType = 'admin' | 'user' | 'all'

export function getRoutes(_: i18n['t']) {
  return [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        {
          path: 'confirm',
          element: (
            <GuestGuard>
              <VerifyCode />
            </GuestGuard>
          )
        },
        {
          path: 'recover',
          element: (
            <GuestGuard>
              <VerifyCode />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          title: _('Dashboard'),
          subheader: _('General'),
          icon: ICONS.dashboard, //
          access: 'all' as IAccessType,
          path: 'app',
          element: <GeneralApp />
        }
      ]
    },
    // App Routes
    {
      title: _('Dashboard'),
      path: 'app',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true },
        {
          title: _('Products'),
          subheader: _('General'),
          icon: ICONS.ecommerce,
          access: 'user' as IAccessType,
          path: 'products',
          children: [
            { path: '', element: <ProductList />, index: true },
            { title: sprintf(_('Create %s'), _('Product')), path: 'new', element: <CreateProduct /> },
            { title: sprintf(_('Edit %s'), _('Product')), path: 'edit/:id', element: <EditProduct /> }
          ]
        },
        {
          title: _('Draws'),
          subheader: _('General'),
          icon: ICONS.draws,
          access: 'user' as IAccessType,
          path: 'draws',
          children: [
            { path: '', element: <DrawList />, index: true }
            //{ title: sprintf(_('Create %s'), _('Draw')), path: 'new', element: <CreateDraw /> },
            //{ title: sprintf(_('Edit %s'), _('Draw')), path: 'edit/:id', element: <EditDraw /> }
          ]
        },
        /*
        {
          title: _('Billing'),
          subheader: _('General'),
          icon: ICONS.invoce,
          access: 'user' as IAccessType,
          path: 'billing',
          children: [
            { path: '', element: <Billing /> },
            { path: 'price', element: <Pricing /> },
            { path: 'checkout', element: <Payment /> }
          ]
        },
        */
        {
          title: '',
          path: 'admin',
          children: [
            {
              title: _('Brands'),
              subheader: _('General'),
              icon: ICONS.mug,
              access: 'admin' as IAccessType, //
              path: 'brands',
              children: [
                { path: '', element: <Brands />, index: true },
                {
                  title: sprintf(_('Edit %s'), _('Brand')),
                  path: ':brandId',
                  children: [
                    { title: _('Brand'), path: '', element: <EditBrand /> },
                    {
                      title: '',
                      path: 'products',
                      children: [
                        { title: sprintf(_('Create %s'), _('Product')), path: 'new', element: <CreateProduct /> },
                        { title: sprintf(_('Edit %s'), _('Product')), path: 'edit/:id', element: <EditProduct /> }
                      ]
                    },
                    {
                      title: '',
                      path: 'draws',
                      children: [
                        { title: sprintf(_('Create %s'), _('Draw')), path: 'new', element: <CreateDraw /> },
                        { title: sprintf(_('Edit %s'), _('Draw')), path: 'edit/:id', element: <EditDraw /> }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              title: _('Coupons'),
              subheader: _('Admin'),
              icon: ICONS.coupons,
              access: 'admin' as IAccessType, //
              path: 'coupons',
              element: <Coupons />,
              index: true
            },
            {
              title: _('Users'),
              subheader: _('General'),
              icon: ICONS.user,
              access: 'admin' as IAccessType, //
              path: 'users',
              children: [
                { path: '', element: <UsersList />, index: true },
                { title: sprintf(_('Create %s'), _('User')), path: 'new', element: <UserCreate /> },
                { title: sprintf(_('Edit %s'), _('User')), path: 'edit/:id', element: <UserCreate /> }
              ]
            },
            {
              title: _('Settings'),
              subheader: _('Admin'),
              icon: ICONS.gear,
              access: 'admin' as IAccessType, //
              path: 'settings',
              element: <EditSettings />,
              index: true
            }
          ]
        }
      ]
    },
    {
      path: 'app',
      element: (
        <AuthGuard>
          <LogoOnlyLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'brands',
          children: [
            { path: 'select', element: <SelectBrand /> },
            { path: 'register', element: <RegisterBrand /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'policy', element: <Policy /> },
        { path: 'terms', element: <Policy /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [{ element: <Navigate to="/auth/login" replace />, index: true }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]
}

export interface ILink {
  href?: string
  name: string
  icon?: JSX.Element
}

interface INav {
  subheader: string
  items: INavItem[]
}

interface INavItem {
  title: string
  path: string
  icon?: JSX.Element
  links?: ILink[]
}

function getNavConfig(access: IAccessType, _: i18n['t']): INav[] {
  const navs: Record<string, INavItem[]> = {}
  const findNavs = (routes: any[], path: string = '') => {
    const getPath = ($path: string) => `${path}/${$path}`
    routes.forEach((route) => {
      if (route.subheader) {
        if (route.access && route.access !== 'all' && route.access !== access) return
        if (!navs[route.subheader]) navs[route.subheader] = []
        navs[route.subheader].push({
          title: route.title,
          path: getPath(route.path),
          icon: route.icon
        })
      }
      if (route.children && route.children.length) {
        findNavs(route.children, getPath(route.path))
      }
    })
  }
  findNavs(getRoutes(_))
  return Object.entries(navs).map(([subheader, items]) => ({ subheader, items }))
}

function getLinks(params: Readonly<Params>, _: i18n['t']) {
  const links: Record<string, INavItem> = {}
  const fillNavs = (routes: any[], path: string = '') => {
    const getPath = ($path: string) => {
      $path = `${path}/${$path}`.replace(/\/\//g, '/')
      $path = $path.replace(/\/:([a-zA-Z0-9._-]*)/g, ($0, $1) => (params[$1] ? `/${params[$1]}` : $0))
      return $path
    }
    routes.forEach((route) => {
      const $path = getPath(route.path)
      if (!$path.endsWith('/') && !links[$path]) links[$path] = route
      if (route.children && route.children.length) {
        fillNavs(route.children, getPath(route.path))
      }
    })
  }
  fillNavs(getRoutes(_))
  return links
}

/**
 * HOOKS
 */

export function useNavConfig() {
  const { translate: _ } = useLocales()
  const isAdmin = useIsAdmin()
  return useMemo(() => {
    return getNavConfig(isAdmin ? 'admin' : 'user', _)
  }, [_, isAdmin])
}

export function useBreadCrumbs(): INavItem | undefined {
  const { translate: _ } = useLocales()
  const params = useParams()
  const { pathname } = useLocation()
  return useMemo(() => {
    const routes = getLinks(params, _)
    const link = routes[pathname]
    if (!link) return undefined
    const links: ILink[] = [{ name: link.title, icon: link.icon }]
    const $links = pathname.split('/')
    $links.pop()
    while ($links.length) {
      const $pathname = $links.join('/')
      if (routes[$pathname]) {
        const $link = routes[$pathname]
        if ($link.title !== '') {
          links.unshift({
            name: $link.title || '-',
            // icon: $link.icon,
            href: $pathname
          })
        }
      }
      $links.pop()
    }
    return {
      title: link.title || '-',
      path: pathname,
      // icon: link.icon,
      links
    }
  }, [_, params, pathname])
}

export function Router() {
  const { translate: _ } = useLocales()
  useEffect(() => apiAuthCheckUser(), [])
  return useRoutes(getRoutes(_))
}

// IMPORT COMPONENTS

function Loadable(Component: LazyExoticComponent<() => JSX.Element>) {
  return function SubLoadable(props: any) {
    const { pathname } = useLocation()
    return (
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
        <Component {...props} />
      </Suspense>
    )
  }
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login').then((module) => ({ default: module.Login }))))
const Register = Loadable(lazy(() => import('../pages/auth/Register').then((module) => ({ default: module.Register }))))
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword').then((module) => ({ default: module.ResetPassword }))))
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode').then((module) => ({ default: module.VerifyCode }))))
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp').then((module) => ({ default: module.GeneralApp }))))
// Products
const SelectBrand = Loadable(lazy(() => import('../pages/app/SelectBrand').then((module) => ({ default: module.SelectionBrand }))))
const RegisterBrand = Loadable(lazy(() => import('../pages/app/NewBrand').then((module) => ({ default: module.NewBrand }))))
const ProductList = Loadable(lazy(() => import('../pages/app/products/ProductList').then((module) => ({ default: module.ProductList }))))
const CreateProduct = Loadable(lazy(() => import('../pages/app/products/ProductCreate').then((module) => ({ default: module.ProductCreate }))))
const EditProduct = Loadable(lazy(() => import('../pages/app/products/ProductEdit').then((module) => ({ default: module.ProductEdit }))))
// Draws
const DrawList = Loadable(lazy(() => import('../pages/app/draws/DrawsList').then((module) => ({ default: module.DrawsList }))))
const CreateDraw = Loadable(lazy(() => import('../pages/app/draws/DrawCreate').then((module) => ({ default: module.DrawCreate }))))
const EditDraw = Loadable(lazy(() => import('../pages/app/draws/DrawEdit').then((module) => ({ default: module.DrawEdit }))))
// Admin
const Brands = Loadable(lazy(() => import('../pages/admin/BrandList').then((module) => ({ default: module.BrandList }))))
const EditBrand = Loadable(lazy(() => import('../pages/admin/BrandEdit').then((module) => ({ default: module.BrandEdit }))))
const UsersList = Loadable(lazy(() => import('../pages/admin/users/UsersList').then((module) => ({ default: module.UsersList }))))
const UsersCreate = Loadable(lazy(() => import('../pages/admin/users/UserCreate').then((module) => ({ default: module.UserCreate }))))
const Coupons = Loadable(lazy(() => import('../pages/admin/Coupons').then((module) => ({ default: module.Coupons }))))
const EditSettings = Loadable(lazy(() => import('../pages/admin/Settings').then((module) => ({ default: module.EditSettings }))))
// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon').then((module) => ({ default: module.ComingSoon }))))
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance').then((module) => ({ default: module.Maintenance }))))
const Billing = Loadable(lazy(() => import('../pages/dashboard/Billing').then((module) => ({ default: module.Billing }))))
const Pricing = Loadable(lazy(() => import('../pages/Pricing').then((module) => ({ default: module.Pricing }))))
const Policy = Loadable(lazy(() => import('../pages/Policy').then((module) => ({ default: module.Policy }))))
const Payment = Loadable(lazy(() => import('../pages/Payment').then((module) => ({ default: module.Payment }))))
const Page500 = Loadable(lazy(() => import('../pages/Page500').then((module) => ({ default: module.Page500 }))))
const Page404 = Loadable(lazy(() => import('../pages/Page404').then((module) => ({ default: module.Page404 }))))

const getIcon = (name: SvgIconTypes) => <SvgIconStyle src={name} sx={{ width: 1, height: 1 }} />
export const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  mug: getIcon('ic_mug'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  draws: getIcon('ic_booking'),
  invoce: getIcon('ic_invoice'),
  coupons: getIcon('ic_booking'),
  gear: getIcon('ic_gear'),
  android: getIcon('ic_android'),
  iphone: getIcon('ic_apple'),
  apple: getIcon('ic_apple'),
  excel: getIcon('ic_excel')
}
