import React from 'react'
import type { ReactNode } from 'react'
import ReactQuill from 'react-quill'
import type { ReactQuillProps } from 'react-quill'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import EditorToolbar, { formats, redoChange, undoChange } from './EditorToolbar'
import type { SxProps } from '@mui/system'
import useLocales from '@/hooks/useLocales'

const RootStyle = styled(Box)(({ theme: $theme }) => {
  const theme = $theme as unknown as ITheme
  return {
    borderRadius: theme.shape.borderRadius,
    border: `solid 1px ${theme.palette.grey[500_32]}`,
    '& .ql-container.ql-snow': {
      borderColor: 'transparent',
      ...theme.typography.body1,
      fontFamily: theme.typography.fontFamily
    },
    '& .ql-editor': {
      minHeight: 200,
      '&.ql-blank::before': {
        fontStyle: 'normal',
        color: theme.palette.text.disabled
      },
      '& pre.ql-syntax': {
        ...theme.typography.body2,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[900]
      }
    }
  }
})

interface Props extends ReactQuillProps {
  id: string
  value: string
  onChange: (event?: any) => void
  error?: boolean
  helperText?: ReactNode
  simple?: boolean
  sx?: SxProps
}

export default function Editor({ id = 'minimal-quill', error, value, onChange, simple = false, helperText, sx, ...other }: Props) {
  const { translate: _ } = useLocales()
  const modules = {
    toolbar: {
      container: `#${id}`,
      handlers: {
        undo: undoChange,
        redo: redoChange
      }
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true
    },
    syntax: true,
    clipboard: {
      matchVisual: false
    }
  }

  return (
    <div>
      <RootStyle
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`
          }),
          ...sx
        }}>
        <EditorToolbar id={id} isSimple={simple} />
        <ReactQuill value={value} onChange={onChange} modules={modules} formats={formats} placeholder={_('Write something awesome') + '...'} {...other} />
      </RootStyle>

      {helperText && helperText}
    </div>
  )
}
