import React, { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box, Link, Typography, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { PATH_APP } from '@/routes/paths'
import MyAvatar from '../../../components/MyAvatar'
import { selectAuthBrand } from '@/redux/auth'
import { dispatch } from '@/redux'
import { setBrandStore } from '@/redux/brand'

export const RootStyle = styled('div')(({ theme: $theme }) => {
  const theme = $theme as unknown as ITheme
  return {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create?.('opacity', {
      duration: theme.transitions.duration?.shorter
    })
  }
})

interface NavbarAccountProps {
  isCollapse?: boolean
}

export default function NavbarAccount({ isCollapse }: NavbarAccountProps) {
  const brand = useSelector(selectAuthBrand)

  useEffect(() => {
    dispatch(setBrandStore(brand))
  }, [brand])

  return (
    <>
      {brand && (
        <Link underline="none" color="inherit" component={RouterLink} to={PATH_APP.brand.select}>
          <RootStyle
            sx={{
              ...(isCollapse && {
                bgcolor: 'transparent'
              })
            }}>
            <MyAvatar src={brand?.photo} />

            <Box
              sx={{
                overflow: 'hidden',
                width: '100%',
                ml: 2,
                transition: (theme) =>
                  theme.transitions.create('width', {
                    duration: theme.transitions.duration.shorter
                  }),
                ...(isCollapse && {
                  ml: 0,
                  width: 0
                })
              }}>
              <Stack sx={{ maxWidth: { xs: 150, md: '100%' } }}>
                <Typography variant="subtitle2" noWrap>
                  {brand?.name}
                </Typography>
                <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                  {brand?.ecommerce}
                </Typography>
              </Stack>
            </Box>
          </RootStyle>
        </Link>
      )}
    </>
  )
}
