import { createAction, createSlice } from '@reduxjs/toolkit'
import sum from 'lodash/sum'
import uniqBy from 'lodash/uniqBy'
import { BaseApi } from '@/api'
import { apiBrandGetId } from '@/redux/brand'
import { setLoading, setLoadingAsync } from '@/redux/reaction'
import { createSelector } from 'reselect'
import type { DTO_200_Product } from '@/api/models'

export type IProductStateSortBy = 'featured' | 'newest' | 'priceDesc' | 'priceAsc'
export interface IProductState {
  products: DTO_200_Product[]
  photos?: any[]
  sortBy?: IProductStateSortBy
  filters: {
    colors: string[]
    priceRange: string
    rating: string
  }
  checkout: {
    activeStep: number
    cart: IProductCart[]
    subtotal: number
    total: number
    discount: number
    shipping: number
    billing?: IBilling
  }
}
export interface IProductCart {
  id: string
  price: number
  quantity: number
}

export interface IBilling {
  receiver: string
  fullAddress: string
  addressType: string
  phone: string
  isDefault: boolean
}

const getInitialState = (): IProductState => ({
  products: [],
  photos: undefined,
  sortBy: undefined,
  filters: {
    colors: [],
    priceRange: '',
    rating: ''
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: undefined
  }
})

export const setProductInitState = createAction('SET_PRODUCT_INIT_STATE')
export const setProducts = createAction<DTO_200_Product[]>('SET_PRODUCTS')
export const setProduct = createAction<DTO_200_Product>('SET_PRODUCT')
export const setProductDelete = createAction<string>('SET_PRODUCT_DELETE')
export const setProductPhotos = createAction<string[]>('SET_PRODUCT_PHOTOS')
export const setProductSort = createAction<IProductStateSortBy>('SET_PRODUCT_SORT')
export const setProductFilter = createAction<IProductState['filters']>('SET_PRODUCT_FILTER')
export const setProductCheckout = createAction<IProductCart[]>('SET_PRODUCT_CHECKOUT')
export const setProductCart = createAction<IProductCart>('SET_PRODUCT_CART')
export const setProductCartDelete = createAction<string>('SET_PRODUCT_CART_DELETE')
export const setProductCartReset = createAction('SET_PRODUCT_CART_RESET')
export const setProductCartBack = createAction('SET_PRODUCT_CART_BACK')
export const setProductCartNext = createAction('SET_PRODUCT_CART_NEXT')
export const setProductCartStep = createAction<number>('SET_PRODUCT_CART_STEP')
export const setProductIncreaseQuantity = createAction<string>('SET_PRODUCT_CART_INCREASE_QUANTITY')
export const setProductDecreaseQuantity = createAction<string>('SET_PRODUCT_CART_DECREASE_QUANTITY')
export const setProductBilling = createAction<IBilling | undefined>('SET_PRODUCT_BILLING')
export const setProductDiscount = createAction<number>('SET_PRODUCT_DISCOUNT')
export const setProductShipping = createAction<number>('SET_PRODUCT_SHIPPING')

const slice = createSlice({
  name: 'product',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setProductInitState, (state) => {
        Object.assign(state, getInitialState())
      })
      .addCase(setProducts, (state, { payload }) => {
        setLoadingAsync(false)
        state.products = payload
      })
      .addCase(setProduct, (state, { payload }) => {
        setLoadingAsync(false)
        const idx = state.products.findIndex((p) => p._id === payload._id)
        if (idx > -1) state.products[idx] = payload
        else state.products.push(payload)
      })
      .addCase(setProductDelete, (state, { payload }) => {
        setLoadingAsync(false)
        const idx = state.products.findIndex((p) => p._id === payload)
        if (idx > -1) state.products.splice(idx, 1)
      })
      .addCase(setProductPhotos, (state, { payload }) => {
        setLoadingAsync(false)
        state.photos = payload
      })
      .addCase(setProductSort, (state, { payload }) => {
        state.sortBy = payload
      })
      .addCase(setProductFilter, (state, { payload }) => {
        state.filters.colors = payload.colors
        state.filters.priceRange = payload.priceRange
        state.filters.rating = payload.rating
      })
      .addCase(setProductCheckout, (state, { payload: cart }) => {
        const subtotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity))
        const discount = cart.length === 0 ? 0 : state.checkout.discount
        const shipping = cart.length === 0 ? 0 : state.checkout.shipping
        const billing = cart.length === 0 ? undefined : state.checkout.billing

        state.checkout.cart = cart
        state.checkout.discount = discount
        state.checkout.shipping = shipping
        state.checkout.billing = billing
        state.checkout.subtotal = subtotal
        state.checkout.total = subtotal - discount
      })
      .addCase(setProductCart, (state, { payload: product }) => {
        const isEmptyCart = state.checkout.cart.length === 0

        if (isEmptyCart) {
          state.checkout.cart = [...state.checkout.cart, product]
        } else {
          state.checkout.cart = state.checkout.cart.map((_product) => {
            const isExisted = _product.id === product.id
            if (isExisted) {
              return {
                ..._product,
                quantity: _product.quantity + 1
              }
            }
            return _product
          })
        }
        state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id')
      })
      .addCase(setProductCartDelete, (state, { payload }) => {
        state.checkout.cart = state.checkout.cart.filter((item) => item.id !== payload)
      })
      .addCase(setProductCartReset, (state) => {
        Object.assign(state.checkout, getInitialState().checkout)
      })
      .addCase(setProductCartBack, (state) => {
        state.checkout.activeStep -= 1
      })
      .addCase(setProductCartNext, (state) => {
        state.checkout.activeStep += 1
      })
      .addCase(setProductCartStep, (state, { payload }) => {
        state.checkout.activeStep = payload
      })
      .addCase(setProductIncreaseQuantity, (state, { payload: productId }) => {
        state.checkout.cart = state.checkout.cart.map((product) => {
          if (product.id === productId) {
            return {
              ...product,
              quantity: product.quantity + 1
            }
          }
          return product
        })
      })
      .addCase(setProductDecreaseQuantity, (state, { payload: productId }) => {
        state.checkout.cart = state.checkout.cart.map((product) => {
          if (product.id === productId) {
            return {
              ...product,
              quantity: product.quantity - 1
            }
          }
          return product
        })
      })
      .addCase(setProductBilling, (state, { payload }) => {
        state.checkout.billing = payload
      })
      .addCase(setProductDiscount, (state, { payload: discount }) => {
        state.checkout.discount = discount
        state.checkout.total = state.checkout.subtotal - discount
      })
      .addCase(setProductShipping, (state, { payload: shipping }) => {
        state.checkout.shipping = shipping
        state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping
      })
  }
})

// Reducer
export const productReducer = slice.reducer
export const productDispatch: { dispatch?: Function } = {}

/**
 * SELECTORS
 */
export const selectProducts = createSelector([(state: IRootState) => state.product.products], (products) => products)
export const selectProduct = (_id?: string) => createSelector([(state: IRootState) => (_id ? state.product.products.find((p) => p._id === _id) : undefined)], (product) => product)
export const selectProductSortBy = createSelector([(state: IRootState) => state.product.sortBy], (sortBy) => sortBy)
export const selectProductFilters = createSelector([(state: IRootState) => state.product.filters], (filters) => filters)
export const selectProductCheckout = createSelector([(state: IRootState) => state.product.checkout], (checkout) => checkout)

/**
 * APIs
 */
export async function apiProductsGet(brandId?: string) {
  const { dispatch } = productDispatch
  setLoading(true)
  try {
    if (brandId) localStorage.setItem('brandIdToken', brandId)
    const brand = apiBrandGetId()
    const response = await BaseApi.get('/api/v1/products', { 'x-business': brand || '' }, undefined)
    dispatch?.(setProducts(response.data.products))
  } catch (error) {
    setLoading(false)
    throw error
  }
}

export async function apiProductGetCouponsPending() {
  const { dispatch } = productDispatch
  setLoading(true)
  try {
    const response = await BaseApi.get('/api/v1/admin/coupons/pending', undefined, undefined)
    dispatch?.(
      setProducts(
        response.data.products.map((p) => {
          ;(p as DTO_200_Product).brand = p.brand?._id
          return p
        }) as DTO_200_Product[]
      )
    )
  } catch (error) {
    setLoading(false)
    console.error(error)
    throw error
  }
}

export async function apiProductApprove(id: string) {
  try {
    const response = await BaseApi.post(`/api/v1/admin/product/{id}/approve`, undefined, undefined, { id })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function apiProductReject(id: string, observation: any) {
  try {
    const response = await BaseApi.post(`/api/v1/admin/product/{id}/reject`, { observation }, undefined, { id })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function apiProductApproveCoupon(id: string) {
  try {
    const response = await BaseApi.post(`/api/v1/admin/coupons/{id}/approve`, undefined, undefined, { id })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function apiProductRejectCoupon(id: string) {
  try {
    const response = await BaseApi.post(`/api/v1/admin/coupons/{id}/reject`, undefined, undefined, { id })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function apiProductAddCoupon(id: string, coupons: any) {
  try {
    const response = await BaseApi.put(`/api/v1/products/{id}/coupons`, { coupons }, undefined, { id })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function apiProductCreate(body: Partial<DTO_200_Product>) {
  const { dispatch } = productDispatch
  setLoading(true)
  try {
    const { cost = 0, description, name = '', photos, sku, url = '', coupons } = body
    const brand = apiBrandGetId()!
    const response = await BaseApi.post('/api/v1/products', { brand, cost, description, name, photos, sku, url, coupons: coupons?.map((c) => c.code) }, { 'x-business': brand || '' }, undefined)
    setLoading(false)
    dispatch?.(setProduct(response.data))
    return response.data as DTO_200_Product
  } catch (error) {
    console.error(error)
    setLoading(false)
    throw error
  }
}

export async function apiProductEdit(id: string, body: Partial<DTO_200_Product>) {
  const { dispatch } = productDispatch
  setLoading(true)
  try {
    const brand = apiBrandGetId() || undefined
    const { name, description, sku, url, cost, photos, coupons } = body
    const response = await BaseApi.put(`/api/v1/products/{id}`, { brand, name, description, sku, url, cost, photos, coupons: coupons?.map((c) => c.code) }, undefined, { id })
    setLoading(false)
    dispatch?.(setProduct(response.data))
    return response.data as DTO_200_Product
  } catch (error) {
    console.error(error)
    setLoading(false)
    throw error
  }
}

export async function apiProductDelete(id: string) {
  const { dispatch } = productDispatch
  setLoading(true)
  try {
    const response = await BaseApi.delete(`/api/v1/products/{id}`, undefined, { id })
    setLoading(false)
    dispatch?.(setProductDelete(id))
    return response.data
  } catch (error) {
    console.error(error)
    setLoading(false)
    throw error
  }
}

export async function apiProductUploadPhoto(photos: File[]): Promise<string[] | undefined> {
  const { dispatch } = productDispatch
  try {
    const brand = apiBrandGetId()
    const data = new FormData()
    photos.forEach((image) => {
      data.append('photos[]', image)
    })
    data.append('brand', brand || '')
    const response = await BaseApi.post('/api/v1/products/upload', data, { 'Content-Type': 'multipart/form-data' }, undefined)
    dispatch?.(setProductPhotos(response.data.urls))
    return response.data.urls
  } catch (error) {
    console.error(error)
    setLoading(false)
    throw error
  }
}
