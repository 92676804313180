import { createSlice } from '@reduxjs/toolkit'
import { BaseApi } from '@/api'
import { dispatch } from '@/redux/index'
import { apiBrandGetId } from '@/redux/brand'

const initialState: IPayments = {
  isLoading: false,
  error: null,
  plans: [],
  transactions: [],
  checkout: {
    planId: '',
    plan: null,
    amount: 0,
    urlInit: '',
    status: null,
    success: false,
    url: ''
  }
}

interface IPayments {
  isLoading: boolean
  error: Error | null
  plans: any[]
  transactions: any[]
  checkout: {
    planId: string
    plan: any | null
    amount: number
    urlInit: string
    status: any
    success: boolean
    url: string
  }
}

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET PLANS
    getPlansSuccess(state, action) {
      state.isLoading = false
      state.plans = action.payload
    },

    // CHECKOUT
    getCheckoutSuccess(state, action) {
      state.isLoading = false
      state.checkout = {
        ...state.checkout,
        ...action.payload,
        success: true
      }
    },

    // CHECKOUT
    setCheckoutPlanId(state, action) {
      state.checkout.planId = action.payload
      const plan = state.plans.find(($plan) => $plan._id === action.payload)
      state.checkout.plan = plan.name
      state.checkout.amount = plan.amount
    },

    // GET TRANSACTIONS
    getTransactionsSuccess(state, action) {
      state.isLoading = false
      state.transactions = action.payload
    },

    setCheckoutStatus(state, action) {
      state.checkout.status = action.payload
    }
  }
})

// Reducer
export const paymentReducer = slice.reducer

// Actions
export const { setCheckoutPlanId, setCheckoutStatus } = slice.actions
export function getPlans() {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      // TODO: const brand = apiBrandGetId()
      // TODO: const response = await BaseApi.get('/api/v1/payment/plan?country=CL', { headers: { 'x-business': brand || '' } })
      const response = { data: { plans: [] } }
      dispatch(slice.actions.getPlansSuccess(response.data.plans))
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function postCheckout(planId: string) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      // TODO: const brand = apiBrandGetId()
      // TODO: const response = await BaseApi.post('/api/v1/payment?country=CL', { planId }, { headers: { 'x-business': brand || '' } })
      const response = { data: { data: {} } }
      const { data } = response.data
      dispatch(slice.actions.getCheckoutSuccess({ ...data }))
      return data
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getTransactions() {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const brand = apiBrandGetId()
      const response = await BaseApi.get('/api/v1/payment/transactions', undefined, undefined)
      const { transactions } = response.data
      dispatch(slice.actions.getTransactionsSuccess(transactions))
      return transactions
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    }
  }
}
