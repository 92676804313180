import React from 'react'
import { m } from 'framer-motion'
import { alpha, styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import Logo from './Logo'
import ProgressBar from './ProgressBar'

const RootStyle = styled('div')<{ opacity?: number }>(({ opacity = 1 }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transitionDuration: '500ms',
  opacity
  // visibility: opacity === 1 ? 'visible' : 'hidden'
  // backgroundColor: theme.palette.background.default
}))

const BgStyle = styled('div')<{ opacity?: number }>(({ theme, opacity = 1 }) => ({
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity,
  backgroundColor: theme.palette.background.default
}))

interface Props {
  isDashboard?: boolean
  opacity?: number
  bgOpacity?: number
}

export default function LoadingScreen({ isDashboard, opacity = 1, bgOpacity = 1, ...other }: Props) {
  return (
    <>
      <ProgressBar />
      {!isDashboard ? (
        <RootStyle {...other} opacity={opacity}>
          <BgStyle {...other} opacity={bgOpacity} />
          <m.div
            initial={{ rotateY: 0 }}
            animate={{ rotateY: 360 }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeatDelay: 1,
              repeat: Infinity
            }}>
            <Logo disabledLink sx={{ width: 64, height: 64 }} />
          </m.div>
          <Box
            component={m.div as React.ElementType}
            animate={{
              scale: [1.2, 1, 1, 1.2, 1.2],
              rotate: [270, 0, 0, 270, 270],
              opacity: [0.25, 1, 1, 1, 0.25],
              borderRadius: ['25%', '25%', '50%', '50%', '25%']
            }}
            transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
            sx={{
              width: 100,
              height: 100,
              borderRadius: '25%',
              position: 'absolute',
              border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`
            }}
          />
          <Box
            component={m.div as React.ElementType}
            animate={{
              scale: [1, 1.2, 1.2, 1, 1],
              rotate: [0, 270, 270, 0, 0],
              opacity: [1, 0.25, 0.25, 0.25, 1],
              borderRadius: ['25%', '25%', '50%', '50%', '25%']
            }}
            transition={{
              ease: 'linear',
              duration: 3.2,
              repeat: Infinity
            }}
            sx={{
              width: 120,
              height: 120,
              borderRadius: '25%',
              position: 'absolute',
              border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`
            }}
          />
        </RootStyle>
      ) : null}
    </>
  )
}
