import { createSlice } from '@reduxjs/toolkit'
import { BaseApi } from '@/api'
import { dispatch } from '@/redux'

const initialState: ICalendarState = {
  isLoading: false,
  error: null,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null
}

interface ICalendarState {
  isLoading: boolean
  error: Error | null
  events: any[]
  isOpenModal: boolean
  selectedEventId: string | null
  selectedRange: { start: Date; end: Date } | null
}

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false
      state.events = action.payload
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload
      state.isLoading = false
      state.events = [...state.events, newEvent]
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload
      const $updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event
        }
        return _event
      })

      state.isLoading = false
      state.events = $updateEvent
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload
      state.events = state.events.filter((event) => event.id !== eventId)
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload
      state.isOpenModal = true
      state.selectedEventId = eventId
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload
      state.isOpenModal = true
      state.selectedRange = { start, end }
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false
      state.selectedEventId = null
      state.selectedRange = null
    }
  }
})

// Reducer
export const calendarReducer = slice.reducer

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions

export function getEvents() {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      // TODO: const response = await BaseApi.get('/api/calendar/events')
      const response = { data: { events: [] } } as any
      dispatch(slice.actions.getEventsSuccess(response.data.events))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function createEvent(newEvent: any) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      // TODO: const response = await BaseApi.post('/api/calendar/events/new', newEvent)
      const response = { data: { event: {} } } as any
      dispatch(slice.actions.createEventSuccess(response.data.event))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function updateEvent(eventId: string, $updateEvent: any) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      // const response = await BaseApi.post('/api/calendar/events/update', { eventId, updateEvent: $updateEvent })
      const response = { data: { event: {} } } as any
      dispatch(slice.actions.updateEventSuccess(response.data.event))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function deleteEvent(eventId: string) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      // TODO: await api.post('/api/calendar/events/delete', { eventId })
      dispatch(slice.actions.deleteEventSuccess({ eventId }))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function selectRange(start: Date, end: Date) {
  return async () => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    )
  }
}
