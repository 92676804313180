import { createAction, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { BaseApi } from '@/api'
import { setLoading, useIsLoading } from '@/redux/reaction'
import { API_HOST } from '@/config'
import { useSelector } from 'react-redux'
import { useCallback, useContext } from 'react'
import { FacebookContext } from '@/contexts/FacebookContext'
import type { persistStore } from 'redux-persist'
import { useNavigate } from 'react-router-dom'
import type { NavigateFunction } from 'react-router-dom'
import type { AxiosResponse } from 'axios'
import { dispatch } from '@/redux/index'
import type { DTO_200_UserData, DTO_200_UserPopulated } from '@/api/models'

export interface IUserData {
  displayName?: string
  email?: string
  photoURL?: string
  phoneNumber?: string
  country?: string
  address?: string
  state?: string
  city?: string
  zipCode?: string
  about?: string
  isPublic?: boolean
}

interface IAuthState {
  user?: DTO_200_UserPopulated
  brandId?: string
  userBrandId?: string
}

const getInitialState = (): IAuthState => ({
  user: undefined,
  brandId: undefined,
  userBrandId: undefined
})

export const setAuthInitState = createAction('SET_AUTH_INIT_STATE')
export const setAuthUser = createAction<Partial<DTO_200_UserPopulated> | undefined>('SET_AUTH_USER')
export const setAuthBrandId = createAction<string | undefined>('SET_AUTH_BRAND_ID')
export const setAuthUserBrandId = createAction<string | undefined>('SET_AUTH_USER_BRAND_ID')

const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setAuthInitState, (state) => {
        Object.assign(state, getInitialState())
      })
      .addCase(setAuthUser, (state, { payload }) => {
        if (!state.user || payload === undefined) state.user = payload as DTO_200_UserPopulated
        else state.user = { ...state.user, ...payload }
      })
      .addCase(setAuthBrandId, (state, { payload }) => {
        if (payload) localStorage.setItem('brandIdToken', payload)
        state.brandId = payload
      })
      .addCase(setAuthUserBrandId, (state, { payload }) => {
        state.userBrandId = payload
      })
      .addDefaultCase(() => {
        //
      })
  }
})

/**
 * SELECTORS
 */

export const selectAuthBrand = createSelector(
  [
    (store: IRootState) => {
      let brand = store.auth.user?.brands?.find((b) => store.auth.brandId === b._id)
      if (!brand && store.auth.user?.brands?.length) {
        brand = store.auth.user?.brands[0]
        if (brand) {
          setTimeout(() => {
            const { dispatch } = authDispatch
            dispatch?.(setAuthBrandId(brand?._id))
          }, 100)
        }
      }
      return brand
    }
  ],
  (data) => data
)
export const selectAuthUser = createSelector([(store: IRootState) => store.auth.user as DTO_200_UserPopulated | undefined], (data) => data)
export const selectAuthUserBrandId = createSelector([(store: IRootState) => store.auth.userBrandId], (data) => data)
export const selectAuthUserData = createSelector([(store: IRootState) => store.auth.user as DTO_200_UserPopulated | undefined], (user) => {
  return {
    displayName: `${user?.name || ''}${user?.lastName ? ' ' + user.lastName : ''}`,
    email: user?.email || '',
    photoURL: user?.photo || '',
    phoneNumber: user?.userData?.phoneNumber || '',
    country: user?.userData?.country || '',
    address: user?.userData?.address || '',
    state: user?.userData?.state || '',
    city: user?.userData?.city || '',
    zipCode: user?.userData?.zipCode || '',
    about: user?.userData?.about || '',
    isPublic: !!user?.userData?.isPublic
  } as IUserData
})
export const authReducer = authSlice.reducer
export const authDispatch: { dispatch?: Function; persistor?: ReturnType<typeof persistStore> } = {}

/**
 * APIs
 */
export function apiAuthLogout() {
  setLoading(true)
  localStorage.removeItem('uid')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('brandIdToken')
  authDispatch.persistor
    ?.purge()
    .then(() => {
      authDispatch.dispatch?.(setAuthUser(undefined))
      setLoading(false)
      setTimeout(() => {
        $navigate?.('/auth/login')
      }, 100)
    })
    .catch((e) => console.error(e))
}
export function apiAuthCheckUser() {
  setTimeout(async () => {
    try {
      const token = localStorage.getItem('accessToken')
      if (!token) return
      const { dispatch } = authDispatch

      const response = await BaseApi.get('/api/v1/users/profile', undefined, undefined)
      if (response.status === 200) {
        const { data } = response
        dispatch?.(setAuthUser(data))
      }
    } catch (e) {
      const err = e as Error & { status: number }
      if (err.status === 404) apiAuthLogout()
      console.error(e)
    }
  }, 0)
}

export async function apiAuthLoadScript(key: string, url: string) {
  await new Promise((res, rej) => {
    const fb = document.getElementById(key)
    if (fb) return res(true)
    const script = document.createElement('script')
    script.setAttribute('id', key)
    script.setAttribute('src', url)
    script.addEventListener('load', () => res(true))
    script.addEventListener('error', (e) => rej(e))
    document.body.appendChild(script)
  })
}

function processLogin(response: AxiosResponse<DTO_200_UserData>) {
  const { data } = response
  if (data?.uid && data?.user && data?.token) {
    if (data.user.role !== 'admin' && data.user.brands?.length) localStorage.setItem('brandIdToken', data.user.brands[0]._id || '')
    localStorage.setItem('uid', data.uid)
    localStorage.setItem('accessToken', data.token)
    authDispatch.dispatch?.(setAuthUser(data.user))
    return data.user
  }
  return response.statusText
}

export async function apiAuthFbLogin(): Promise<DTO_200_UserPopulated | string> {
  try {
    const token: string = await new Promise((res, rej) => {
      FB.login(function (loginRes: any) {
        if (loginRes.authResponse) res(loginRes.authResponse.accessToken)
        else rej('User cancelled login or did not fully authorize.')
      })
    })
    setLoading(true)
    const response = await BaseApi.post('/api/v3/user/facebook', { token }, { deviceid: undefined }, undefined)
    setLoading(false)
    return processLogin(response)
  } catch (e) {
    return (e as Error).message
  }
}

export const GoogleAuth = {
  client_id: '948458644970-gn873b9rq530hmf7fa1b69c7uaj3irm2.apps.googleusercontent.com',
  login_uri: `${API_HOST}/api/v3/user/google`
}

export async function apiAuthGoogleLogin(): Promise<DTO_200_UserPopulated | string> {
  try {
    await apiAuthLoadScript('google-api', 'https://accounts.google.com/gsi/client')
    const jwt: string = await new Promise((res, rej) => {
      const { google } = window as any
      const { client_id, login_uri } = GoogleAuth
      google.accounts.id.initialize({
        client_id,
        callback({ credential }: { credential: string }) {
          if (credential) return res(credential)
          rej(new Error('invalid credentials'))
        },
        login_uri
      })
      google.accounts.id.renderButton(document.getElementById('google-login'), { theme: 'outline', size: 'large' })
      const callPrompt = () => {
        google.accounts.id.prompt((notification: any) => {
          let error: string | undefined
          if (notification.isNotDisplayed()) error = 'not displayed'
          if (notification.isSkippedMoment()) error = 'skipped moment'
          if (error) {
            document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`
            callPrompt()
          }
        })
      }
      callPrompt()
    })
    setLoading(true)
    const response = await BaseApi.post('/api/v3/user/google', { jwt }, { deviceid: undefined }, undefined)
    setLoading(false)
    return processLogin(response as AxiosResponse<DTO_200_UserData>)
  } catch (e) {
    return (e as Error).message
  }
}

export async function apiAuthLogin(email: string, password: string): Promise<DTO_200_UserPopulated | string> {
  try {
    setLoading(true)
    const response = await BaseApi.post('/api/v3/user/login', { email, password }, undefined, undefined)
    setLoading(false)
    return processLogin(response)
  } catch (error) {
    setLoading(false)
    throw error
  }
}

export async function apiAuthSignUp(email: string, password: string, name: string, lastName: string) {
  try {
    setLoading(true)
    const response = await BaseApi.post('/api/v3/user/signup', { email, password, name, lastName }, { deviceid: undefined }, undefined)
    setLoading(false)
    return response.data?.ok as boolean
  } catch (error) {
    setLoading(false)
    throw error
  }
}

export async function apiAuthPasswordRecovery(email: string) {
  try {
    setLoading(true)
    const response = await BaseApi.post('/api/v3/user/send-password-recovery-email', { email }, undefined, undefined)
    setLoading(false)
    return response.data?.ok as boolean
  } catch (error) {
    setLoading(false)
    throw error
  }
}

export interface ILoginOK {
  token: string
  uid: string
  user: DTO_200_UserPopulated
}

export async function apiAuthConfirmEmail(email: string, code: string): Promise<ILoginOK> {
  try {
    setLoading(true)
    const response = await BaseApi.post('/api/v3/user/confirm-email', { email, code }, undefined, undefined)
    setLoading(false)
    return response.data as ILoginOK
  } catch (error) {
    setLoading(false)
    throw error
  }
}

export async function apiAuthRecoverEmail(email: string, code: string, password: string): Promise<ILoginOK> {
  try {
    setLoading(true)
    const response = await BaseApi.post('/api/v3/user/update-password-and-login', { email, code, password }, undefined, undefined)
    setLoading(false)
    return response.data as ILoginOK
  } catch (error) {
    setLoading(false)
    throw error
  }
}

/**
 * REDUX
 */

export function useAuthRegister(): [(email: string, password: string, name: string, lastName: string) => Promise<boolean>, boolean] {
  const isLoading = useIsLoading()
  return [apiAuthSignUp, isLoading]
}

export function useAuthLogin(): [(email: string, password: string) => Promise<DTO_200_UserPopulated | string>, boolean] {
  const isLoading = useIsLoading()
  return [apiAuthLogin, isLoading]
}

export function useAuthUser() {
  const user = useSelector(selectAuthUser)
  if (user?.role !== 'admin' && user?.brands?.length) localStorage.setItem('brandIdToken', user.brands[0]._id || '')
  return user
}

export function useAuthUserData() {
  return useSelector(selectAuthUserData)
}

let $navigate: NavigateFunction | undefined
export function useLogout(): [() => void, IUserData | undefined] {
  const user = useAuthUserData()
  $navigate = useNavigate()
  return [apiAuthLogout, user]
}

export function useAuth() {
  const user = useAuthUser()
  if (user?.role !== 'admin' && user?.brands?.length) localStorage.setItem('brandIdToken', user.brands[0]._id || '')
  return { isAuthenticated: !!user, isInitialized: !!user, hasBrand: !!user?.brands?.length }
}

export function useAuthFacebook() {
  const context = useContext(FacebookContext)
  if (!context) throw new Error('Facebook context must be use inside FacebookProvider')
  return context
}

export function useIsAdmin() {
  const userBrandId = useSelector(selectAuthUserBrandId)
  return useAuthUser()?.role === 'admin' && !userBrandId
}

export function useViewAsUser() {
  const navigate = useNavigate()
  return useCallback(
    (brandId?: string) => {
      if (brandId) dispatch(setAuthBrandId(brandId))
      dispatch(setAuthUserBrandId(brandId))
      navigate('/dashboard/app')
    },
    [navigate]
  )
}
