import { createAction, createSlice } from '@reduxjs/toolkit'
import sum from 'lodash/sum'
import uniqBy from 'lodash/uniqBy'
import { BaseApi } from '@/api'
import { apiBrandGetId } from '@/redux/brand'
import { setLoadingAsync } from '@/redux/reaction'
import type { IBilling, IProductCart } from '@/redux/product'
import { createSelector } from 'reselect'
import type { DTO_200_DrawPopulated, DTO_200_Product, DTO_DrawCreate } from '@/api/models'

interface IDrawState {
  draws: DTO_200_DrawPopulated[]
  product?: DTO_200_Product
  sortBy?: string
  filters: {
    gender: string[]
    category: string
    colors: string[]
    priceRange: string
    rating: string
  }
  checkout: {
    activeStep: number
    cart: IProductCart[]
    subtotal: number
    total: number
    discount: number
    shipping: number
    billing?: IBilling
  }
}

const getInitialState = (): IDrawState => ({
  draws: [],
  product: undefined,
  sortBy: undefined,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: ''
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: undefined
  }
})

export const setDrawInitState = createAction('SET_DRAW_INIT_STATE')
export const setDraws = createAction<DTO_200_DrawPopulated[]>('SET_DRAWS')
export const setDraw = createAction<DTO_200_DrawPopulated>('SET_DRAW')
export const setDrawProduct = createAction<DTO_200_Product>('SET_DRAW_PRODUCT')
export const setDrawDelete = createAction<string>('SET_DRAW_DELETE')
export const setDrawDeletes = createAction<string[]>('SET_DRAW_DELETES')
export const setDrawSort = createAction<string>('SET_DRAW_SORT')
export const setDrawFilter = createAction<IDrawState['filters']>('SET_DRAW_FILTER')
export const setDrawCheckout = createAction<IProductCart[]>('SET_DRAW_CHECKOUT')
export const setDrawCart = createAction<IProductCart>('SET_DRAW_CART')
export const setDrawCartDelete = createAction<string>('SET_DRAW_CART_DELETE')
export const setDrawCartReset = createAction('SET_DRAW_CART_RESET')
export const setDrawCartBack = createAction('SET_DRAW_CART_BACK')
export const setDrawCartNext = createAction('SET_DRAW_CART_NEXT')
export const setDrawCartStep = createAction<number>('SET_DRAW_CART_STEP')
export const setDrawIncreaseQuantity = createAction<string>('SET_DRAW_CART_INCREASE_QUANTITY')
export const setDrawDecreaseQuantity = createAction<string>('SET_DRAW_CART_DECREASE_QUANTITY')
export const setDrawBilling = createAction<IBilling | undefined>('SET_DRAW_BILLING')
export const setDrawDiscount = createAction<number>('SET_DRAW_DISCOUNT')
export const setDrawShipping = createAction<number>('SET_DRAW_SHIPPING')

const slice = createSlice({
  name: 'draw',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setDrawInitState, (state) => {
        Object.assign(state, getInitialState())
      })
      .addCase(setDraws, (state, { payload }) => {
        setLoadingAsync(false)
        state.draws = payload
      })
      .addCase(setDraw, (state, { payload }) => {
        setLoadingAsync(false)
        const idx = state.draws.findIndex((p) => p._id === payload._id)
        if (idx > -1) state.draws[idx] = payload
        else state.draws.push(payload)
      })
      .addCase(setDrawDelete, (state, { payload }) => {
        setLoadingAsync(false)
        const idx = state.draws.findIndex((p) => p._id === payload)
        if (idx > -1) state.draws.splice(idx, 1)
      })
      .addCase(setDrawDeletes, (state, { payload }) => {
        setLoadingAsync(false)
        state.draws = state.draws.filter((d) => !payload.includes(d._id || ''))
      })
      .addCase(setDrawProduct, (state, { payload }) => {
        setLoadingAsync(false)
        state.product = payload
      })
      .addCase(setDrawSort, (state, { payload }) => {
        state.sortBy = payload
      })
      .addCase(setDrawFilter, (state, { payload }) => {
        state.filters.gender = payload.gender
        state.filters.category = payload.category
        state.filters.colors = payload.colors
        state.filters.priceRange = payload.priceRange
        state.filters.rating = payload.rating
      })
      .addCase(setDrawCheckout, (state, { payload: cart }) => {
        const subtotal = sum(cart.map((cartItem: any) => cartItem.price * cartItem.quantity))
        const discount = cart.length === 0 ? 0 : state.checkout.discount
        const shipping = cart.length === 0 ? 0 : state.checkout.shipping
        const billing = cart.length === 0 ? null : state.checkout.billing

        state.checkout.cart = cart
        state.checkout.discount = discount
        state.checkout.shipping = shipping
        state.checkout.billing = billing || undefined
        state.checkout.subtotal = subtotal
        state.checkout.total = subtotal - discount
      })
      .addCase(setDrawCart, (state, { payload: product }) => {
        const isEmptyCart = state.checkout.cart.length === 0

        if (isEmptyCart) {
          state.checkout.cart = [...state.checkout.cart, product]
        } else {
          state.checkout.cart = state.checkout.cart.map((_product) => {
            const isExisted = _product.id === product.id
            if (isExisted) {
              return {
                ..._product,
                quantity: _product.quantity + 1
              }
            }
            return _product
          })
        }
        state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id')
      })
      .addCase(setDrawCartDelete, (state, { payload }) => {
        state.checkout.cart = state.checkout.cart.filter((item) => item.id !== payload)
      })
      .addCase(setDrawCartReset, (state) => {
        Object.assign(state.checkout, getInitialState().checkout)
      })
      .addCase(setDrawCartBack, (state) => {
        state.checkout.activeStep -= 1
      })
      .addCase(setDrawCartNext, (state) => {
        state.checkout.activeStep += 1
      })
      .addCase(setDrawCartStep, (state, { payload }) => {
        state.checkout.activeStep = payload
      })
      .addCase(setDrawIncreaseQuantity, (state, { payload: productId }) => {
        state.checkout.cart = state.checkout.cart.map((product) => {
          if (product.id === productId) {
            return {
              ...product,
              quantity: product.quantity + 1
            }
          }
          return product
        })
      })
      .addCase(setDrawDecreaseQuantity, (state, { payload: productId }) => {
        state.checkout.cart = state.checkout.cart.map((product) => {
          if (product.id === productId) {
            return {
              ...product,
              quantity: product.quantity - 1
            }
          }
          return product
        })
      })
      .addCase(setDrawBilling, (state, { payload }) => {
        state.checkout.billing = payload
      })
      .addCase(setDrawDiscount, (state, { payload: discount }) => {
        state.checkout.discount = discount
        state.checkout.total = state.checkout.subtotal - discount
      })
      .addCase(setDrawShipping, (state, { payload: shipping }) => {
        state.checkout.shipping = shipping
        state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping
      })
  }
})

/**
 * REDUCERs
 */
export const drawReducer = slice.reducer
export const drawDispatch: { dispatch?: Function } = {}
export const selectDraws = createSelector([(store: IRootState) => store.draw.draws], (draws) => draws)
export const selectDraw = (id: string) => createSelector([(store: IRootState) => (!id ? undefined : store.draw.draws.find((d) => d._id === id))], (draw) => draw)

/**
 * APIs
 */
export async function apiDrawsGet() {
  const { dispatch } = drawDispatch
  setLoadingAsync(true)
  try {
    const brand = apiBrandGetId()
    const response = await BaseApi.get('/api/v1/draws', { 'x-business': brand || '' }, undefined)
    dispatch?.(setDraws(response.data.draws))
  } catch (error) {
    console.error(error)
    setLoadingAsync(false)
  }
}

export async function apiDrawCreate(data: Partial<DTO_200_DrawPopulated>): Promise<DTO_200_DrawPopulated> {
  setLoadingAsync(true)
  try {
    const brand = apiBrandGetId()!
    const { start, country = 'chile', isGlobal, prizes, chests, color, isFinished, end } = data
    const response = await BaseApi.post(
      '/api/v1/draws',
      {
        brand, //
        start: start!,
        country,
        isGlobal,
        states: [],
        prizes: prizes?.map((a) => ({ productId: a.product?._id || '', quantity: a.quantity! })),
        chests: chests?.map((a) => ({ productId: a.product?._id || '', quantity: a.quantity! })),
        color,
        isFinished,
        end
      },
      { 'x-business': brand || '' },
      undefined
    )
    drawDispatch.dispatch?.(setDraw(response.data))
    return response.data
  } catch (error) {
    setLoadingAsync(false)
    throw error
  }
}

export async function apiDrawEdit(id: string, body: Partial<DTO_200_DrawPopulated>) {
  setLoadingAsync(true)
  try {
    // const brand = apiBrandGetId()
    const { prizes, chests, color, isFinished, end } = body
    const response = await BaseApi.put(
      `/api/v1/draws/{id}`,
      {
        prizes: prizes?.map((a) => ({ productId: a.product?._id || '', quantity: a.quantity! })), //
        chests: chests?.map((a) => ({ productId: a.product?._id || '', quantity: a.quantity! })),
        color,
        isFinished,
        end
      },
      undefined,
      { id }
    )
    // drawDispatch.dispatch?.(setDrawProduct(response.data))
    drawDispatch.dispatch?.(setDraw(response.data))
    return response.data
  } catch (error) {
    console.error(error)
    setLoadingAsync(false)
    throw error
  }
}

export async function apiDrawDelete(id: string) {
  setLoadingAsync(true)
  try {
    // const brand = apiBrandGetId()
    await BaseApi.delete(`/api/v1/draws/{id}`, undefined, { id })
    drawDispatch.dispatch?.(setDrawDelete(id))
  } catch (error) {
    console.error(error)
    setLoadingAsync(false)
    throw error
  }
}

export async function apiDrawDeletes(ids: string[]) {
  setLoadingAsync(true)
  try {
    for (const id of ids) {
      await BaseApi.delete(`/api/v1/draws/{id}`, undefined, { id })
    }
    drawDispatch.dispatch?.(setDrawDeletes(ids))
  } catch (error) {
    console.error(error)
    setLoadingAsync(false)
    throw error
  }
}
export async function apiDrawExposure(exposure: DTO_DrawCreate) {
  return await BaseApi.post('/api/v1/draws/exposure', exposure, { 'x-business': exposure.brand }, undefined)
}

export async function apiDrawCountry(country: string) {
  return await BaseApi.get(`/api/v1/settings/{country}`, undefined, { country })
}
