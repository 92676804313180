import { useTranslation } from 'react-i18next'
import { enUS, heIL, esES } from '@mui/material/locale'
import useSettings from './useSettings'

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/icons/ic_flag_en.svg'
  },
  {
    label: 'Israel',
    value: 'he',
    systemValue: heIL,
    icon: '/icons/ic_flag_il.svg'
  },
  {
    label: 'Español',
    value: 'es',
    systemValue: esES,
    icon: '/icons/ic_flag_es.svg'
  }
]

export default function useLocales() {
  const { onChangeDirection } = useSettings()
  const { i18n, t: translate } = useTranslation()
  const langStorage = localStorage.getItem('i18nextLng')
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1]

  const handleChangeLanguage = (newlang: string) => {
    if (newlang === 'he') {
      onChangeDirection({ target: { value: 'rtl' } })
    } else {
      onChangeDirection({ target: { value: 'ltr' } })
    }
    i18n.changeLanguage(newlang).then()
  }

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS
  }
}
