export async function isAvailableAsync() {
  if (typeof navigator === 'undefined') return false
  return !!navigator.share
}

export async function shareAsync(url: string, options: any = {}) {
  // NOTE: `navigator.share` is only available via HTTPS
  if (navigator.share) {
    await navigator.share({ ...options, url })
  } else {
    throw new Error('no navigator share')
  }
}
