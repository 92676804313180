import { useDispatch, useSelector } from 'react-redux'
import { selectReactionImageCrop, setLoading, setReactionImageCrop } from '@/redux/reaction'
import { Button, DialogTitle, Stack } from '@mui/material'
import { DialogAnimate } from '@/components/animate'
import React, { useCallback, useEffect, useState } from 'react'
import useLocales from '@/hooks/useLocales'
import Cropper from 'react-easy-crop'

export interface IImageFile extends File {
  height: number
  width: number
  lastModified: number
  lastModifiedDate: Date
  name: string
  size: number
  type: 'image/png' | 'image/jpeg'
  src: string
  webkitRelativePath: string
  preview?: string
  options?: {
    maxWidth?: number
    maxHeight?: number
    minWidth?: number
    minHeight?: number
    ratio: number
    callback?: (image: IImageFile | undefined) => void
    quality?: number
    type?: 'image/png' | 'image/jpeg'
  }
}
export function EasyCrop() {
  const { translate: _ } = useLocales()
  const image = useSelector(selectReactionImageCrop)
  const [isOpen, setIsOpen] = useState(!!image)
  const dispatch = useDispatch()

  const closeModal = (ignoreCallback?: boolean) => {
    setLoading(false)
    if (!ignoreCallback) image?.options?.callback?.(undefined)
    dispatch(setReactionImageCrop(undefined))
  }

  useEffect(() => {
    if (isOpen === !!image) return
    if (image) setIsOpen(true)
    else setTimeout(() => setIsOpen(false), 300)
  }, [image, isOpen])

  if (!isOpen) return null
  return (
    <DialogAnimate size="md" open={!!image} onClose={() => closeModal()} sx={{ pb: 2 }}>
      <DialogTitle sx={{ pb: 2, textAlign: 'center' }}>{_('Adjust the image correctly')}</DialogTitle>
      <EasyCropComponent image={image} closeModal={closeModal} />
    </DialogAnimate>
  )
}

interface PropTypes {
  image?: IImageFile
  closeModal: (ignoreCallback?: boolean) => void
}
function EasyCropComponent({ image, closeModal }: PropTypes) {
  const { translate: _ } = useLocales()
  const [img, setImage] = useState<HTMLImageElement | undefined>()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [params, setParams] = useState({
    height: image?.height || 0,
    width: image?.width || 0,
    x: 0,
    y: 0
  })

  const onCropComplete = useCallback((area, pixels) => setParams(pixels), [])

  const applyCrop = useCallback(async () => {
    if (!img) return
    setLoading(true)
    const canvas = document.createElement('canvas')
    const pixels = { ...params }
    const ratio = image?.options?.ratio || pixels.width / pixels.height
    let s = 1
    if (image?.options?.maxWidth && ratio >= 1 && pixels.width > image?.options?.maxWidth) {
      s = image?.options?.maxWidth / pixels.width
    } else if (image?.options?.maxHeight && ratio < 1 && pixels.height > image?.options?.maxHeight) {
      s = image?.options?.maxHeight / pixels.height
    } else if (image?.options?.minWidth && ratio >= 1 && pixels.width < image?.options?.minWidth) {
      s = image?.options?.minWidth / pixels.width
    } else if (image?.options?.minHeight && ratio < 1 && pixels.height > image?.options?.minHeight) {
      s = image?.options?.minHeight / pixels.height
    }
    canvas.width = pixels.width * s
    canvas.height = pixels.height * s
    const ctx = canvas.getContext('2d')
    const width = img.naturalWidth
    const height = img.naturalHeight
    ctx?.drawImage(img, 0, 0, width, height, -pixels.x * s, -pixels.y * s, width * s, height * s)
    const type = image?.options?.type || image?.type || 'image/jpeg'
    const blob: Blob | undefined = await new Promise((res) => canvas.toBlob((b) => res(b || undefined), type, image?.options?.quality))
    if (!blob) return setLoading(false)
    const file: IImageFile = new File([blob], image?.name || '', { type }) as IImageFile
    file.width = pixels.width * s
    file.height = pixels.height * s
    file.src = URL.createObjectURL(file)
    file.preview = file.src
    file.options = image?.options
    setLoading(false)
    closeModal(true)
    image?.options?.callback?.(file)
  }, [closeModal, image, img, params])

  return (
    <Stack spacing={2} sx={{ p: 0, position: 'relative', overflow: 'hidden' }}>
      <Stack sx={{ p: 2, mb: 2, position: 'relative', minWidth: '50vh', minHeight: '50vh', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{image ? <Cropper setImageRef={($img) => setImage($img.current || undefined)} image={image.src} crop={crop} zoom={zoom} aspect={image.options?.ratio || 1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} /> : null}</Stack>
      <Stack spacing={1} sx={{ px: 5, pt: 2, position: 'relative', backgroundColor: '#FFFFFF' }}>
        <Button variant="contained" onClick={applyCrop}>
          {_('Apply')}
        </Button>
        <Button variant="text" onClick={() => closeModal()}>
          {_('Cancel')}
        </Button>
      </Stack>
    </Stack>
  )
}
