import React, { useRef } from 'react'
import { m } from 'framer-motion'
import { useState, useEffect } from 'react'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box, Link, Grid, List, Stack, Popover, ListItem, ListSubheader, CardActionArea } from '@mui/material'
import Iconify from '../../components/Iconify'
import type { SxProps } from '@mui/system'

const LinkStyle = styled(Link)<any>(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter
  }),
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none'
  }
}))

const ListItemStyle = styled(ListItem)<any>(({ theme }) => ({
  ...theme.typography.body2,
  padding: 0,
  marginTop: theme.spacing(3),
  color: theme.palette.text.secondary,
  transition: theme.transitions.create('color'),
  '&:hover': {
    color: theme.palette.text.primary
  }
}))

interface MenuDesktopProps {
  isHome?: boolean
  isOffset?: boolean
  navConfig?: any[]
}

export default function MenuDesktop({ isOffset, isHome, navConfig = [] }: MenuDesktopProps) {
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const self = useRef({ open, handleClose })
  self.current = { open, handleClose }
  useEffect(() => {
    if (self.current.open) self.current.handleClose()
  }, [pathname])

  return (
    <Stack direction="row">
      {navConfig.map((link: any) => (
        <MenuDesktopItem key={link.title} item={link} isOpen={open} onOpen={handleOpen} onClose={handleClose} isOffset={isOffset} isHome={isHome} />
      ))}
    </Stack>
  )
}

interface IconBulletProps {
  type?: 'item' | 'subheader'
}

function IconBullet({ type = 'item' }: IconBulletProps) {
  return (
    <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && { ml: 0, width: 8, height: 2, borderRadius: 2 })
        }}
      />
    </Box>
  )
}

interface MenuDesktopItemProps {
  isHome?: boolean
  isOffset?: boolean
  isOpen?: boolean
  onClose?(...args: any[]): any
  onOpen?(...args: any[]): any
  item?: {
    path?: string
    title?: string
    children?: any[]
  }
}

function MenuDesktopItem({ item = {}, isHome = false, isOpen = false, isOffset, onOpen, onClose }: MenuDesktopItemProps) {
  const { title, path, children } = item

  if (children) {
    return (
      <>
        <LinkStyle
          onClick={onOpen}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            ...(isHome && { color: 'common.white' }),
            ...(isOffset && { color: 'text.primary' }),
            ...(isOpen && { opacity: 0.48 })
          }}>
          {title}
          <Iconify icon={isOpen ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'} sx={{ ml: 0.5, width: 16, height: 16 }} />
        </LinkStyle>

        <Popover
          open={isOpen}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 80, left: 0 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={onClose}
          PaperProps={{
            sx: {
              px: 3,
              pt: 5,
              pb: 3,
              right: 16,
              m: 'auto',
              borderRadius: 2,
              maxWidth: (theme: ITheme) => theme.breakpoints.values.lg,
              boxShadow: (theme: ITheme) => theme.customShadows.z24
            } as SxProps
          }}>
          <Grid container spacing={3}>
            {children.map((list) => {
              const { subheader, items } = list

              return (
                <Grid key={subheader} item xs={12} md={subheader === 'Dashboard' ? 6 : 2}>
                  <List disablePadding>
                    <ListSubheader
                      disableSticky
                      disableGutters
                      sx={{
                        display: 'flex',
                        lineHeight: 'unset',
                        alignItems: 'center',
                        color: 'text.primary',
                        typography: 'overline'
                      }}>
                      <IconBullet type="subheader" /> {subheader}
                    </ListSubheader>

                    {items.map(($item: any) => (
                      <ListItemStyle
                        key={$item.title}
                        to={$item.path}
                        component={RouterLink}
                        underline="none"
                        sx={{
                          '&.active': {
                            color: 'text.primary',
                            typography: 'subtitle2'
                          }
                        }}>
                        {$item.title === 'Dashboard' ? (
                          <CardActionArea
                            sx={{
                              py: 5,
                              px: 10,
                              borderRadius: 2,
                              color: 'primary.main',
                              bgcolor: 'background.neutral'
                            }}>
                            <Box
                              component={m.img}
                              whileTap="tap"
                              whileHover="hover"
                              variants={{
                                hover: { scale: 1.02 },
                                tap: { scale: 0.98 }
                              }}
                              src="https://minimal-assets-api.vercel.app/assets/illustrations/illustration_dashboard.png"
                            />
                          </CardActionArea>
                        ) : (
                          <>
                            <IconBullet />
                            {$item.title}
                          </>
                        )}
                      </ListItemStyle>
                    ))}
                  </List>
                </Grid>
              )
            })}
          </Grid>
        </Popover>
      </>
    )
  }

  if (title === 'Contact') {
    return (
      <LinkStyle
        href={path}
        target="_blank"
        rel="noopener"
        sx={{
          ...(isHome && { color: 'text.primary' }),
          ...(isOffset && { color: 'text.primary' })
        }}>
        {title}
      </LinkStyle>
    )
  }

  return (
    <LinkStyle
      to={path}
      component={RouterLink}
      end={path === '/'}
      sx={{
        ...(isHome && { color: 'text.primary' }),
        ...(isOffset && { color: 'text.primary' }),
        '&.active': {
          color: 'primary.main'
        }
      }}>
      {title}
    </LinkStyle>
  )
}
