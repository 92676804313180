import React from 'react'
import type { ReactNode } from 'react'
import { createContext, useState, useEffect } from 'react'
import useResponsive from '../hooks/useResponsive'

const initialState: CollapseDrawerContextState = {
  collapseClick: false,
  collapseHover: false,
  onToggleCollapse: () => {},
  onHoverEnter: () => {},
  onHoverLeave: () => {}
}

interface CollapseDrawerContextState {
  collapseClick: boolean
  collapseHover: boolean
  onToggleCollapse?(...args: any[]): any
  onHoverEnter?(...args: any[]): any
  onHoverLeave?(...args: any[]): any
  isCollapse?: boolean
}

const CollapseDrawerContext = createContext<CollapseDrawerContextState>(initialState)

function CollapseDrawerProvider({ children }: { children: ReactNode }) {
  const isDesktop = useResponsive('up', 'lg')

  const [collapse, setCollapse] = useState<{
    click: boolean
    hover: boolean
  }>({
    click: false,
    hover: false
  })

  useEffect(() => {
    if (!isDesktop) {
      setCollapse({
        click: false,
        hover: false
      })
    }
  }, [isDesktop])

  const handleToggleCollapse = () => {
    setCollapse({ ...collapse, click: !collapse.click })
  }

  const handleHoverEnter = () => {
    if (collapse.click) {
      setCollapse({ ...collapse, hover: true })
    }
  }

  const handleHoverLeave = () => {
    setCollapse({ ...collapse, hover: false })
  }

  return (
    <CollapseDrawerContext.Provider
      value={
        {
          isCollapse: collapse.click && !collapse.hover,
          collapseClick: collapse.click,
          collapseHover: collapse.hover,
          onToggleCollapse: handleToggleCollapse,
          onHoverEnter: handleHoverEnter,
          onHoverLeave: handleHoverLeave
        } as CollapseDrawerContextState
      }>
      {children}
    </CollapseDrawerContext.Provider>
  )
}

export { CollapseDrawerProvider, CollapseDrawerContext }
