import { createSlice } from '@reduxjs/toolkit'
import type { Dispatch } from '@reduxjs/toolkit'
import omit from 'lodash/omit'
import { BaseApi } from '@/api'

function objFromArray(array: any[], key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current
    return accumulator
  }, {})
}

const initialState: IKanban = {
  isLoading: false,
  error: null,
  board: {
    cards: {},
    columns: {},
    columnOrder: []
  }
}

interface IKanban {
  isLoading: boolean
  error: Error | null
  board: {
    cards: Record<string, any>
    columns: Record<string, any>
    columnOrder: string[]
  }
}

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false
      const board = action.payload
      const cards = objFromArray(board.cards)
      const columns = objFromArray(board.columns)
      const { columnOrder } = board
      state.board = {
        cards,
        columns,
        columnOrder
      }
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload
      state.isLoading = false
      state.board.columns = {
        ...state.board.columns,
        [newColumn.id]: newColumn
      }
      state.board.columnOrder.push(newColumn.id)
    },

    persistCard(state, action) {
      state.board.columns = action.payload
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload
    },

    addTask(state, action) {
      const { card, columnId } = action.payload

      state.board.cards[card.id] = card
      state.board.columns[columnId].cardIds.push(card.id)
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload

      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter((id: string) => id !== cardId)
      state.board.cards = omit(state.board.cards, [cardId])
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload

      state.isLoading = false
      state.board.columns[column.id] = column
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload
      const deletedColumn = state.board.columns[columnId]

      state.isLoading = false
      state.board.columns = omit(state.board.columns, [columnId])
      state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds])
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId)
    }
  }
})

// Reducer
export const kanbanReducer = slice.reducer
export const kanbanDispatch: { dispatch?: Function } = {}
export const { actions } = slice

export function getBoard() {
  return async () => {
    const { dispatch } = kanbanDispatch
    dispatch?.(slice.actions.startLoading())
    try {
      // TODO: const response = await BaseApi.get('/api/kanban/board')
      const response = { data: { board: {} } }
      dispatch?.(slice.actions.getBoardSuccess(response.data.board))
    } catch (error) {
      dispatch?.(slice.actions.hasError(error))
    }
  }
}

export function createColumn(newColumn: any) {
  return async () => {
    const { dispatch } = kanbanDispatch
    dispatch?.(slice.actions.startLoading())
    try {
      // TODO: const response = await BaseApi.post('/api/kanban/columns/new', newColumn)
      const response = { data: { column: {} } }
      dispatch?.(slice.actions.createColumnSuccess(response.data.column))
    } catch (error) {
      dispatch?.(slice.actions.hasError(error))
    }
  }
}

export function updateColumn(columnId: string, $updateColumn: any) {
  return async () => {
    const { dispatch } = kanbanDispatch
    dispatch?.(slice.actions.startLoading())
    try {
      // TODO: const response = await BaseApi.post('/api/kanban/columns/update', { columnId, updateColumn: $updateColumn })
      const response = { data: { column: {} } }
      dispatch?.(slice.actions.updateColumnSuccess(response.data.column))
    } catch (error) {
      dispatch?.(slice.actions.hasError(error))
    }
  }
}

export function deleteColumn(columnId: string) {
  return async () => {
    const { dispatch } = kanbanDispatch
    dispatch?.(slice.actions.startLoading())
    try {
      // TODO: await BaseApi.post('/api/kanban/columns/delete', { columnId })
      dispatch?.(slice.actions.deleteColumnSuccess({ columnId }))
    } catch (error) {
      dispatch?.(slice.actions.hasError(error))
    }
  }
}

export function persistColumn(newColumnOrder: string[]) {
  return () => {
    const { dispatch } = kanbanDispatch
    dispatch?.(slice.actions.persistColumn(newColumnOrder))
  }
}

export function persistCard(columns: Record<string, any>) {
  return () => {
    const { dispatch } = kanbanDispatch
    dispatch?.(slice.actions.persistCard(columns))
  }
}

export function addTask({ card, columnId }: { card: any; columnId: string }) {
  return () => {
    const { dispatch } = kanbanDispatch
    dispatch?.(slice.actions.addTask({ card, columnId }))
  }
}

export function deleteTask({ cardId, columnId }: { cardId: string; columnId: string }) {
  return ($dispatch: Dispatch) => {
    $dispatch?.(slice.actions.deleteTask({ cardId, columnId }))
  }
}
