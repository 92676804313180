import React from 'react'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import createCache from '@emotion/cache'
import type { StylisPlugin } from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { useTheme } from '@mui/material/styles'
import stylisRTLPlugin from '@/lib/stylis-plugin-rtl/stylis-rtl'

export default function RtlLayout({ children }: { children: ReactNode }) {
  const theme: ITheme = useTheme()

  useEffect(() => {
    document.dir = theme.direction
  }, [theme.direction])

  const cacheRtl = createCache({
    key: theme.direction === 'rtl' ? 'rtl' : 'css',
    stylisPlugins: (theme.direction === 'rtl' ? [stylisRTLPlugin] : []) as unknown as StylisPlugin[]
  })

  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>
}
