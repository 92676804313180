import Axios from 'axios'
import type { AxiosResponse } from 'axios'
import { API_HOST } from '@/config'
import type { IApi } from '@/api/models'
import { _moustache } from '@/lib/xi'
import { DTO_200_BrandBoxEnum } from '@/api/models'

const axiosInstance = Axios.create({
  baseURL: API_HOST
})

axiosInstance.interceptors.request.use(async (response) => {
  try {
    const token = localStorage.getItem('accessToken') || ''
    response.headers!.Authorization = `Bearer ${token}`
    response.headers!['ID'] = localStorage.getItem('uid') || ''
    response.headers!['Access-Control-Allow-Origin'] = '*'
  } catch (error) {
    response.headers!.Authorization = ''
  }
  response.baseURL = API_HOST
  return response
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
)

export abstract class BaseApi {
  static drawColors = [
    '#FFD166',
    '#06D6A0',
    '#3A7CA5', //
    '#FF3366',
    '#644536',
    '#7F0AE8'
  ]
  static boxOptions = DTO_200_BrandBoxEnum
  static async get<K extends keyof IApi>(url: K, headers: IApi[K]['get']['header'], params: IApi[K]['get']['path']): Promise<AxiosResponse<IApi[K]['get']['response']>> {
    if (params && Object.keys(params).length > 0) url = _moustache(url, params) as K
    return await axiosInstance.get(url, { headers })
  }

  static async post<K extends keyof IApi>(url: K, body: IApi[K]['post']['body'], headers: IApi[K]['post']['header'], params: IApi[K]['post']['path']): Promise<AxiosResponse<IApi[K]['post']['response']>> {
    if (params && Object.keys(params).length > 0) url = _moustache(url, params) as K
    return await axiosInstance.post(url, body, { headers })
  }

  static async delete<K extends keyof IApi>(url: K, headers: IApi[K]['delete']['header'], params: IApi[K]['delete']['path']): Promise<AxiosResponse<IApi[K]['delete']['response']>> {
    if (params && Object.keys(params).length > 0) url = _moustache(url, params) as K
    return await axiosInstance.delete(url, { headers })
  }

  static async put<K extends keyof IApi>(url: K, body: IApi[K]['put']['body'], headers: IApi[K]['put']['header'], params: IApi[K]['put']['path']): Promise<AxiosResponse<IApi[K]['put']['response']>> {
    if (params && Object.keys(params).length > 0) url = _moustache(url, params) as K
    return await axiosInstance.put(url, body, { headers })
  }
}
