import React from 'react'
import type { ReactNode } from 'react'
import { LazyMotion } from 'framer-motion'
import loadFeatures from './features'

export default function MotionLazyContainer({ children }: { children: ReactNode }) {
  return (
    <LazyMotion strict features={loadFeatures}>
      {children}
    </LazyMotion>
  )
}
