import React, { forwardRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { Avatar as MUIAvatar } from '@mui/material'
import type { AvatarProps } from '@mui/material'

export type IAvatarColor = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'
interface Props extends Omit<AvatarProps, 'color'> {
  color?: IAvatarColor
}

const Avatar = forwardRef<HTMLDivElement, Props>(({ color = 'default', children, sx, ...other }, ref) => {
  const theme: ITheme = useTheme()

  if (color === 'default') {
    return (
      <MUIAvatar ref={ref} sx={sx} {...other}>
        {children}
      </MUIAvatar>
    )
  }

  return (
    <MUIAvatar
      ref={ref}
      sx={{
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette[color].contrastText,
        backgroundColor: theme.palette[color].main,
        ...sx
      }}
      {...other}>
      {children}
    </MUIAvatar>
  )
})

Avatar.displayName = 'Avatar'

export default Avatar
