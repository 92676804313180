import numeral from 'numeral'

export function fCurrency(number: number | string) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00')
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%')
}

export function fNumber(number: number | string) {
  return numeral(number).format()
}

export function fShortenNumber(number: number) {
  return numeral(number).format('0.00a').replace('.00', '')
}

export function fData(number: number) {
  return numeral(number).format('0.0 b')
}

export function fTime(ms: number) {
  const segs = Math.floor(ms / 1000)
  const min = Math.floor(segs / 60)
  const hours = Math.floor(min / 60)
  const days = Math.floor(hours / 24)
  return `${days}d ` + `${hours % 24}`.padStart(2, '0') + ':' + `${min % 60}`.padStart(2, '0') + 'h'
}
