import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box, Stack, Link, Container, Typography } from '@mui/material'
import { PATH_AUTH } from '@/routes/paths'
import useResponsive from '../../hooks/useResponsive'
import useLocales from '../../hooks/useLocales'
import Page from '../../components/Page'
import Logo from '../../components/Logo'
import { LoginForm } from '../../sections/auth/login'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7)
  }
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}))

export function Login() {
  const smUp = useResponsive('up', 'sm')
  const { translate: _ } = useLocales()
  return (
    <Page title={_('Login')}>
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              {`${_(`Don't have an account?`)} `}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                {_('Get started')}
              </Link>
            </Typography>
          )}
        </HeaderStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {_('Sign in to Click77')}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>{_('Enter your details below')}</Typography>
              </Box>
            </Stack>
            <LoginForm />
            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                {`${_(`Don't have an account?`)} `}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  {_('Get started')}
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  )
}
