/* eslint-disable @typescript-eslint/no-explicit-any */
// noinspection JSUnusedGlobalSymbols

/**
 * Xi: Diff
 * creator: efexi
 * version: 1.1.1
 * update: 2023-04-05
 */

/**
 * Xi: sortBy
 * @param {array} arr
 * @param {string} key
 * @param {boolean=} reverse
 * @return {array}
 */
export function _sortBy<T>(arr: T[], key: keyof T, reverse?: boolean): T[] {
  const val = reverse ? -1 : 1
  return arr.sort((a, b) => (a[key] > b[key] ? val : b[key] > a[key] ? -val : 0))
}

/**
 * Xi: clone
 * @param {object|array} o
 * @param {include:string[]=,exclude:string[]=,noDepp:boolean=} opts
 * @return {object|array}
 */
export function _clone<T>(
  o: T,
  opts: {
    include?: string[]
    exclude?: string[]
    noDeep?: boolean
  } = {}
): T {
  if (!o) return o
  const obj: any = o
  if (Array.isArray(obj)) {
    if (opts.noDeep) return [...obj] as any
    else return obj.map((v) => _clone(v, opts)) as any
  }
  if (typeof obj === 'object') {
    const $obj: any = {}
    Object.keys(obj).forEach((key) => {
      if (opts.include?.length && opts.include.indexOf(key) < 0) return
      if (opts.exclude?.length && opts.exclude.indexOf(key) > -1) return
      if (opts.noDeep) $obj[key] = obj[key]
      else $obj[key] = _clone(obj[key], opts)
    })
    return $obj
  }
  return obj
}

const Diff = {
  VALUE_CREATED: 'created',
  VALUE_UPDATED: 'updated',
  VALUE_DELETED: 'deleted',
  VALUE_UNCHANGED: 'unchanged',

  /**
   * assign
   * @return {object}
   */
  assign<T extends any>(obj: T): T | string {
    if (typeof obj !== 'object' || !obj) return obj
    const o1: any = obj
    if (o1.__type) {
      switch (o1.__type) {
        case Diff.VALUE_DELETED:
        case Diff.VALUE_UPDATED:
          return o1.__data
      }
      return '__DELETE__'
    }
    if (Array.isArray(o1)) {
      if (o1.length < 1) return '__DELETE__'
      const do1: any[] = []
      o1.forEach((val) => {
        const res = Diff.assign(val)
        if (res !== '__DELETE__') do1.push(res)
        else do1.push(null)
      })
      return do1 as any
    } else if (typeof o1 === 'object') {
      if (Object.keys(o1).length < 1) return '__DELETE__'
      const do1: any = {}
      let update = false
      Object.keys(o1).forEach((key) => {
        if (key === 'id') {
          if (o1[key].__type === Diff.VALUE_UPDATED) update = true
          return (do1[key] = o1[key].__data)
        }
        const res = Diff.assign(o1[key])
        if (res !== '__DELETE__') {
          if (Array.isArray(res) && (res.length < 1 || !res.find((obj1) => obj1 != null))) return
          do1[key] = res
        }
      })
      if (!update && Object.keys(do1).length === 1 && do1.id) return '__DELETE__'
      if (Object.keys(do1).length === 0) return '__DELETE__'
      return do1
    }
    return o1
  },

  /**
   * map
   * @param {object} obj1
   * @param {object} obj2
   * @return {object}
   */
  map<T extends any>(obj1: T, obj2: T) {
    if (Diff.isFunction(obj1) || Diff.isFunction(obj2)) {
      throw new Error('Invalid argument. Function given, object expected.')
    }
    if (Diff.isValue(obj1) || Diff.isValue(obj2)) {
      return {
        __type: Diff.compareValues(obj1, obj2),
        __data: obj1 === undefined ? obj2 : obj1
      }
    }
    let o1: any = obj1
    let o2: any = obj2
    if (Array.isArray(o1)) {
      const df: any[] = []
      if (o1.length < 1) return df
      else if (o2 && o2[0] && o2[0].id) {
        const $o1: any[] = []
        o1.forEach((val) => {
          if (!Array.isArray(val) && !val.id) df.push(Diff.map(val, undefined))
          else $o1.push(val)
        })
        o1 = _sortBy($o1, 'id')
        o2 = _sortBy(o2, 'id')
      }
      o1.forEach((_: any, key: string) => {
        if (Diff.isFunction(o1[key])) return
        let value2
        if (o2[key] !== undefined) value2 = o2[key]
        df.push(Diff.map(o1[key], value2))
      })
      o2.forEach((_: any, key: any) => {
        if (Diff.isFunction(o2[key]) || df[key] !== undefined) return
        df.push(Diff.map(undefined, o2[key]))
      })
      return df
    } else {
      const df: any = {}
      Object.keys(o1).forEach((key) => {
        if (Diff.isFunction(o1[key])) return
        let value2
        if (o2[key] !== undefined) value2 = o2[key]
        df[key] = Diff.map(o1[key], value2)
      })
      Object.keys(o2).forEach((key) => {
        if (Diff.isFunction(o2[key]) || df[key] !== undefined) return
        df[key] = Diff.map(undefined, o2[key])
      })
      return df
    }
  },
  /**
   * compare values
   * @param value1
   * @param value2
   * @return {string}
   */
  compareValues<T extends any>(value1: T, value2: T): string {
    if (value1 === value2) return Diff.VALUE_UNCHANGED
    if (Diff.isDate(value1) && Diff.isDate(value2) && (value1 as Date).getTime() === (value2 as Date).getTime()) return Diff.VALUE_UNCHANGED
    if (value1 === undefined) return Diff.VALUE_CREATED
    if (value2 === undefined) return Diff.VALUE_DELETED
    return Diff.VALUE_UPDATED
  },
  isFunction(x: any) {
    return Object.prototype.toString.call(x) === '[object Function]'
  },
  isArray(x: any) {
    return Object.prototype.toString.call(x) === '[object Array]'
  },
  isDate(x: any) {
    return Object.prototype.toString.call(x) === '[object Date]'
  },
  isObject(x: any) {
    return Object.prototype.toString.call(x) === '[object Object]'
  },
  isValue(x: any) {
    return !Diff.isObject(x) && !Diff.isArray(x)
  }
}

/**
 * Xi: Diff
 * @param {object|array} obj1
 * @param {object|array} obj2
 * @param {boolean=} cloneObject
 * @return {object|array}
 */
export function _diff<T extends any>(obj1: T, obj2: T, cloneObject?: boolean): T {
  if (cloneObject) obj1 = _clone(obj1)
  const df = Diff.map(obj1, obj2)
  const res = Diff.assign(df)
  return res === '__DELETE__' ? null : (res as any)
}

/**
 * Xi: assign
 * @param {object} o1
 * @param {object} o2
 * @param {string[]=} keys
 * @return {object}
 */
export function _assign<T>(o1: T, o2: T, keys?: string[]): T {
  if (typeof o1 !== 'object' || typeof o2 !== 'object') return o2
  const obj1: any = o1
  const obj2: any = o2
  Object.keys(obj2).forEach((key) => {
    if (Array.isArray(obj2[key])) {
      const arr = _clone(obj2[key])
      if (!(keys && keys.includes(key))) {
        arr.forEach((o: any, i: number) => {
          if (o == null) arr[i] = obj1[key][i]
          else if (typeof o === 'object') arr[i] = _assign(obj1[key][i], obj2[key][i])
        })
      }
      obj1[key] = arr
    } else if (typeof obj2[key] === 'object') obj1[key] = _assign(obj1[key], obj2[key])
    else obj1[key] = obj2[key]
  })
  return obj1
}
