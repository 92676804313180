import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, Typography, Breadcrumbs as MUIBreadcrumbs } from '@mui/material'
import type { BreadcrumbsProps } from '@mui/material'
import type { ILink } from '@/routes'

interface Props extends BreadcrumbsProps {
  links: ILink[]
  activeLast?: boolean
}

export default function Breadcrumbs({ links, activeLast = false, ...other }: Props) {
  const currentLink = links.length ? links[links.length - 1].name : ''
  const listDefault = links.map((link, i) => <LinkItem key={`${link.name}-${i}`} link={link} />)
  const listActiveLast = links.map((link, i) => (
    <div key={`${link.name}-${i}`}>
      {link.name !== currentLink ? (
        <LinkItem link={link} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            maxWidth: 260,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'text.disabled',
            textOverflow: 'ellipsis'
          }}>
          {currentLink}
        </Typography>
      )}
    </div>
  ))

  return (
    <MUIBreadcrumbs separator={<Box component="span" sx={{ width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' }} />} {...other}>
      {activeLast ? listDefault : listActiveLast}
    </MUIBreadcrumbs>
  )
}

function LinkItem({ link }: { link: ILink }) {
  const { href, name, icon } = link
  return (
    <Link
      key={name}
      variant="body2"
      component={RouterLink}
      to={href || '#'}
      sx={{
        lineHeight: 2,
        display: 'flex',
        alignItems: 'center',
        color: 'text.primary',
        '& > div': { display: 'inherit' }
      }}>
      {icon && <Box sx={{ mr: 1, '& svg': { width: 20, height: 20 } }}>{icon}</Box>}
      {name}
    </Link>
  )
}
