import React from 'react'
import type { ReactElement } from 'react'
import { Icon } from '@iconify/react'
import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material'

interface IconifyProps extends BoxProps {
  icon: ReactElement | string
  sx?: object
}

const Iconify: React.FC<IconifyProps> = ({ icon, sx, ...other }) => {
  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />
}

export default Iconify
